import { Avatar, Box, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

function getFileType(file) {

    if(file.type.match('image.*'))
      return 'image';
  
    if(file.type.match('video.*'))
      return 'video';
  
    return 'other';
  }

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 5,
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
    width: "100%",
    position: "relative"
};

const img = {
    display: 'block',
    width: '100%',
    height: '100%'
};


function ImageUploaderWithThumbs({ title, multiple,accept, selectedItems, setSelectedItems }) {
    const { getRootProps, getInputProps } = useDropzone({
        accept,
        multiple: { multiple },
        onDrop: acceptedFiles => {
            // setSelectedItems((prev) => [...prev, ...acceptedFiles.map(file => Object.assign(file, {
            //     preview: URL.createObjectURL(file)
            // }))]);
            setSelectedItems((prev) => [...prev, ...acceptedFiles]);
        },
        validator:file=>{
            console.log("Validations:",file);
            const foundItem = selectedItems.find(item=>item.name===file.name);
            if(!foundItem){
                return null;
            }
            alert("This file is already added!");
            return true;
        },
        onError:error=>console.log("ERRORR:",error)
    });

    const handleDelete = (name) => {
        setSelectedItems(selectedItems.filter((item) => item.name !== name));
    }

    const thumbs = selectedItems.map((file, idx) => (
        <div style={thumb} key={file.name}>
            <Box style={thumbInner}
                sx={{
                    "&:hover .btn-delete": { display: "flex" }
                }}
            >
                <Avatar style={img} alt="Preview" src={URL.createObjectURL(file)} variant="rounded"
                sx={{"& img":{height:"100%"}}}
                >
                    <Stack justifyContent="center" height="100%" fontSize="12px" p="4px" className="file-name">{file.name}</Stack>
                </Avatar>
                {/* <img
                    src={URL.createObjectURL(file)}
                    alt="preview"
                    style={img}
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                /> */}
                <IconButton className="btn-delete" aria-label="delete" size="large" color="error"
                    sx={{ position: "absolute", top: "0", right: "0", display: "none",
                width:"30px", height:"30px",p:0
                }}
                    onClick={() => handleDelete(file.name)}
                >
                    <HighlightOffIcon />
                </IconButton>
            </Box>
        </div>
    ));

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => selectedItems.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);

    return (
        <section className="container">
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                border="1px solid #d2d6da"
                borderRadius="8px"
                sx={{ cursor: "pointer" }}
                {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <Typography variant='subtitle2' fontWeight="normal">{title}</Typography>
            </Stack>
            <aside style={thumbsContainer}>
                {thumbs}
            </aside>
        </section>
    );
}

export default ImageUploaderWithThumbs;