/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Setting pages components
import TableCell from "layouts/pages/account/settings/components/TableCell";

function Notifications({ userSettings, setUserSettings }) {
  const handleChange = (e) => {
    const { name, checked } = e.target;
    setUserSettings({
      ...userSettings, [name]: checked
    });
  }
  const { notification_email_transaction, notification_email_withdrawals,
    notification_follows_email, notification_follows_push, notification_follows_sms,
    notification_log_email, notification_log_push, notification_log_sms,
    notification_push_transaction, notification_push_withdrawal,
    notification_sms_transaction, notification_sms_withdrawal } = { ...userSettings };
  return (
    <Card id="notifications">
      <MDBox p={3} lineHeight={1}>
        <MDBox mb={1}>
          <MDTypography variant="h5">Notifications</MDTypography>
        </MDBox>
        <MDTypography variant="button" color="text">
          Choose how you receive notifications. These notification settings apply to the things
          you’re watching.
        </MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <MDBox minWidth="auto">
          <Table sx={{ minWidth: "36rem" }}>
            <MDBox component="thead">
              <TableRow>
                <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>
                  Activity
                </TableCell>
                <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                  Email
                </TableCell>
                <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                  Push
                </TableCell>
                <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                  SMS
                </TableCell>
              </TableRow>
            </MDBox>
            <TableBody>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <MDBox lineHeight={1.4}>
                    <MDTypography display="block" variant="button" fontWeight="regular">
                      Transactions
                    </MDTypography>
                    <MDTypography variant="caption" color="text" fontWeight="regular">
                      Notify the status of Transactions
                    </MDTypography>
                  </MDBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch name="notification_email_transaction" onChange={handleChange} checked={notification_email_transaction} />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch name="notification_push_transaction" onChange={handleChange} checked={notification_push_transaction} />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch name="notification_sms_transaction" onChange={handleChange} checked={notification_sms_transaction} />

                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <MDBox lineHeight={1.4}>
                    <MDTypography display="block" variant="button" fontWeight="regular">
                      Withdrawals
                    </MDTypography>
                    <MDTypography variant="caption" color="text" fontWeight="regular">
                      Notify the status of withdrawal request
                    </MDTypography>
                  </MDBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch name="notification_email_withdrawals" onChange={handleChange} checked={notification_email_withdrawals} />

                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch name="notification_push_withdrawal" onChange={handleChange} checked={notification_push_withdrawal} />

                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch name="notification_sms_withdrawal" onChange={handleChange} checked={notification_sms_withdrawal} />

                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <MDBox lineHeight={1.4}>
                    <MDTypography display="block" variant="button" fontWeight="regular">
                      Follows
                    </MDTypography>
                    <MDTypography variant="caption" color="text" fontWeight="regular">
                      Notify when another user follows you.
                    </MDTypography>
                  </MDBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch name="notification_follows_email" onChange={handleChange} checked={notification_follows_email} />

                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch name="notification_follows_push" onChange={handleChange} checked={notification_follows_push} />

                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch name="notification_follows_sms" onChange={handleChange} checked={notification_follows_sms} />

                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]} >
                  <MDTypography variant="caption" color="text" fontWeight="regular">
                    Log in from a new device
                  </MDTypography>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} >
                  <Switch name="notification_log_email" onChange={handleChange} checked={notification_log_email} />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} >
                  <Switch name="notification_log_push" onChange={handleChange} checked={notification_log_push} />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} >
                  <Switch name="notification_log_sms" onChange={handleChange} checked={notification_log_sms} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Notifications;
