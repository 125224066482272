import { Button, ButtonGroup, Divider, Grid, ImageList, ImageListItem, Stack, Typography } from '@mui/material';
import Footer from 'biota/Footer';
import DashboardLayout from 'biota/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'biota/Navbars/DashboardNavbar';
import MDBox from 'components/MDBox';
import React from 'react'
import backgroundImage from "assets/images/biota/bg-1.jpeg";
import CropFreeIcon from '@mui/icons-material/CropFree';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import InfoIcon from '@mui/icons-material/Info';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import "./ProjectPreview.scss";
import MDButton from 'components/MDButton';

function ProjectPreview() {
    const itemData = [
        {
            img: 'https://www.worldatlas.com/r/w1200/upload/e6/29/1c/shutterstock-1856210101.jpg',
            title: 'Breakfast',
        },
        {
            img: 'https://img-aws.ehowcdn.com/360x267p/s3-us-west-1.amazonaws.com/contentlab.studiod/getty/20d066f0a3454d84823e0ed1eaf35b1d',
            title: 'Burger',
        },
        {
            img: 'https://whsjohnnygreen.org/wp-content/uploads/2015/01/iStock_000011145477Large_mini_1-900x540.jpg',
            title: 'Camera',
        },
        {
            img: 'https://www.leafyplace.com/wp-content/uploads/2019/11/lemur.jpg',
            title: 'Coffee',
        },
        {
            img: 'https://www.leafyplace.com/wp-content/uploads/2019/11/macau.jpg',
            title: 'Hats',
        },
        {
            img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStNPL_nU7de3DBszTo5eoIFJnjGrb4yHpm3KQsPiVRXTtk0oD6bj4e7rnDiDikP1fL18g&usqp=CAU',
            title: 'Honey',
        }
    ];
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox className="project-preview-container">
                <MDBox className="project-preview-top" sx={{ bgcolor: "primary.main" }}>
                    <Typography color="inherit" variant="h2">Tirimbina Biological Reserve</Typography>
                </MDBox>
                <MDBox
                    className="project-preview-banner"
                    position="relative"
                    minHeight="400px"
                    sx={{
                        backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                            `url(${backgroundImage})`,
                        backgroundSize: "cover",
                        backgroundPosition: "50%",
                        overflow: "hidden",
                    }}
                >
                    <MDBox
                        className="project-preview-banner-card"

                        color="white"
                        m={2}
                        maxWidth="600px"
                        p={6}
                        sx={{ bgcolor: "primary.main" }}
                    >
                        <Typography color="inherit" className="project-preview-banner-card-title" variant="h3">Tirimbina Biological Reserve</Typography>
                        <Divider sx={{ bgcolor: "#ffffff", width: "100px", height: "3px" }} />
                        <MDBox mt={4} color="white">
                            <Typography color="inherit" variant="body2" mb={3}>Lorem ipsum dolor sit.</Typography>

                            <Stack direction="row" spacing={4}>
                                <Stack direction="row" spacing={1.5}>
                                    <CropFreeIcon fontSize='large' />
                                    <Stack>
                                        <Typography color="inherit" variant="subtitle2">Total Area</Typography>
                                        <Typography color="inherit" variant="subtitle2">347.43h</Typography>
                                    </Stack>
                                </Stack>
                                <Stack direction="row" spacing={1.5}>
                                    <SelectAllIcon fontSize="large" />
                                    <Stack>
                                        <Typography color="inherit" variant="subtitle2">Effective Area</Typography>
                                        <Typography color="inherit" variant="subtitle2">339.98h</Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </MDBox>
                    </MDBox>
                </MDBox>

                <MDBox color="white" className="project-preview-main" sx={{ bgcolor: "primary.main" }} p={8} mt={5}>
                    <Grid maxWidth="xl" mx="auto" container>
                        <MDBox color="inherit" mb={3}>
                            <Typography color="inherit" className="project-preview-banner-card-title" variant="h4">Tirimbina Biological Reserve</Typography>
                            <Divider sx={{ bgcolor: "#ffffff", width: "100px", height: "3px" }} />
                        </MDBox>
                        <Typography color="inherit" variant="body2" mb={3}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque repellendus,
                            amet laudantium molestias quo quos possimus laboriosam delectus fuga perferendis.</Typography>

                        <Typography color="inherit" variant="body2" mb={3}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque repellendus,
                            amet laudantium molestias quo quos possimus laboriosam delectus fuga perferendis.</Typography>
                        <MDButton color="warning" title="Look our nfts" fullWidth><InfoIcon sx={{ color: "primary.main" }} className="biota-btn-icon" /> BIOTA NFTS</MDButton>

                    </Grid>
                    <Grid maxWidth="xl" mx="auto" container mt={{ xs: 3, lg: 6 }}>
                        <Stack width="100%">
                            <MDBox color="inherit" mb={3}>
                                <Typography color="inherit" className="project-preview-banner-card-title" variant="h4">Image gallery</Typography>
                                <Divider sx={{ bgcolor: "#ffffff", width: "100px", height: "3px" }} />
                            </MDBox>
                            <MDBox>
                                <ImageList cols={3} rowHeight={164} gap={10}>
                                    {itemData.map((item) => (
                                        <ImageListItem key={item.img}>
                                            <img
                                                src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                                srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                                alt={item.title}
                                                loading="lazy"
                                                style={{ height: "100%", objectFit: "cover" }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </MDBox>
                        </Stack>
                    </Grid>

                </MDBox>

                <MDBox className="project-preview-main" py={8} mt={2}>
                    <Grid maxWidth="xl" mx="auto" container flexWrap="nowrap" gap={5}>
                        <Grid item xs={12} lg={5}>
                            <Typography color="inherit" fontWeight="bold" variant="subtitle1" mb={1}>Registered Owner</Typography>
                            <Typography color="inherit" variant="subtitle1" mb={1}>Lorem ipsum dolor sit amet consectetur adipisicing elit.</Typography>
                            <Stack direction="row" justifyContent="center">
                                <MDButton variant="text" color="warning" size="large" endIcon={<ArrowRightAltIcon />}>
                                    VIEW MORE
                                </MDButton>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <Typography color="inherit" fontWeight="bold" variant="h3">
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsum unde laboriosam assumenda
                                 alias impedit necessitatibus rerum tenetur reprehenderit similique iusto? Fugit laborum ad
                                  unde quaerat
                            </Typography>
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
            <Footer />
        </DashboardLayout>
    )
}

export default ProjectPreview