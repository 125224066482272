import React from "react";
// import styled from "styled-components";
// const Button = styled.button;
import MDButton from "components/MDButton";

function FileUploader({ setFile, children }) {
  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setFile(fileUploaded);
  };
  return (
    <>
      <MDButton variant="gradient" color="dark" onClick={handleClick} fullWidth>
        {children}
      </MDButton>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
      />
    </>
  );
}
export default FileUploader;
