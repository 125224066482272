/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";

// Wizard application components
import FormField from "layouts/applications/wizard/components/FormField";

// Images
import team2 from "assets/images/team-2.jpg";

import "./Location.scss";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import StepperTitle from "components/StepperTitle/StepperTitle";

function Location() {
  return (
    <MDBox className="user-varification-location">

      <StepperTitle
        title="Location Info"
        subTitle="Mandatory information about your location" />
      <MDBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <MDBox>
              <FormField type="text" label="First Name" />
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDBox>
              <FormField type="text" label="Last Name" />
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDBox>
              <FormField type="email" label="Email" />
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} className="user-varification-location-select">
            <MDBox width="100%">
              <FormControl variant="standard" fullWidth>
                <InputLabel id="location-country">Country</InputLabel>
                <Select
                  labelId="location-country"
                  id="location-country-select"
                  value=""
                  label="Country"
                  onChange={() => { }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} >
            <MDBox>
              <FormField type="text" label="Departament/Province" />
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} className="user-varification-location-select">
            <MDBox width="100%">
              <FormControl variant="standard" fullWidth>
                <InputLabel id="location-district">District</InputLabel>
                <Select
                  labelId="location-district"
                  id="location-district-select"
                  value=""
                  label="District"
                  onChange={() => { }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} >
            <MDBox>
              <FormField type="text" label="Address" />
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} className="user-varification-location-select">
            <MDBox width="100%">
              <FormControl variant="standard" fullWidth>
                <InputLabel id="location-account-type">Account type</InputLabel>
                <Select
                  labelId="location-account-type"
                  id="location-account-type-select"
                  value=""
                  label="Account type"
                  onChange={() => { }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} className="user-varification-location-select">
            <MDBox width="100%">
              <FormControl variant="standard" fullWidth>
                <InputLabel id="location-biota-selection">BIOTA Selection (Dropdown)</InputLabel>
                <Select
                  labelId="location-biota-selection"
                  id="location-biota-selection-select"
                  value=""
                  label="BIOTA Selection (Dropdown)"
                  onChange={() => { }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} className="user-varification-location-select">
            <MDBox width="100%">
              <FormControl variant="standard" fullWidth>
                <InputLabel id="location-biota-selection2">BIOTA Selection (Dropdown)</InputLabel>
                <Select
                  labelId="location-biota-selection2"
                  id="location-biota-selection2-select"
                  value=""
                  label="BIOTA Selection (Dropdown)"
                  onChange={() => { }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default Location;
