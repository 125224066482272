import { Avatar, Checkbox, FormControlLabel, List, ListItem, ListItemAvatar, ListItemText, Stack, Tab, Tabs, Typography } from '@mui/material';
import MDBox from 'components/MDBox';
import React from 'react'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Modal } from 'react-responsive-modal';
import team2 from "assets/images/team-2.jpg"
import 'react-responsive-modal/styles.css';
import "./TransactionsModal.scss";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <MDBox sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </MDBox>
            )}
        </div>
    );
}
function TransactionsModal({ open, setOpen }) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    return (
        <div className='transactions-modal-container'>
            <Modal classNames="" showCloseIcon={false} modalId="transactions-modal" open={open} onClose={() => setOpen(prev => !prev)} center>
                <MDBox sx={{ width: '100%' }}>
                    <Stack spacing={16} alignItems="center" direction="row" justifyContent="space-between" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <h4>Transaction - #169420</h4>
                        <Tabs centered={false} value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Manage Transaction" {...a11yProps(0)} />
                            <Tab label="Customer Support" {...a11yProps(1)} />
                        </Tabs>
                    </Stack>
                    <TabPanel value={value} index={0}>
                        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            <Stack component={ListItem} direction="row" justifyContent="space-between">
                                <h5 className='transaction-list-header'>Made By</h5>
                                <h5  className='transaction-list-header'>Token Amount</h5>
                            </Stack>

                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <img src={team2} className="transaction-list-img" alt="profile" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="John Doe" secondary="8.232 orders" />
                                <div className='amount'>$9034.00</div>
                            </ListItem>
                            <Stack component={ListItem} direction="row" mt={2} justifyContent="space-between">
                                <h5  className='transaction-list-header'>Type of Transaction</h5>
                                <h5  className='transaction-list-header'>Total</h5>
                            </Stack>

                            <Stack component={ListItem} direction="row" justifyContent="space-between">
                                <h5>Bid</h5>
                                <div className='amount'>$9034.00</div>
                            </Stack>

                        </List>

                        <Stack direction="column" alignItems="flex-start" spacing={0} mt={3}>
                            <h5>Status</h5>
                            <FormControlLabel control={<Checkbox defaultChecked />} label="In progress" />
                            <FormControlLabel control={<Checkbox  />} label="Successful" />
                            <FormControlLabel control={<Checkbox  />} label="Failed" />
                        </Stack>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        Item Two
                    </TabPanel>
                </MDBox>
            </Modal>
        </div>
    );
}

export default TransactionsModal