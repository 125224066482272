/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useContext } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from '@mui/material/Switch';
import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import FormField from "layouts/pages/projects/components/FormField";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useMaterialUIController, setGridView } from "context";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Material Dashboard 2 PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import backgroundImage from "assets/images/bg-profile.jpeg";
import axios from "axios";
import { useAuth } from "../../../../../use-auth";

const mytheme = createTheme({
  palette: {
    info: {
      main: "#FFFFFF",
      focus: "#FFFFFF",
    },
    primary: {
      main: "#ffffff",
      focus: "#ffffff"
    },
    transparent: {
      main: "transparent",
    },
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          // Some CSS
          color: '#ffffff',
        },
        tag: {
          color: '#ffffff'
        }
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          // Some CSS
          color: '#ffffff',
        }
      },
    },
  },
});

function Header({ children }) {
  const [userData, setUserData] = useState({nombre: "David Eliseo", tipo: "Land Owner", verified: true});
  const auth = useAuth();
  const [controller, dispatch] = useMaterialUIController();
  const { gridView } = controller;

  const gridViewSwitchHandler = (event) => {
    setGridView(dispatch, !gridView);
  };

  const listViewSwitchHandler = (event) => {
    setGridView(dispatch, !gridView);
  };

  // useEffect(() => {
  //   axios({
  //     url: "https://biota-backend.herokuapp.com/api/view/user/" + auth.user.id,
  //     method: 'GET',
  //     headers: {
  //       "Authorization": "Bearer " + auth.user.token
  //     }
  //   })
  //   .then(response => {
  //       if(response.status===200) {
  //           console.log(response.data);
  //       }
  //   })
  //   .catch(error => {
  //     if(error.response.status===401) {
  //       console.log(error);
  //     }
  //   });
  //   setUserData({nombre: "David Eliseo", tipo: "Land Owner", verified: true});
  // },[]);

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <MDBox position="absolute" top="10px" left="20px" right="20px">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <MDBox style={{backgroundColor:"white"}} borderRadius="lg">
              <MDInput type="search" variant="outlined" label="Search" fullWidth />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={2}>
            <MDBox display="flex" flexDirection="column">
              <MDTypography variant="caption" color="light">
                <Switch value="grid" checked={gridView} inputProps={{ 'aria-label': 'Grid' }} size="small" onChange={gridViewSwitchHandler} /> Grid View
              </MDTypography>
              <MDTypography variant="caption" color="light">
                <Switch value="list" checked={!gridView} inputProps={{ 'aria-label': 'List' }} size="small" onChange={listViewSwitchHandler} /> List View
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={2}>
            <MDBox>
            <ThemeProvider theme={mytheme}>
              <Autocomplete
                    defaultValue="1"
                    color="primary"
                    options={["1","2","3"]}
                    renderInput={(params) => (
                      <FormField {...params} color="primary" sx={{
                        '& .MuiInput-underline:before': { borderBottomColor: '#ffffff' },
                        '& .MuiInput-underline:after': { borderBottomColor: '#ffffff' },
                      }} label="Location" InputLabelProps={{ shrink: true, sx:{color:'#ffffff'} }} />
                    )}
              />
              </ThemeProvider>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={2}>
              <MDInput type="date" variant="standard" label="Date" color="white" />
          </Grid>
        </Grid>
      </MDBox>
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
