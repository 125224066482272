/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "biota/LayoutContainers/DashboardLayout";
import DashboardNavbar from "biota/Navbars/DashboardNavbar";
import Footer from "biota/Footer";
import ProfileInfoCard from "biota/Cards/InfoCards/ProfileInfoCard";
import LaunchedProjectsList from "biota/Lists/LaunchedProjectsList";
import DefaultProjectCard from "biota/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/pages/profile/components/Header";

// Data
import profilesListData from "layouts/pages/profile/profile-overview/data/profilesListData";

// Images
import homeDecor1 from "assets/images/biota/project-4.png";
import homeDecor2 from "assets/images/biota/project-2.png";
import homeDecor3 from "assets/images/biota/project-1.png";
import homeDecor4 from "assets/images/biota/project-3.png";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { getUser } from "app/slices/userSlice";
import { logout } from "app/slices/authSlice";
import { useGetUserQuery } from "app/services/userApi";

function Overview() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isEdit, setIsEdit] = useState(false);
  const [projects, setProjects] = useState([
    { listing: "NFT Collection #1", proyecto: "Tirimbina Biological Reserve", descripcion: "Tirimbina Biological Reserve, is a non-profit organization..." },
    { listing: "NFT Collection #2", proyecto: "La Selva Biological Station", descripcion: "The Selva Biological Station covers an area of ​​1,531 ha,..." },
    { listing: "NFT Collection #3", proyecto: "The Lapa Verde Refuge", descripcion: "The Lapa Verde Refuge farm is managed by the Ecovida..." },
    { listing: "NFT Collection #4", proyecto: "Jarabillos River INT S.A.", descripcion: "Mr. Gonzalo Ramírez Guier, as representative of Río Jabillos..." }
  ]);
  const { status, data:user,isError, error, isLoading, isSuccess } = useGetUserQuery();

  const favProjects = projects.map((item) => (
    <Grid item xs={12} md={6} xl={3} key={item.listing}>
      <DefaultProjectCard
        image={homeDecor1}
        label={item.listing}
        title={item.proyecto}
        description={item.descripcion}
        action={{
          type: "internal",
          route: "/pages/profile/profile-overview",
          color: "success",
          label: "View Collection",
        }}
        authors={[
          { image: team1, name: "Elena Morison" },
          { image: team2, name: "Ryan Milly" },
          { image: team3, name: "Nick Daniel" },
          { image: team4, name: "Peterson" },
        ]}
      />
    </Grid>
  ));
  console.log("USER:", user);
  useEffect(() => {
    if (isError) {
      console.log("something went wrong on profile page!");
      console.log("Error:", error?.data);
    }
  }, [user, isError, isSuccess, navigate, dispatch]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header isEdit={isEdit} setIsEdit={setIsEdit} user={user} isLoading={isLoading}>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                user={user}
                title="profile information"
                description={`Hi, I’m ${ user.first_name }  ${user.last_name}: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality).`}
                info={{
                  fullName: "Alec M. Thompson",
                  mobile: "(44) 123 1234 123",
                  email: "alecthompson@mail.com",
                  location: "US",
                }}
                social={[
                  {
                    link: "https://www.facebook.com/CreativeTim/",
                    icon: <FacebookIcon />,
                    color: "facebook",
                  },
                  {
                    link: "https://twitter.com/creativetim",
                    icon: <TwitterIcon />,
                    color: "twitter",
                  },
                  {
                    link: "https://www.instagram.com/creativetimofficial/",
                    icon: <InstagramIcon />,
                    color: "instagram",
                  },
                ]}
                action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
            <Grid item xs={12} xl={8}>
              <LaunchedProjectsList isEdit={isEdit} title="launched projects" projects={profilesListData} shadow={false} />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h6" fontWeight="medium">
            Favorite Projects
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="button" color="text">
              NFT Collections
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox p={2}>
          <Grid container spacing={6}>
            {favProjects}
          </Grid>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
