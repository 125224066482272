/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import documentImage from "assets/images/biota/document.png";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import "./Documents.scss";
import StepperTitle from "components/StepperTitle/StepperTitle";

function Documents() {

  return (
    <MDBox>
      <StepperTitle
        title="Documents"
        subTitle="Legal documents related to your listings" />
      <MDBox mt={2}>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={4} md={3}>
            <div className="user-varification-document-card">
              <div className="img-container">
                <img src={documentImage} alt="document" />
              </div>
              <h5 className="document-card-title">Identification</h5>
              <p className="document-card-description">Government Issued ID</p>
              <Divider />
              <MDButton className="document-card-btn" variant="text" color="info">Upload</MDButton>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <div className="user-varification-document-card">
              <div className="img-container">
                <img src={documentImage} alt="document" />
              </div>
              <h5 className="document-card-title">Land Cerfitication</h5>
              <p className="document-card-description">Lorem, ipsum dolor.</p>
              <Divider />
              <MDButton className="document-card-btn" variant="text" color="info">Upload</MDButton>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <div className="user-varification-document-card">
              <div className="img-container">
                <img src={documentImage} alt="document" />
              </div>
              <h5 className="document-card-title">Proof of Ownership</h5>
              <p className="document-card-description">Lorem, ipsum dolor.</p>
              <Divider />
              <MDButton className="document-card-btn" variant="text" color="info">Upload</MDButton>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <div className="user-varification-document-card">
              <div className="img-container">
                <img src={documentImage} alt="document" />
              </div>
              <h5 className="document-card-title">Dimensions of the Land</h5>
              <p className="document-card-description">Lorem, ipsum dolor.</p>
              <Divider />
              <MDButton className="document-card-btn" variant="text" color="info">Upload</MDButton>
            </div>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default Documents;
