/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import { loadModules } from 'esri-loader';
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React example components
import ImpactTable from "biota/Tables/ImpactTable";

// Data
import impactTableData from "layouts/dashboards/biota/components/ProjectList/data/impactTableData";
import { useTranslation } from "react-i18next";
import { Map } from '@esri/react-arcgis';
import puntosFincas from 'assets/json/puntos_fincas.json';

function ProjectList() {
  const { t } = useTranslation();
  const [map, setMap] = useState(null);
  const [view, setView] = useState(null);
  
  const tablaFincas = puntosFincas.features.map((item)=>{
    return { project: item.properties.finca,
        "NFTs available": "20",
        sold: "$230,900",
        "project valuation": "29.9%",
        "Biodiversity impact": 100,
      }
  });

  const handleMapLoad = (imap, iview) => {
    setMap(imap);
    console.log(imap);
    setView(iview);
    loadModules(["esri/layers/GeoJSONLayer"]).then(([GeoJSONLayer]) => {
      const blob = new Blob([JSON.stringify(puntosFincas)], {
        type: "application/json"
      });
      const url = URL.createObjectURL(blob);
      const layerProyectosMarker = new GeoJSONLayer({
        url,
        outFields: ["*"],
        renderer: {
          "type": "simple",
          "field": "nombre",
          "label": "Proyectos Biota",
          "symbol": {
            "type": "simple-marker",
            "size": "48px",
            "color": "#ebb312",
            "outline": {
              "color": "rgba(255, 255, 255, 1)",
              "width": 5
            }
          }
        },
        title: "Proyectos",
        visible: true,
        popupEnabled: false,
        popupTemplate: null,
        opacity: 1,
        listMode: "hide",
        featureReduction: {
          "type": "cluster",
          "clusterRadius": "100px",
          "clusterMinSize": "32px",
          "clusterMaxSize": "64px",
          "labelingInfo": [{
            "deconflictionStrategy": "none",
            "labelExpressionInfo": {
              "expression": "Text($feature.cluster_count, '#,###')"
            },
            "symbol": {
              "type": "text",
              "color": "#004a5d",
              "font": {
                "weight": "bold",
                "family": "Noto Sans",
                "size": "14px"
              }
            },
            "labelPlacement": "center-center"
          }]
        }
      });
      imap.add(layerProyectosMarker);
    });
  };

  return (
    <Card sx={{ width: "100%" }}>
      <MDBox display="flex">
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          variant="gradient"
          bgColor="success"
          color="white"
          shadow="md"
          borderRadius="xl"
          ml={3}
          mt={-2}
        >
          <Icon fontSize="medium" color="inherit">
            language
          </Icon>
        </MDBox>
        <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
          {t("dashboard-table-title")}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <Grid container>
          <Grid item xs={12} md={8} lg={8}>
            <ImpactTable rows={tablaFincas} shadow={false} />
          </Grid>
          <Grid item xs={12} md={4} lg={4} sx={{ mt: { xs: 5, lg: 0 } }}>
            <Map 
              mapProperties={{ basemap: 'satellite' }}
              viewProperties={{
                center: [-84.0, 10.5],
                zoom: 10
              }}
              onLoad={handleMapLoad}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default ProjectList;
