import React from 'react'
import chairImage from "assets/images/ecommerce/black-chair.jpeg";
import cardImage from "assets/images/bg-player.jpeg";
import projectBack from "assets/images/project-back.jpg";
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MDBox from 'components/MDBox';
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';
import "./ProjectListCard.scss";

function ProjectListCard({ project }) {
    const { id, listing_title: listingTitle, description, area, nft_amount: nftAmount } = project;
    return (
        <MDBox className='project-list-card' sx={{boxShadow:1,backgroundColor:"white !important"}}
            component={Link} to={`/pages/projects/single-project/${id}`} >
            <div className='project-card-img-container'>
                <img src={projectBack} className="gallery-img" alt="project gallery" />
                {/* <IconButton aria-label="favorite" className="favorite-icon" color="error">
                    <FavoriteIcon color="error" />
                </IconButton> */}
            </div>
            <div className='project-list-card-content'>
                <h5 className='project-title'>{listingTitle}</h5>
                <div className='project-description'>{description}</div>

                <div className='project-list-footer'>
                    <div className='project-list-location-info'>
                        <LocationOnIcon /> <span>{area} miles away</span>
                    </div>
                    <div className='project-list-project-price'>{nftAmount}$</div>
                </div>
            </div>
        </MDBox>
        // <div className='project-list-card'>


        // </div>
    )
}

export default ProjectListCard