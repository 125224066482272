import DashboardLayout from 'biota/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'biota/Navbars/DashboardNavbar'
import MDBox from 'components/MDBox'
import React, { useMemo, useState } from 'react'
import { Button, CircularProgress, FormControl, FormGroup, FormHelperText, Input, InputAdornment, InputLabel, NativeSelect, Stack, TextField, Typography } from '@mui/material';
import { ReactComponent as PlaceholderCircle } from "assets/images/placeholder-img-circle.svg";
import UploadIcon from '@mui/icons-material/Upload';
import Footer from 'biota/Footer';
import { FileUpload } from '@mui/icons-material';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDDropzone from 'components/MDDropzone';
import { useForm } from 'react-hook-form';
import { useGetUserQuery } from 'app/services/userApi';
import { useAddProjectMutation } from 'app/services/projectsApi';
import { useUploadFilesMutation } from 'app/services/uploadFilesApi';
import ImageUploaderWithThumbs from 'components/ImageUploaderWithThumbs/ImageUploaderWithThumbs';
import { useNavigate } from 'react-router-dom';

import "./CreateProject.scss";
import ImageFileUploader from './components/ImageFileUploader/ImageFileUploader';

function CreateProject() {
    const inputRef = React.useRef();
    const [files, setFiles] = useState([]);
    const { register, control, handleSubmit, formState: { errors } } = useForm();
    const { data: user, isError: userIsError, error: userError, isLoading: userIsLoading, isSuccess: userIsSuccess } = useGetUserQuery();
    const [addProject, { isCreating }] = useAddProjectMutation();
    const [uploadFiles, { isLoading }] = useUploadFilesMutation();
    const [photoFiles, setPhotoFiles] = useState([]);
    const [certificateFiles, setCertificateFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [projectData, setProjectData] = useState({
        contract_address: "0X00000000000",
        owner: 0,//current loggedin userid
        listing_type_id: "1", //use this
        listing_title: "Sample title", //name
        latitude: 0,//empty
        longitude: 0,//empty
        status: "1", //use this
        area: 0, //leave empty
        description: "Short description", //description
        percentage_biologic: 0,//leave empty
        biota_index: 0,//leave empty
        plane_number: 0,//leave empty
        address: "Address", //leave empty
        biota_caracterization: "Sample text", //leave empty
        cover_type: "Sample text",//leave empty
        conservation_vacuum: 0,//leave empty
        hydro_priority: 0,//leave empty
        biologic_corridors: 0,//leave empty
        flora: 0,//leave empty
        fauna: 0,//leave empty
        nft_amount: 0,//use this
        initial_value: 0,//value per nft
        available_value: 0,//use this
    });
    const [selectedFiles, setSelectedFiles] = useState({
        logoFile: [],
        featuredFile: [],
        bannerFile: [],
        certificatesFiles: [],
        videoOrImagesFiles: []
    })
    // const handleChangeFile = (event) => {
    //     console.log(event.target.files);
    //     //OR
    //     console.log(inputRef.current.files)
    //     setFiles(prev => [...prev, event.target.files[0]])
    // };
    // const handleFileUploadClick = () => {
    //     //   var fileUploadDom = React.findDOMNode(this.refs.fileUpload);
    //     inputRef.current.click();

    // }
    const onSubmit = async (data) => {
        try {
            setLoading(prev=>!prev);
            const requestData = { ...projectData, ...data, owner: user?.id };
            const response = await addProject(requestData);
            const { success, projects: { id } } = response?.data || {};
            console.log("add project response:", response);
       
            const formData = new FormData();
            formData.append('related_id', id);
            formData.append('related_table', "projects");
            formData.append('file_description', "dummy");
            formData.append('file_types[0]', 1);
            if (selectedFiles.logoFile.length) {
                formData.set('files', selectedFiles.logoFile[0]);
                formData.set('file_use[0]', "logo");
    
                const logoResponse = await uploadFiles(formData);
                console.log("upload logo image response:", logoResponse);
            }
            if (selectedFiles.featuredFile.length) {
                formData.set('files', selectedFiles.featuredFile[0]);
                formData.set('file_use[0]', "featured_image");
    
                const featuredResponse = await uploadFiles(formData);
                console.log("upload featured image response:", featuredResponse);
            }
            if (selectedFiles.bannerFile.length) {
                formData.set('files', selectedFiles.bannerFile[0]);
                formData.set('file_use[0]', "banner");
    
                const bannerResponse = await uploadFiles(formData);
                console.log("upload banner image response:", bannerResponse);
            }
            if (photoFiles.length) {
                formData.set('files', photoFiles);
                formData.set('file_use[0]', "media");
    
                const mediaResponse = await uploadFiles(formData);
                console.log("upload media response:", mediaResponse);
            }
            if (certificateFiles.length) {
                formData.set('files', certificateFiles);
                formData.set('file_use[0]', "documents");
                const certificatesResponse = await uploadFiles(formData);
                console.log("upload certificates response:", certificatesResponse);
            }
            setLoading(prev=>!prev);
            navigate("/pages/projects/preview")
        } catch (error) {
            console.log("Error:",error);
            setLoading(prev=>!prev);
        }
        // setIsEdit(prev => !prev);
        // if (response?.data?.success) {
        //   setOpen(true);
        // }
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox mt={4} className="create-project-container">
                <MDBox className="create-project-title">
                    <h4>Create new Project</h4>
                    {/* <p>*Requiered Fields*</p> */}
                </MDBox>
                <MDBox className="create-project-content" py={4} component="form"
                    onSubmit={handleSubmit(onSubmit)}>
                    <ImageFileUploader setSelectedFiles={setSelectedFiles} type="circle" title="Logo Image" description="*This image will also be used for navigation. 350 x 350 recommended" />
                    <ImageFileUploader setSelectedFiles={setSelectedFiles} type="feature" title="Featured Image" description="*This image will be used for featuring your collection on the homepage, category pages, or other promotional areas of BIOTA. 600 x 400 recommended" />
                    <ImageFileUploader setSelectedFiles={setSelectedFiles} type="banner" title="Banner Image" description="*This image will appear at the top of your collection page. Avoid including too much text in this banner image, as the dimensions change on different devices. 1400 x 400 recommended." />
                    <MDBox className="create-project-form-container" mt={8}>
                        <MDBox
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                        >
                            <MDBox className="field-container">
                                <InputLabel className='input-label name-label' htmlFor='name-input'>Name</InputLabel>
                                <MDBox className="input-container">
                                    <TextField id="name-input" className="input-field"
                                        placeholder="Example: Treasure of the sea"
                                        error={!!errors?.listing_title}
                                        {...register("listing_title", { required: true })}
                                    />
                                </MDBox>
                            </MDBox>
                            <MDBox className="field-container">
                                <InputLabel className='input-label' htmlFor='url-input'>URL</InputLabel>
                                <FormHelperText className="create-form-field-desc-text">*Customize your URL on BIOTA. Must only contain lowercase letters,numbers, and hyphens</FormHelperText>
                                <MDBox className="input-container">
                                    <MDInput id="url-input" className="input-field"
                                        placeholder="https://opensea.io/collection/treasure-of-the-sea"
                                    // error={!!errors?.customizeUrl}
                                    // {...register("customizeUrl", { required: false })}
                                    />
                                </MDBox>
                            </MDBox>
                            <MDBox className="field-container">
                                <InputLabel className='input-label' htmlFor='description-input'>Description</InputLabel>
                                <MDBox className="create-form-field-desc-text">*Markdown syntax is supported. 0 of 1000 characters used.</MDBox>
                                <MDBox className="input-container">
                                    {/* <MDInput multiline rows={5} id="description-input"
                                        className="input-field" 
                                        error={!!errors?.description}
                                        {...register("description", { required: true })}
                                    /> */}
                                    <TextField multiline rows={5} id="description-input"
                                        className="input-field"
                                        error={!!errors?.description}
                                        {...register("description", { required: true })}
                                    />
                                </MDBox>
                            </MDBox>
                            <MDBox className="field-container">
                                <InputLabel>Links</InputLabel>

                                <Stack direction="column" spacing={1}>
                                    <MDInput className="input-field-prepend-text" InputProps={{
                                        startAdornment: <InputAdornment position="start">https://www.instagram.com/</InputAdornment>
                                    }} />
                                    <MDInput className="input-field-prepend-text" InputProps={{
                                        startAdornment: <InputAdornment position="start">https://www.instagram.com/</InputAdornment>
                                    }} />
                                </Stack>
                                {/* <FormGroup row className='form-group form-group-instagram'>
                                    <InputLabel className='input-label' htmlFor='name-input'>https://www.instagram.com/</InputLabel>
                                    <MDInput id="name-input" className="input-field" placeholder="" success />
                                </FormGroup>
                                <FormGroup row className='form-group form-group-instagram'>
                                    <InputLabel className='input-label' htmlFor='name-input'>https://www.instagram.com/</InputLabel>
                                    <MDInput id="name-input" className="input-field" placeholder="" success />
                                </FormGroup> */}
                            </MDBox>
                            <MDBox className="select-container">
                                <FormControl >
                                    <InputLabel variant="standard" htmlFor="land-size">
                                        Land size per nft
                                    </InputLabel>
                                    <NativeSelect
                                        defaultValue={30}
                                        inputProps={{
                                            name: 'land-size',
                                            id: 'land-size',
                                        }}
                                    >
                                        <option value={10}>Ten</option>
                                        <option value={20}>Twenty</option>
                                        <option value={30}>Thirty</option>
                                    </NativeSelect>
                                </FormControl>
                            </MDBox>
                            <MDBox className="select-container">
                                <FormControl>
                                    <InputLabel variant="standard" htmlFor="value-per-nft">
                                        Value per nft
                                    </InputLabel>
                                    <Input type="number"
                                        error={!!errors?.initial_value}
                                        {...register("initial_value", {
                                            required: true,
                                            min: 1, max: 10000,
                                        })}
                                    />
                                </FormControl>
                            </MDBox>
                            <Typography variant="subtitle1" mt={3}>Available Certifications</Typography>
                            <MDBox>
                                {useMemo(
                                    () => (
                                        <ImageUploaderWithThumbs
                                            title="Drag  drop file here, or click to select file"
                                            multiple
                                            accept={{
                                                'application/pdf': [],
                                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
                                            }}
                                            selectedItems={certificateFiles}
                                            setSelectedItems={setCertificateFiles}
                                        />
                                    ),
                                    [certificateFiles]
                                )}
                            </MDBox>
                            <Typography variant="subtitle1" mt={3}>Videos or photos</Typography>
                            <MDBox>

                                {useMemo(
                                    () => (
                                        <ImageUploaderWithThumbs
                                            title="Drag  drop file here, or click to select file"
                                            multiple
                                            accept={{
                                                'image/*': [],
                                                'video/*': [],
                                            }}
                                            selectedItems={photoFiles}
                                            setSelectedItems={setPhotoFiles}
                                        />
                                    ),
                                    [photoFiles]
                                )}
                                {/* {useMemo(
                                    () => (
                                        <MDDropzone options={{
                                            addRemoveLinks: true, acceptedFiles: 'video/*, image/*',
                                            // addedfile:(file)=>{
                                            //     console.log("add file:",file);
                                            //     // return file;
                                            // },
                                            init: (evt) => console.log("EVENT:", evt),
                                            complete: (file) => { console.log("Commplete", file); },
                                            removedFile: (file) => console.log("Remove file:", file),
                                            cancelFile: (file) => console.log("Cancel file:", file),
                                            deleteFile: (file) => console.log("detelte file:", file)

                                        }} />
                                    ),
                                    []
                                )} */}
                            </MDBox>

                            <Stack mt={2} alignItems="flex-end">
                                <MDButton
                                    disabled={loading}
                                    type="submit"
                                    variant="contained" color="success"
                                    sx={{ width: "200px" }}>

                                    <MDBox color="inherit" component="span" mr={1}>Submit</MDBox>
                                    {loading && <CircularProgress color="inherit" size={20} />}
                                </MDButton>
                            </Stack>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </MDBox>
            <Footer />
        </DashboardLayout>
    )
}

export default CreateProject