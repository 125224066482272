/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
import selectData from "layouts/pages/account/settings/components/SubmitSupportTicket/data/selectData";
import { Button, MenuItem, Stack, TextField } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import MDButton from "components/MDButton";
import { topicsSelectData } from "utils/utils";
import { useAddSupportTicketMutation } from "app/services/supportTicketsApi";
import { useForm } from "react-hook-form";

function BasicSettings() {
  const [addSupportTicket, { isLoading,isSuccess,status,isError }] = useAddSupportTicketMutation();
  const { register, control, handleSubmit, formState:{errors,isDirty,isValid} } = useForm();
  const onSubmit =async (data) => {
    console.log("Ticket data:", {project_id:0,...data});
  const response = await addSupportTicket({project_id:0,...data});
  console.log("RESPONSE",response);
  }

  return (
    <Card id="submit-support-tickets" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Submit Support Ticket</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField label="Title" variant="outlined" fullWidth 
             error={!!errors?.title}
             {...register("title", { required: "This field is required!" })}
            />

          </Grid>
          <Grid item xs={12} sm={6}>
            <MDBox sx={{
              height: "100%", "& .MuiSvgIcon-root": { display: "inline-block" },
              "& .MuiOutlinedInput-root, & .MuiSelect-select": { height: "100% !important" }
            }}>
              <TextField
                select
                fullWidth
                label="Topic"
                defaultValue=''
                variant="outlined"
                inputProps={register('topic', {
                  required: 'This field is required!',
                })}
                error={!!errors.topic}
                sx={{ height: "100%", "& .MuiInput-root": { flex: 1 } }}
              >
                {topicsSelectData.map((topic) => (
                  <MenuItem key={topic.value} value={topic.value}>
                    {topic.label}
                  </MenuItem>
                ))}
              </TextField>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <TextField label="Subject" multiline rows={5} fullWidth 
             error={!!errors?.subject}
             {...register("subject", { required: "This field is required!" })}
            />
          </Grid>
          <Grid item xs={12} justifyContent="flex-end">
            <Stack direction="row" justifyContent="flex-end" >
              <MDButton variant="contained" type="submit" color="info" endIcon={<SendIcon />}>
                Submit
              </MDButton>
            </Stack>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default BasicSettings;
