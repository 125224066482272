/* eslint-disable */
import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import userService from '../services/userService'

const initialState = {
  user: null,
  users: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}


// get all users
export const userList = createAsyncThunk('user/userlist', async (_, thunkAPI) => {
  try {
    return await userService.userList()
  } catch (error) {
    const message = error.message;
    return thunkAPI.rejectWithValue(message)
  }
});
// get single user
export const getUser = createAsyncThunk('user/user', async (_, thunkAPI) => {
  try {
    alert("called")
    return await userService.getUser()
  } catch (error) {
    const message = error.message;
    return thunkAPI.rejectWithValue(message)
  }
});

// update single user
export const updateUser = createAsyncThunk('user/update', async (updatedUser, thunkAPI) => {
  try {
    return await userService.updateUser(updatedUser)
  } catch (error) {
    const message = error.message;
    return thunkAPI.rejectWithValue(message)
  }
});


export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUser: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(userList.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.users = action.payload
      })
      .addCase(userList.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.users = null
      })
      .addCase(getUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        if (action.payload?.failureMessage === "ExpiredException: Expired token"
          || action?.payload?.failureMessage === "Exception: Signature verification failed") {
          state.isError = true;
          state.message = action.payload?.failureMessage;
        } else {
          state.isError = false;
          state.message = "";
          state.user = action.payload.user[0];
        }
      })
      .addCase(getUser.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.user = null
      }).addCase(updateUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        if (action.payload?.failureMessage === "ExpiredException: Expired token"
          || action?.payload?.failureMessage === "Exception: Signature verification failed") {
          state.isError = true;
          state.message = action.payload?.failureMessage;
        } else {
          state.isError = false;
          state.message = "";
          state.user = { ...current(state).user, ...action.payload.user };
        }
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.user = null
      })
  },
})

export const { resetUser } = userSlice.actions
export default userSlice.reducer