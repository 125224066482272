import { useSelector } from 'react-redux';
import {
    Routes,
    Route,
    Link,
    Navigate,
    Outlet,
  } from 'react-router-dom';
  import { useAuth } from "../../use-auth";
  
  function ProtectedRoute({ user, redirectPath = '/authentication/sign-in' }){
    // const auth = useAuth();
    // if (!auth || auth.user.email==="") {
    //   return <Navigate to={redirectPath} replace />;
    // }
    const { user:loginUser, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth);

    if (!loginUser) {
      return <Navigate to={redirectPath} replace />;
    }
    return <Outlet />;
  };

  export default ProtectedRoute;