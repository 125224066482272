import React, { useState } from 'react'
import ImageIcon from '@mui/icons-material/Image';

import "./ImageFileUploader.scss";
import MDBox from 'components/MDBox';


function ImageFileUploader({type,title, description,setSelectedFiles}) {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleFileChange = (e) => {
    e.preventDefault();
    let files;
    // setSizeError(false);

    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const file = e.target.files[0];
      const fsize = file.size;
      const fileSize = Math.round((fsize / 1024));
      //10240 == 10mb
      // if (fileSize > 10240) {
      //     setSizeError(true)
      //     return;
      // }
      // reader.result;
      // e.target.files[0].name
      setSelectedImage(reader.result);
      if(type==="circle"){
        setSelectedFiles(prev=>({...prev,logoFile:[file]}))
      }else  if(type==="feature"){
        setSelectedFiles(prev=>({...prev,featuredFile:[file]}))
      }else  if(type==="banner"){
        setSelectedFiles(prev=>({...prev,bannerFile:[file]}))
      }
    };
    reader.readAsDataURL(files[0]);
  };
  return (
    <div className='image-file-uploader'>
      <div className='image-file-uploader-title'>{title}</div>
      <MDBox>
      <p className='image-file-uploader-desc'>{description}</p>
      <input
        accept="image/*"
        id={`contained-button-file-${type}`}
        type="file"
        className='uploader-file-input'
        onChange={handleFileChange}
      />
      <label htmlFor={`contained-button-file-${type}`} className={`placehoder-img placeholder-${type}`}>
        {selectedImage ? <img src={selectedImage} alt="project profile" /> : <ImageIcon fontSize="large" />}
      </label>
      </MDBox>
    </div>
  )
}

export default ImageFileUploader