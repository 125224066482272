/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function FundingMethodList({ title, categories }) {
  const renderItems = categories.map(({ color, icon, name, description }, key) => (
    <MDBox
      key={name}
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      borderRadius="lg"
      py={1}
      pr={2}
      mb={categories.length - 1 === key ? 0 : 1}
    >
      <MDBox display="flex" flexDirection="column" flexGrow="1">
        <MDBox display="flex" alignItems="center" flexGrow="1">
          <MDBox
            display="grid"
            alignItems="center"
            justifyContent="center"
            bgColor={color}
            borderRadius="lg"
            shadow="md"
            color="white"
            width="2rem"
            height="2rem"
            mr={2}
            variant="gradient"
            fontSize="0.875rem"
          >
            <Icon
              fontSize="32pt"
              sx={{
                display: "grid",
                placeItems: "center",
              }}
            >
              {icon}
            </Icon>
          </MDBox>
          <MDBox display="flex" flexDirection="column" flexGrow="1">
            <MDTypography variant="h4" color={color} fontWeight="medium" gutterBottom>
              {name}
            </MDTypography>
            <MDTypography variant="caption" color="text">
              {description}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" flexDirection="column" width="160px;">
            <MDTypography variant="caption">
              Enabled <Switch defaultChecked />
            </MDTypography>
            <MDTypography variant="caption">
              Set as favorite <Switch defaultChecked />
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox p="20px">
          Bank Account Number
        </MDBox>
      </MDBox>
    </MDBox>
  ));

  return (
    <MDBox>
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderItems}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Typechecking props for the CategoriesList
FundingMethodList.propTypes = {
  title: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default FundingMethodList;
