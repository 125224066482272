
import { Avatar, Checkbox, FormControl, Grid, InputLabel, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, MenuItem, Select, TextField } from '@mui/material';
import MDBox from 'components/MDBox';
import StepperTitle from 'components/StepperTitle/StepperTitle';
import FormField from 'layouts/applications/userverification/components/FormField';
import React, { useState } from 'react'
import bankImage from "assets/images/bank.svg";
import paypalImage from "assets/images/paypal.svg";

import "./WithdrawalMethods.scss";

function WithdrawalMethods() {
    const [checked, setChecked] = React.useState([1]);
    const [bankChecked, setBankChecked] = useState(false);
    const [paypalChecked, setPaypalChecked] = useState(false);
    const [checkedValue, setCheckedValue] = useState("");

    const [list, setList] = useState([{ img: bankImage, text: "Bank Account 1", checked: false, name: "bank" },
    { img: paypalImage, text: "Paypal", checked: true, name: "paypal" }])
    const handleToggle = (value) => () => {
        setList(prev => {
            return prev.map((item) => ({ ...item, checked: !item.checked }))
        })
    };
    return (
        <MDBox className="withdrawal-methods">
            <StepperTitle
                title="Withdrawal Method"
                subTitle="" />
            <MDBox mt={2}>
                <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {list.map((listItem) => {
                        const labelId = `checkbox-list-secondary-label-${listItem.text}`;
                        return (
                            <ListItem
                                key={listItem.text}
                                secondaryAction={
                                    <Checkbox
                                        edge="end"
                                        onChange={handleToggle(listItem.name)}
                                        checked={listItem.checked}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                }
                                disablePadding
                            >
                                <ListItemButton>
                                    <img src={listItem.img} className="withdrawal-methods-img" alt="bank" />
                                    <h5 className='withdrawal-methods-text'>{listItem.text}</h5>
                                    {listItem.checked && (<div className="withdrawal-methods-amount">
                                        <label htmlFor={`withdrawal-methods-amount-${listItem.text}`}>Amount</label>
                                        <TextField id={`withdrawal-methods-amount-${listItem.text}`} placeholder="$ or BT" variant="standard" />

                                    </div>)}
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>
            </MDBox>
        </MDBox>
    )
}

export default WithdrawalMethods