import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import { useEffect, useState } from "react";
import MDDatePicker from "components/MDDatePicker";
import axios from "axios";
import Flatpickr from "react-flatpickr";

import paisesCodigos from "assets/resources/countries";
import { Controller, useForm } from "react-hook-form";
import { CircularProgress, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { countriesData } from "utils/utils";
import { useSelector } from "react-redux";

function Step1({ update, errorMessage, userType }) {
  const { register, control, handleSubmit, formState: { errors } } = useForm();
  const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth);
 
  const onSubmit = (data) => {
    update(data);
  }

  return (
    <Card>
      <MDBox
        variant="gradient"
        bgColor="success"
        borderRadius="lg"
        coloredShadow="success"
        mx={2}
        mt={-3}
        p={3}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          Register
        </MDTypography>
        <MDTypography display="block" fontWeight="medium" variant="button" color="white" my={1}>
          {userType === 1 ? "Land Owner Account" : "Investor Account"}
        </MDTypography>
      </MDBox>
      <MDBox pt={4} pb={3} px={3}>
        <MDBox component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
          <MDBox mb={2}>
            <MDInput
              type="email"
              label="Email"
              name="email"
              variant="standard"
              fullWidth
              // onChange={(evt) => handleOnChange(evt)}
              error={!!errors?.email}
              {...register("email", {
                required: "This field is required!",
                pattern: {
                  value: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                  message: "Invalid email address"
                },
              })}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="password"
              name="password"
              label="Password"
              variant="standard"
              fullWidth
              // onChange={(evt) => handleOnChange(evt)}
              error={!!errors?.password}
              {...register("password", { required: "This field is required!" })}
            />
          </MDBox>
          <MDBox mb={2}>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6}>
                <MDInput
                  type="text"
                  label="First Name"
                  name="first_name"
                  variant="standard"
                  error={!!errors?.first_name}
                  {...register("first_name", { required: "This field is required!" })}
                />
              </Grid>
              <Grid item lg={6} md={6}>
                <MDInput
                  type="text"
                  label="Last Name"
                  name="last_name"
                  variant="standard"
                  error={!!errors?.last_name}
                  {...register("last_name", { required: "This field is required!" })}
                />
              </Grid>
            </Grid>
          </MDBox>

          <MDBox mb={2}>
            <Grid container spacing={1}>
              <Grid item lg={3} md={3}>
                <MDTypography
                  display="block"
                  fontWeight="small"
                  variant="button"
                  color="black"
                  my={1}
                >
                  Birthday
                </MDTypography>
              </Grid>
              <Grid item lg={4} md={4}>
                <MDBox>
                  {/* <MDDatePicker
                    label="Birthday"
                    fullWidth
                    onChange={(evt) => handleOnChangeFechaNac(evt)}
                  /> */}
                  <Flatpickr
                    render={({ defaultValue }, ref) => (
                      <MDInput
                        defaultValue={defaultValue}
                        error={!!errors?.fecha_nac}
                        {...register("fecha_nac", { required: "This field is required!" })}
                        inputRef={ref} />
                    )}
                  />
                </MDBox>
              </Grid>
              <Grid item lg={5} md={5}>
                <MDBox sx={{
                  height: "100%", "& .MuiSvgIcon-root": { display: "inline-block" },
                  "& .MuiOutlinedInput-root, & .MuiSelect-select": { height: "100% !important" }
                }}>
                  <TextField
                    select
                    fullWidth
                    label="Gender"
                    defaultValue=''
                    inputProps={register('sexo', {
                      required: 'This field is required!',
                    })}
                    error={!!errors.sexo}
                    sx={{ height: "100%" }}
                  >
                    {["Male", "Female"].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <Grid item xs={12} mb={2}>
            <MDBox sx={{ height: "45px", "& .MuiSvgIcon-root": { display: "inline-block" }, "& .MuiOutlinedInput-root, & .MuiSelect-select": { height: "45px" } }}>
              <TextField
                select
                fullWidth
                label="Country"
                defaultValue=''
                inputProps={register('country', {
                  required: 'This field is required!',
                })}
                error={!!errors.country}
                sx={{ height: "45px" }}
              >
                {/* {countries.map((country) => (
                  <MenuItem key={country.label} value={country.value}>
                    {country.label}
                  </MenuItem>
                ))} */}
                {countriesData.map((country) => (
                  <MenuItem key={country.label} value={country.value}>
                    {country.label}
                  </MenuItem>
                ))}
              </TextField>
            </MDBox>
          </Grid>

          <MDBox>
            <Grid container spacing={2}>
              <Grid item lg={5} md={5}>
                <MDBox sx={{
                  height: "100%", "& .MuiSvgIcon-root": { display: "inline-block" },
                  "& .MuiOutlinedInput-root, & .MuiSelect-select": { height: "100% !important" }
                }}>
                  <TextField
                    select
                    fullWidth
                    label="Area"
                    defaultValue=''
                    inputProps={register('area', {
                      required: 'This field is required!',
                    })}
                    error={!!errors.area}
                    sx={{ height: "100%" }}
                  >
                    {/* {countryCodes.map((code) => (
                      <MenuItem key={code} value={code}>
                        {code}
                      </MenuItem>
                    ))} */}
                    {countriesData.map(({ code }) => (
                      <MenuItem key={code} value={code}>
                        {code}
                      </MenuItem>
                    ))}
                  </TextField>
                </MDBox>
              </Grid>
              <Grid item lg={7} md={7}>
                <MDBox>
                  <MDInput
                    type="text"
                    label="Number"
                    variant="standard"
                    fullWidth
                    name="phone_number"
                    error={!!errors?.phone_number}
                    {...register("phone_number", { required: "This field is required!" })}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={4} mb={1}>
            {errorMessage && (
              <MDTypography
                display="block"
                fontWeight="medium"
                variant="button"
                color="error"
                my={1}
              >
                {errorMessage}
              </MDTypography>
            )}
          </MDBox>
          <MDBox mt={4} mb={1}>
            <MDButton variant="gradient" color="success" type="submit" disabled={isLoading}  fullWidth>
              <MDBox component="span" color="inherit" mr={1}>Sign Up</MDBox> 
              {isLoading && <CircularProgress color="inherit" size={20} />}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Step1;
