import Footer from 'biota/Footer';
import DashboardLayout from 'biota/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'biota/Navbars/DashboardNavbar';
import MDBox from 'components/MDBox';
import React, { useEffect, useState } from 'react'
import backgroundImage from "assets/images/biota/bg-1.jpeg";
import GridOnIcon from '@mui/icons-material/GridOn';
import Gallery from '@mui/icons-material/PhotoAlbum';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import chairImage from "assets/images/biota/project-1.png";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import "./SingleProject.scss";
import { Card, CardActions, CardContent, CardMedia, Divider, Grid, Icon, IconButton, List, Stack, Tab, Tabs, Typography } from '@mui/material';
import { Grid3x3Outlined } from '@mui/icons-material';
import { format } from 'date-fns';
import MDInput from 'components/MDInput';
import DefaultLineChart from 'biota/Charts/LineCharts/DefaultLineChart';
import DataTable from 'examples/Tables/DataTable';
import { getTransactionTypeById, groupByObjectByKey,monthsShortNamesData } from 'utils/utils';
import { useGetSingleProjectQuery, useGetProjectTransactionsQuery, useGetProjectFilesQuery} from 'app/services/projectsApi';
import { useGetProjectNFTSQuery } from 'app/services/nftApi';
import { useParams } from 'react-router-dom';
import Lightroom from 'react-lightbox-gallery'
import activityChartData from './data/activityChartData';
import SingleNFTCard from './SingleNFTCard/SingleNFTCard';
import LatestTransactionsDataTableData from './data/latestTransactionsDataTableData';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            style={{ width: "100%" }}
        >
            {value === index && (
                <MDBox>
                    <Typography>{children}</Typography>
                </MDBox>
            )}
        </div>
    );
}

function SingleProject() {
    const [value, setValue] = React.useState(0);
    const [nftFilteredData, setNftFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("")
    const [chartData, setChartData] = useState(activityChartData)
    const [transactionsTableData, setTransactionsTableData] = useState(LatestTransactionsDataTableData)

    const { id } = useParams();
    const images=[];
    const settings= {
        columnCount:{
          default:6,
          mobile:2,
          tab:4
        },
        mode: 'light'
      }
    const { status, data: projectData, isError, error, isLoading, isSuccess } = useGetSingleProjectQuery(id);
    const { data: projectNFTSData, isLoading: isNftLoading } = useGetProjectNFTSQuery(id);
    const { data: projectTransactions, isLoading: traansactionIsLoading } = useGetProjectTransactionsQuery(id);
    const {  data: projectFilesData, isLoading:filesIsLoading } = useGetProjectFilesQuery(id);
    console.log("projectTransactions", projectTransactions)
    const { listing_title: listingTitle, description, items,
        owners, avg_price: avgPrice, volume_traded: volumeTraded, banner, logo,
        owner_name: ownerName,
        social } = projectData?.projects || {};
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const loadImages= ()=>{
        projectFilesData?.filter(galleryItem=>galleryItem.use==="media")?.map((item,idx) =>
            (
                images.push({src:item.file_path,sub:listingTitle})
            )
        )
    };
    loadImages();
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    }
    useEffect(() => {
        if (searchTerm.length) {
            const filterdData = projectNFTSData?.filter(function (v, i) {
                return (v.number_of.toString().includes(searchTerm.toLowerCase()) || v.name.toLowerCase().includes(searchTerm.toLowerCase()));
            });
            setNftFilteredData(filterdData);
        } else {
            setNftFilteredData([]);
        }

    }, [searchTerm]);
    useEffect(() => {
        // let abc=[
        //     {
        //       id: "id",
        //       status: "status 1",
        //       user_id: "uid",
        //       listing_id: "lid",
        //       nft_key: "key",
        //       amount: "am",
        //       price: "100",
        //       transaction_type: "sell",
        //       date_created: "date",
        //     },
        //   ];
        if (projectTransactions) {
            const data = projectTransactions?.map(item => ({
                ...item,
                transaction_type: getTransactionTypeById(item.transaction_type),
                date_created: `${format(new Date(item.date_created), 'dd.MM.yyyy')}  ${format(new Date(item.date_created), 'hh.mm')}`
            }))
            setTransactionsTableData((prev) => ({ ...prev, rows: data }));

            //group by month data
            const filteredChartData = projectTransactions?.map(item => {
                let price = item.amount.replace("$", "");
                price = price.replaceAll(",", "");
                return {
                    month: format(new Date(item.date_created), 'MMM'),
                    amount: parseFloat(price)
                }
            });
            const groupByResult = groupByObjectByKey(filteredChartData, "month")
            // console.log("groupByResult", groupByResult);
            const months = Object.keys(groupByResult);
            const values = Object.values(groupByResult).map(item=>item.reduce((sum,num)=>sum+num));
            // console.log("Months",months)
            // console.log("values",values);
            // // months.push("Sept");
            // values.push(2000)
            // months.push("Oct");
            // values.push(2500)
            
            // console.log("filteredChartData", filteredChartData);
            setChartData(prev=>{
                return {...prev,labels:months,datasets:[{...prev.datasets,data:values}]}
            })
        }


    }, [projectTransactions])
    return (
        <DashboardLayout>
            <DashboardNavbar />
            {isLoading || isNftLoading ? "Loading..." : (
                <MDBox mt={4} className="single-project-container">
                    <MDBox
                        className="top-banner"
                        position="relative"
                        minHeight="18.75rem"
                        borderRadius="xl"
                        style={{ padding: "10px 20px" }}
                        sx={{
                            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                                `${linearGradient(
                                    rgba(gradients.primary.main, 0.1),
                                    rgba(gradients.dark.main, 0.3)
                                )}, url(${banner || backgroundImage})`,
                            backgroundSize: "cover",
                            backgroundPosition: "50%",
                            overflow: "hidden",
                        }}
                    />
                    <Grid container spacing={3} className="single-project-stats-container">
                        <Grid item xs={12} md={6} lg={3} className="single-project-stats-left">
                            <h4>{listingTitle}</h4>
                            <div><span>Created by</span> {ownerName}</div>
                        </Grid>
                        <Grid item xs={12} md={12} className="single-project-stats-center">
                            <div className='img-container'>
                                <img src={logo || chairImage} alt="chair" />
                            </div>
                            <Stack direction="row" spacing={3}>
                                <MDBox>
                                    <h4>{items || 0}k</h4>
                                    <span>Items</span>
                                </MDBox>
                                <MDBox>
                                    <h4>{owners || 0}k</h4>
                                    <span>Owners</span>
                                </MDBox>
                                <MDBox>
                                    <h4>{avgPrice || 0}k</h4>
                                    <span>Average Price</span>
                                </MDBox>
                                <MDBox>
                                    <h4>{volumeTraded || 0}k</h4>
                                    <span>Volume Traded</span>
                                </MDBox>
                            </Stack>
                            <MDBox>
                                {description}
                            </MDBox>
                            {/* <Stack direction="row" spacing={3}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <GridOnIcon fontSize='medium' />
                                <Typography variant='body2' component="span" fontSize={14} fontWeight="bold">Items</Typography>
                            </Stack>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <MonitorHeartIcon fontSize='medium' />
                                <Typography variant='body2' component="span" fontSize={14} fontWeight="bold">Activity</Typography>
                            </Stack>
                        </Stack> */}
                        </Grid>
                        <Grid item xs={12} md={12} lg={3} className="single-project-stats-right"  >
                            <Stack direction="row" spacing={1} justifyContent="flex-end">
                                {/* <IconButton>
                                    <FacebookIcon />
                                </IconButton>
                                <IconButton><InstagramIcon /></IconButton>
                                <IconButton href="http://www.google.com"><TwitterIcon /></IconButton> */}
                                {Object.entries(JSON.parse(social.toString()))?.map(([property, url], idx) => {
                                    return ["facebook", "twitter", "instagram"].includes(property.toLowerCase()) && (
                                        <IconButton href={url} key={property}>
                                            {property.toLowerCase() === "facebook" && <FacebookIcon />}
                                            {property.toLowerCase() === "twitter" && <TwitterIcon />}
                                            {property.toLowerCase() === "instagram" && <InstagramIcon />}
                                        </IconButton>
                                    )
                                }
                                )}
                            </Stack>
                        </Grid>

                    </Grid>
                    <Grid container>
                        <Stack spacing={16} justifyContent="center" width="100%" alignItems="center"
                            direction="row" >
                            <Tabs centered value={value} onChange={handleChange}
                                sx={{ marginTop: "30px" }}
                                aria-label="basic tabs example">
                                <Tab sx={{ padding: "3px 10px" }} icon={<GridOnIcon />} iconPosition="start" label="Items" {...a11yProps(0)} />
                                <Tab sx={{ padding: "3px 10px" }} icon={<Gallery />} iconPosition="start" label="Gallery" {...a11yProps(1)} />
                                <Tab sx={{ padding: "3px 10px" }} icon={<MonitorHeartIcon />} iconPosition="start" label="Activity" {...a11yProps(2)} />
                            </Tabs>
                        </Stack>
                    </Grid>
                    <Grid container>
                        <TabPanel value={value} index={0}>
                            <Card sx={{ padding: "40px 20px", marginTop: "50px" }} className="single-project-cards-container">
                                <Grid container sx={{ marginBottom: "20px" }}>
                                    <Grid item xs={12} md={8} mx="auto">
                                        <MDInput fullWidth label="Search here"
                                            value={searchTerm}
                                            onChange={handleSearchChange} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    {/* {projectNFTSData?.length ? projectNFTSData?.map(nft => (
                                        <Grid key={nft.key} item xs={12} md={6} lg={4} xl={3}>
                                            <SingleNFTCard nftData={nft} />
                                        </Grid>
                                    )) : (
                                        <Grid item xs={12}>
                                            There is no NFTS available.
                                        </Grid>
                                    )} */}

                                    {nftFilteredData.length ? nftFilteredData?.map((nft) => (
                                        <Grid key={nft.key} item xs={12} md={6} lg={4} xl={3}>
                                            <SingleNFTCard nftData={nft} />
                                        </Grid>
                                    )) : projectNFTSData?.map((nft) => (
                                        <Grid key={nft.key} item xs={12} md={6} lg={4} xl={3}>
                                            <SingleNFTCard nftData={nft} />
                                        </Grid>
                                    ))
                                    }
                                </Grid>
                            </Card>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <Card sx={{ padding: "40px 20px", marginTop: "50px" }} className="single-project-cards-container">
                                <Lightroom images={images} settings={settings} />  
                            </Card>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <Card sx={{ padding: "40px 20px", marginTop: "50px" }} className="single-project-cards-container">
                                <Grid container sx={{ marginBottom: "20px" }}>
                                    <Grid item xs={12} md={8} mx="auto">
                                        <MDInput fullWidth label="Search here" />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} fullWidth>
                                    <Grid item xs={12}>
                                        <DefaultLineChart
                                            title="Collection activity chart"
                                            fullWidth
                                            chart={chartData}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid item xs={12}>
                                            <Typography variant="h5" mt={3}>Latest Transactions</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DataTable table={transactionsTableData} entriesPerPage canSearch />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>
                        </TabPanel>
                        
                    </Grid>
                </MDBox>

            )}
            <Footer />
        </DashboardLayout>
    )
}

export default SingleProject