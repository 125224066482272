/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Settings page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import Sidenav from "layouts/pages/account/settings/components/Sidenav";
import Header from "layouts/pages/account/settings/components/Header";
import BasicInfo from "layouts/pages/account/settings/components/BasicInfo";
import BasicSettings from "layouts/pages/account/settings/components/BasicSettings";
import ChangePassword from "layouts/pages/account/settings/components/ChangePassword";
import Authentication from "layouts/pages/account/settings/components/Authentication";
import FundingMethods from "layouts/pages/account/settings/components/FundingMethods";
import Notifications from "layouts/pages/account/settings/components/Notifications";
import SubmitSupportTicket from "layouts/pages/account/settings/components/SubmitSupportTicket";
import DeleteAccount from "layouts/pages/account/settings/components/DeleteAccount";
import { useGetUserSettingsQuery, useUpdateUserSettingsMutation } from "app/services/userSettingsApi";
import { useEffect, useState } from "react";
import { Alert, CircularProgress, Fab, Snackbar } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch,useSelector } from "react-redux";
import { getUser } from "app/slices/userSlice";
import { useGetUserQuery } from "app/services/userApi";
import SaveIcon from '@mui/icons-material/Save';

function Settings() {
  const { status, data, error, isLoading, isSuccess } = useGetUserSettingsQuery();
  const { status:userStatus, data:user, error:userError, isLoading:userLoading, isSuccess:userSuccess } = useGetUserQuery();

  // const { data:userData} = useGetUserQuery();
  // console.log("USERDATA API",user)
  const [updateSettings, { isLoading: isUpdating }] = useUpdateUserSettingsMutation();


  const dispatch = useDispatch();

  


  const [userSettings, setUserSettings] = useState(null);
  const [isChanged, setIsChanged] = useState(false)
  const [open, setOpen] = useState(false);
  console.log("SETTINGS Data", data);
  console.log("userSattings", userSettings);
  const handleUpdateSettings = async () => {
    const response = await updateSettings({ ...userSettings });
    console.log("Update response:", response)
    if (response?.data?.success) {
      setOpen(true);
    }
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (isSuccess) {
      setUserSettings(data?.user_settings?.[0]);
    } else {
      console.log("there is something went wrong with user settings!!!")
    }
  }, [status, data]);
  useEffect(() => {
    //data?.user_settings?.[0]
    console.log("Data values:", data?.user_settings?.[0])
    console.log("Settings values:", userSettings)
    if (data?.user_settings?.[0] && userSettings) {
      if (Object.values(data?.user_settings?.[0]).join() !== Object.values(userSettings).join()) {
        setIsChanged(true);
      } else {
        setIsChanged(false);
      }
    }
  }, [userSettings]);
console.log("OPEN",open)
  return (
    <BaseLayout>
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Updated successfully!
        </Alert>
      </Snackbar>
      {isChanged && (
        <Fab color="primary" aria-label="add" sx={{
          position: "fixed",
          right: 20,
          bottom: 20,
          zIndex: 200
        }} onClick={handleUpdateSettings}>
          <SaveIcon fontSize="medium" />
        </Fab>
      )}
      <MDBox mt={4}>
        {
          isLoading || userLoading ? <CircularProgress color="info" /> : (
            <Grid container spacing={3}>
              <Grid item xs={12} lg={3}>
                <Sidenav />
              </Grid>
              <Grid item xs={12} lg={9}>
                <MDBox mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Header user={user}/>
                    </Grid>
                    <Grid item xs={12}>
                      <BasicInfo user={user}/>
                    </Grid>
                    <Grid item xs={12}>
                      <BasicSettings userSettings={userSettings} setUserSettings={setUserSettings} />
                    </Grid>
                    <Grid item xs={12}>
                      <ChangePassword user={user}/>
                    </Grid>
                    <Grid item xs={12}>
                      <Authentication userSettings={userSettings} setUserSettings={setUserSettings} />
                    </Grid>
                    <Grid item xs={12}>
                      <FundingMethods />
                    </Grid>
                    <Grid item xs={12}>
                      <Notifications userSettings={userSettings} setUserSettings={setUserSettings} />
                    </Grid>
                    <Grid item xs={12}>
                      <SubmitSupportTicket />
                    </Grid>
                    <Grid item xs={12}>
                      <DeleteAccount />
                    </Grid>
                  </Grid>
                </MDBox>
              </Grid>
            </Grid>
          )
        }
      </MDBox>
    </BaseLayout>
  );
}

export default Settings;
