/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import { useSelector } from "react-redux";
import MDDatePicker from "components/MDDatePicker";
import { InputAdornment, MenuItem, TextField } from "@mui/material";
import { countriesData } from "utils/utils";
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { CalendarTodayRounded } from "@mui/icons-material";

function BasicInfo({user}) {
  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Basic Info</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField label="First Name" placeholder="Alec" value={user?.first_name} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField label="Last Name" placeholder="Thompson" value={user?.last_name} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                {/* <Autocomplete
                  options={selectData.gender}
                  value={user?.sex==="m"?"Male":"Female"}
                  renderInput={(params) => (
                    <FormField {...params} label="I'm" InputLabelProps={{ shrink: true }} />
                  )}
                /> */}
                <MDBox sx={{
                  height: "100%", "& .MuiSvgIcon-root": { display: "inline-block" },
                  "& .MuiOutlinedInput-root, & .MuiSelect-select": { height: "100% !important" }
                }}>
                  <TextField
                    select
                    fullWidth
                    label="I'm"
                    variant="standard"
                    defaultValue=''
                    value={user?.sex === "m" ? "Male" : "Female"}
                    // inputProps={register('sexo', {
                    //   required: 'This field is required!',
                    // })}
                    // error={!!errors.sexo}
                    sx={{ height: "100%" }}
                  >
                    {["Male", "Female"].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6}
                sx={{ display: "flex", alignItems: "flex-end", "& .MuiFormControl-root": { display: "flex" } }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={5} sx={{ maxWidth: "100% !important", flexBasis: "100% !important" }}>
                    {/* <Autocomplete
                      defaultValue="February"
                      options={selectData.birthDate}
                      renderInput={(params) => (
                        <FormField
                          {...params}
                          label="Birth Date"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    /> */}
                    <MDDatePicker
                      input={{
                        placeholder: "Birth Date",
                        variant: "standard", value: user?.birth_date,
                        InputProps: {
                          endAdornment: <InputAdornment position="end">
                            <CalendarTodayRounded/>
                          </InputAdornment>,
                        }
                      }} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Email"
              placeholder="example@email.com"
              value={user?.email}
              inputProps={{ type: "email" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="confirmation email"
              placeholder="example@email.com"
              inputProps={{ type: "email" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* <FormField label="Country" placeholder="El Salvador" /> */}
            <TextField
              select
              fullWidth
              label="Country"
              variant="standard"
              value={user?.country?.toLowerCase()}
              sx={{
                height: "45px", "& .MuiInput-root": { flex: 1 },
                "& .MuiSelect-select": { paddingLeft: "0 !important" },
                "& .MuiSvgIcon-root": { display: "inline-block" }
              }}
            >
              {countriesData.map((country) => (
                <MenuItem key={country.label} value={country.value}>
                  {country.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Phone Number"
              placeholder="+40 735 631 620"
              value={user?.phone_number}
              inputProps={{ type: "number" }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default BasicInfo;
