import React from 'react'
import chairImage from "assets/images/ecommerce/black-chair.jpeg";
import cardImage from "assets/images/bg-player.jpeg";
import projectFront from "assets/images/project-front.jpg";
import projectBack from "assets/images/project-back.jpg";
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { IconButton } from '@mui/material';
import MDBox from 'components/MDBox';
import { Link } from 'react-router-dom';
import "./ProjectGridCard.scss";

function ProjectGridCard({project}) {
    const {id,listing_title:listingTitle,description,area,nft_amount:nftAmount,logo,featured:featuredImg}=project;
    return (
        <MDBox sx={{display:"block",backgroundColor:"white"}} component={Link} to={`/pages/projects/single-project/${id}`} className='project-grid-card'>
            <div className='project-card-img-container'>
                <img src={featuredImg} className="gallery-img" alt="project gallery" />
                {/* <IconButton aria-label="favorite" className="favorite-icon" color="error">
                    <FavoriteIcon color="error" />
                </IconButton> */}
                <img src={logo} className="profile-img" alt="Project Owner" />
            </div>
            <div className='project-card-body'>
                <h5 className='project-title'>{listingTitle}</h5>
                <div className='project-description'>{description.substring(0,200)}... <strong>CONTINUE READING</strong></div>
            </div>
            <div className='project-card-footer'>
                <div className='project-location-info'>
                    <LocationOnIcon /> <span>{area} ha</span>
                </div>
                <div className='project-project-price'>{nftAmount} NFTs</div>
            </div>
        </MDBox>
    )
}

export default ProjectGridCard