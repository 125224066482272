/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React components
import MasterCard from "examples/Cards/MasterCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Billing page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import PaymentMethod from "layouts/pages/account/billing/components/PaymentMethod";
import Invoices from "layouts/pages/account/billing/components/Invoices";
import BillingInformation from "layouts/pages/account/billing/components/BillingInformation";
import Transactions from "layouts/pages/account/billing/components/Transactions";
import DashboardLayout from "biota/LayoutContainers/DashboardLayout";
import DashboardNavbar from "biota/Navbars/DashboardNavbar";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import bankImg from "assets/images/bank.svg";
import { ReactComponent as PdfIcon } from "assets/images/pdf.svg";
import { Avatar, Box, Button, Icon, IconButton, List, ListItem, ListItemAvatar, ListItemText, Menu, MenuItem, Modal, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import "./Billing.scss";
import DefaultLineChart from "biota/Charts/LineCharts/DefaultLineChart";
import MDBadgeDot from "components/MDBadgeDot";
import MDButton from "components/MDButton";
import defaultLineChartData from "layouts/dashboards/sales/data/defaultLineChartData";
import MDDatePicker from "components/MDDatePicker";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function Billing() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          backgroundColor: "white"
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box>
      </Modal>
      <MDBox mt={4} className="billing-page-container">
        <MDBox mb={3}>
          <MDBox className="billing-page-title">
            <h5>Wallet</h5>
            <div className="verify-status-container">
              <div className="verify-status-dot verified" />
              <div className="verify-status-text">Verified Account</div>
            </div>
          </MDBox>
          <Grid container spacing={3}>
            <Grid container item xs={12} lg={4} spacing={3}>
              <Grid item xs={12}>
                <div className="balance-card">
                  <div className="img-container">
                    <AccountBalanceWalletIcon />
                  </div>
                  <div className="balance-card-menu-icon">
                    <IconButton id="basic-button"
                      size="large"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem onClick={handleClose}>Profile</MenuItem>
                      <MenuItem onClick={handleClose}>My account</MenuItem>
                      <MenuItem onClick={handleClose}>Logout</MenuItem>
                    </Menu>
                  </div>
                  <p>Total balance</p>
                  <h4>$240,230</h4>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
              <DefaultInfoCard
                icon={<AccountBalanceWalletIcon />}
                title="Un-Sold NFTs"
                description=""
                value="+$2000"
                color="dark"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DefaultInfoCard
                icon={<AccountBalanceWalletIcon />}
                title="Projected   Value"
                description=""
                value="$455.00"
                color="dark"
              />
            </Grid>
            </Grid>
            <Grid item xs={12} xl={8}>
              <DefaultLineChart
                title="Statistices"
                description={
                  <MDBox display="flex" justifyContent="space-between">
                    <MDBox mt={-4} mr={-1} position="absolute" right="1.5rem">
                      <Button id="basic-button"
                        size="small"
                        variant="outlined"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}>
                        <MDBox color="info">Jan - </MDBox>
                        <MDBox color="info"> Jul</MDBox>
                        <KeyboardArrowDownIcon color="info" />
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        <MenuItem onClick={handleClose}>Profile</MenuItem>
                        <MenuItem onClick={handleClose}>My account</MenuItem>
                        <MenuItem onClick={handleClose}>Logout</MenuItem>
                      </Menu>
                    </MDBox>
                  </MDBox>
                }
                chart={defaultLineChartData}
              />
            </Grid>
            
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <div className="recent-transactions-card">
                <div className="recent-transactions-top">
                  <h5>Recent Transactions</h5>
                  <div className="recent-transactions-dates">
                    <div>
                      <label htmlFor="start-date">Start</label>
                      <MDDatePicker id="start-date" input={{ placeholder: "Select a date" }} />
                    </div>
                    <div>
                      <label htmlFor="start-date">End</label>
                      <MDDatePicker id="start-date" input={{ placeholder: "Select a date" }} />
                    </div>
                  </div>
                </div>
                <div className="recent-transactions-list">
                  <h6 className="recent-transactions-list-order">NEWEST</h6>
                  <List>
                    <ListItem onClick={handleOpenModal}>
                      <ListItemAvatar>
                        <Avatar className="recent-transactions-plus">
                          <AddIcon color="success" />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText className="recent-transactions-list-text" primary="Photos" secondary="Jan 9, 2014" />
                      <MDBox className="recent-transactions-pdf">
                        <PdfIcon />
                        <span>PDF</span>
                      </MDBox>
                      <MDBox className="recent-transactions-amount" color="success">
                        $209.4
                      </MDBox>
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar className="recent-transactions-minus">
                          <RemoveIcon color="error" />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText className="recent-transactions-list-text" primary="Work" secondary="Jan 7, 2014" />
                      <MDBox className="recent-transactions-pdf">
                        <PdfIcon />

                        <span>PDF</span>
                      </MDBox>
                      <MDBox className="recent-transactions-amount" color="error">
                        $209.4
                      </MDBox>
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar className="recent-transactions-plus">
                          <AddIcon color="success" />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText className="recent-transactions-list-text" primary="Vacation" secondary="July 20, 2014" />
                      <MDBox className="recent-transactions-pdf">
                        <PdfIcon />

                        <span>PDF</span>
                      </MDBox>
                      <MDBox className="recent-transactions-amount" color="success">
                        $209.4
                      </MDBox>
                    </ListItem>
                  </List>
                </div>
                <div className="recent-transactions-list">
                  <h6 className="recent-transactions-list-order">Yesterday</h6>
                  <List>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar className="recent-transactions-plus">
                          <AddIcon color="success" />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText className="recent-transactions-list-text" primary="Photos" secondary="Jan 9, 2014" />
                      <MDBox className="recent-transactions-pdf">
                        <PdfIcon />

                        <span>PDF</span>
                      </MDBox>
                      <MDBox className="recent-transactions-amount" color="success">
                        $209.4
                      </MDBox>
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar className="recent-transactions-minus">
                          <RemoveIcon color="error" />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText className="recent-transactions-list-text" primary="Work" secondary="Jan 7, 2014" />
                      <MDBox className="recent-transactions-pdf">
                        <PdfIcon />

                        <span>PDF</span>
                      </MDBox>
                      <MDBox className="recent-transactions-amount" color="error">
                        $209.4
                      </MDBox>
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar className="recent-transactions-plus">
                          <AddIcon color="success" />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText className="recent-transactions-list-text" primary="Vacation" secondary="July 20, 2014" />
                      <MDBox className="recent-transactions-pdf">
                        <PdfIcon />

                        <span>PDF</span>
                      </MDBox>
                      <MDBox className="recent-transactions-amount" color="success">
                        $209.4
                      </MDBox>
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar >
                          <PriorityHighIcon color="dark" />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText className="recent-transactions-list-text" primary="Vacation" secondary="July 20, 2014" />
                      <MDBox className="recent-transactions-pdf">
                        <PdfIcon />

                        <span>PDF</span>
                      </MDBox>
                      <MDBox className="recent-transactions-amount" color="dark">
                        Pending
                      </MDBox>
                    </ListItem>
                  </List>
                </div>
              </div>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Billing;
