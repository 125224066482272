/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import { useState, useEffect } from "react";

import MDInput from "components/MDInput";

import "./EditProfileInfo.scss";
import { Alert, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, Skeleton, Snackbar, Stack, TextField, Typography } from "@mui/material";
import { countries } from "countries-list";
import { useSelector, useDispatch } from "react-redux";
import { countriesData } from "utils/utils";
import MDButton from "components/MDButton";
import { useForm } from "react-hook-form";
import { updateUser } from "app/slices/userSlice";
import { useUpdateUserMutation } from "app/services/userApi";

function ProfileInfoCard({ title, description, info, social, action, shadow, isEdit, setIsEdit,user }) {
  // const labels = [];
  // const values = [];
  const { socialMediaColors } = colors;
  const { size } = typography;
  const [userInfo, setUserInfo] = useState(user);
  const [editUserInfo, setEditUserInfo] = useState(user);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch()
  const [editUser, { isLoading }] = useUpdateUserMutation();


  const { register, control, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = async (data) => {
    const response = await editUser({ ...data, id: editUserInfo?.id });
    console.log("update user response:",response);
    setIsEdit(prev => !prev);
    if (response?.data?.success) {
      setOpen(true);
    }
  }
  console.log("user info card:", user)
  // Render the card social media icons
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const renderSocial = social.map(({ link, icon, color }) => (
    <MDBox
      key={color}
      component="a"
      href={link}
      target="_blank"
      rel="noreferrer"
      fontSize={size.lg}
      color={socialMediaColors[color].main}
      pr={1}
      pl={0.5}
      lineHeight={1}
    >
       <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Updated successfully!
        </Alert>
      </Snackbar>
      {icon}
    </MDBox>
  ));
  useEffect(() => {
    if (user) {
      setUserInfo(user);
      setEditUserInfo(user);
    }
  }, [user]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("Input change");
    setEditUserInfo({
      ...editUserInfo, [name]: value,
      ...(name === "email" && { username: value })
    });

  }


  return (
    <Card sx={{ height: "100%", width: "100%", boxShadow: !shadow && "none" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
        <MDTypography component={Link} to={action.route} variant="body2" color="secondary">
          <Tooltip title={action.tooltip} placement="top" >
            <Icon onClick={() => setIsEdit(prev => !prev)}>edit</Icon>
          </Tooltip>
        </MDTypography>
      </MDBox>
      <MDBox p={2}>{/*start edit from here */}
        <MDBox mb={2} lineHeight={1}>
          <MDTypography variant="button" color="text" fontWeight="light">
            {isEdit ? <MDInput variant="standard" label="Type your description here..." fullWidth multiline rows={5} value={description} /> : description}
          </MDTypography>
        </MDBox>
        <MDBox opacity={0.3}>
          <Divider />
        </MDBox>
        <MDBox>
          {isEdit ? (
            <MDBox component="form"
              className="edit-fields-container" py={1}
              style={{ display: "flex", flexDirection: "column", gap: 20 }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Stack direction="row" spacing="10px" alignItems="baseline">
                <InputLabel className="field-label" htmlFor="first_name">First Name:</InputLabel>
                <MDInput variant="standard" id="first_name" fullWidth
                  name="first_name"

                  error={!!errors?.first_name}
                  {...register("first_name", { required: "This field is required!" })}
                  value={editUserInfo?.first_name}
                  onChange={handleChange}
                />
              </Stack>
              <Stack direction="row" spacing="10px" alignItems="baseline">
                <InputLabel className="field-label" htmlFor="last_name">Last Name:</InputLabel>
                <MDInput variant="standard" id="last_name" fullWidth
                  name="last_name"
                  error={!!errors?.last_name}
                  {...register("last_name", { required: "This field is required!" })}
                  value={editUserInfo?.last_name}
                  onChange={handleChange}
                />
              </Stack>
              <Stack direction="row" spacing="10px" alignItems="baseline">
                <InputLabel className="field-label" htmlFor="mobile">Mobile:</InputLabel>
                <MDInput variant="standard" id="mobile" fullWidth
                  name="phone_number"
                  error={!!errors?.phone_number}
                  {...register("phone_number", { required: "This field is required!" })}
                  value={editUserInfo?.phone_number}
                  onChange={handleChange}
                />
              </Stack>
              <Stack direction="row" spacing="10px" alignItems="baseline">
                <InputLabel className="field-label" htmlFor="email">Email:</InputLabel>
                <MDInput variant="standard" id="email" fullWidth
                  disabled
                  name="email"
                  {...register("email", {
                    
                  })}
                  value={editUserInfo?.email}
                  onChange={handleChange}
                />
              </Stack>
              <Stack direction="row" spacing="10px" alignItems="baseline"
                sx={{ "& .MuiSelect-select": { paddingLeft: "0 !important" } }}>
                <InputLabel className="field-label" id="location-profile" >Location:</InputLabel>
                <TextField
                  select
                  fullWidth
                  label="Location"
                  defaultValue=''
                  variant="standard"

                  name="country"
                  inputProps={register('country', {
                    required: 'This field is required!',
                  })}
                  error={!!errors.country}
                  value={editUserInfo?.country}
                  onChange={handleChange}
                  sx={{ height: "100%" }}
                >
                  {countriesData.map(country => (
                    <MenuItem key={country.label} value={country.value} >{country.label}</MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Stack direction="row" spacing="10px" alignItems="baseline"
                sx={{ "& .MuiSelect-select": { paddingLeft: "0 !important" } }}>
                <InputLabel className="field-label" htmlFor="social-profile">Social:</InputLabel>
                <TextField
                  select
                  fullWidth
                  label="Social"
                  defaultValue=''
                  variant="standard"
                  inputProps={{
                    id: 'social-profile',
                  }}
                  // name="first_name" 
                  // value={editUserInfo?.first_name}
                  // onChange={handleChange}
                  // inputProps={register('sexo', {
                  //   required: 'This field is required!',
                  // })}
                  // error={errors.sexo}
                  sx={{ height: "100%" }}
                >
                  {social.map(({ link, icon, color }) => (
                    <MenuItem key={color} value={color}>{icon} {color}</MenuItem>
                  ))}
                </TextField>

              </Stack>
              <Stack>
                {/* <Button variant="contained">Update User</Button> */}
                <MDButton type="submit" variant="contained" color="info" 
                 disabled={isLoading}  
                >
                  <MDBox color="inherit" component="span" mr={1}>Update User</MDBox>
                  {isLoading && <CircularProgress color="inherit" size={20} />}
                </MDButton>
              </Stack>
            </MDBox>
          ) :
            <>
              {/* {renderItems} */}

              <ProfileListItem label="Full Name" value={`${userInfo?.first_name} ${userInfo?.last_name}`} />
              <ProfileListItem label="Mobile" value={userInfo?.phone_number} />
              <ProfileListItem label="Email" value={userInfo?.email} />
              <ProfileListItem label="Location" value={userInfo?.country} />

              <MDBox display="flex" py={1} pr={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  social: &nbsp;
                </MDTypography>
                {renderSocial}
              </MDBox>
            </>
          }
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfileInfoCard
ProfileInfoCard.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfileInfoCard
ProfileInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  social: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
};

export default ProfileInfoCard;

function ProfileListItem({ label, value }) {
  return (
    <Stack spacing={2} direction="row" display="flex" py={1} pr={2}>
      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
        {label}:
      </MDTypography>
      <MDTypography variant="button" fontWeight="regular" color="text">
        {value}
      </MDTypography>
    </Stack>
  )
}