/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";

// Material Dashboard 2 PRO React examples
import PageLayout from "biota/LayoutContainers/PageLayout";

// Pricing page components
import Header from "layouts/pages/biota-faq/components/Header";
import Footer from "layouts/pages/biota-faq/components/Footer";
import Faq from "layouts/pages/biota-faq/components/Faq";

function BiotaFaq() {
  const [tabValue, setTabValue] = useState(0);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <PageLayout>
      <Header tabValue={tabValue} tabHandler={handleSetTabValue}>
        <Container>
          <Faq category={tabValue} />
        </Container>
      </Header>
      <Footer />
    </PageLayout>
  );
}

export default BiotaFaq;
