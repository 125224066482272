
const activityChartData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sept","Oct","Nov","Dec"],
    datasets: [
      {
        label: "Amount",
        color: "info",
        data: [0,0,0,0,0,0,0,0,0,0,0,0],
      },
    //   {
    //     label: "Google Ads",
    //     color: "dark",
    //     data: [10, 30, 40, 120, 150, 220, 280, 250, 280],
    //   },
    ],
  };
export default activityChartData;
