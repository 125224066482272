/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
// ProductsList page components
import IdCell from "layouts/ecommerce/orders/order-list/components/IdCell";
import DefaultCell from "layouts/ecommerce/orders/order-list/components/DefaultCell";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";
import CustomerCell from "layouts/ecommerce/orders/order-list/components/CustomerCell";

// Images
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import team5 from "assets/images/team-5.jpg";
import ivana from "assets/images/ivana-squares.jpg";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton } from "@mui/material";

const TransactionsDataTableData = {
  columns: [
    { Header: "Item", accessor: "item", Cell: ({ value }) => <IdCell id={value} /> },
    {
      Header: "Price",
      accessor: "price",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Quantity",
      accessor: "quantity",
      Cell: ({ value }) =>  <DefaultCell value={value}/>,
    },
    {
      Header: "From",
      accessor: "from",
      Cell: ({ value: [name, data] }) => (
        <CustomerCell image={data.image} color={data.color || "dark"} name={name} />
      ),
    },
    {
      Header: "To",
      accessor: "to",
      Cell: ({ value }) => {
        const [name, data] = value;

        return (
          <DefaultCell
            value={typeof value === "string" ? value : name}
            suffix={data.suffix || false}
          />
        );
      },
    },
    { Header: "", accessor: "action", Cell: ({ value }) => <IconButton> <VisibilityIcon onClick={()=>alert("CLICk")} fontSize="medium" /></IconButton> },
    { Header: "Time", accessor: "time", Cell: ({ value }) => <DefaultCell value={value} /> },
  ],

  rows: [
    {
      item: "#10421",
      price: "$2390.00",
      quantity: "1",
      from: ["Orlando Imieto", { image: team2 }],
      to: "Nike Sport V2",
      action:"",
      time: "! Nov, 10:53 AM",
    },
    {
      item: "#10422",
      price:"$2390.00",
      quantity: "1",
      from: ["Alice Murinho", { image: team1 }],
      to: "Valvet T-shirt",
      time: "! Nov, 10:53 AM",
    },
    {
      item: "#10423",
      price: "$2390.00",
      quantity: "3",
      from: ["Michael Mirra", { image: "M" }],
      to: ["Leather Wallet", { suffix: "+1 more" }],
      time: "! Nov, 10:53 AM",
    },
    {
      item: "#10424",
      price: "$2390.00",
      quantity: "1",
      from: ["Andrew Nichel", { image: team3 }],
      to: "Bracelet Onu-Lino",
      time: "! Nov, 10:53 AM",
    },
    {
      item: "#10425",
      price: "$2390.00",
      quantity: "2",
      from: ["Sebastian Koga", { image: team4 }],
      to: ["Phone Case Pink", { suffix: "x 2" }],
      time: "! Nov, 10:53 AM",
    },
    {
      item: "#10426",
      price: "$2390.00",
      quantity: "1",
      from: ["Laur Gilbert", { image: "L" }],
      to: "Backpack Niver",
      time: "! Nov, 10:53 AM",
    },
    {
      item: "#10421",
      price: "$2390.00",
      quantity: "1",
      from: ["Orlando Imieto", { image: team2 }],
      to: "Nike Sport V2",
      time: "! Nov, 10:53 AM",
    },
    {
      item: "#10422",
      price:"$2390.00",
      quantity: "1",
      from: ["Alice Murinho", { image: team1 }],
      to: "Valvet T-shirt",
      time: "! Nov, 10:53 AM",
    },
    {
      item: "#10423",
      price: "$2390.00",
      quantity: "3",
      from: ["Michael Mirra", { image: "M" }],
      to: ["Leather Wallet", { suffix: "+1 more" }],
      time: "! Nov, 10:53 AM",
    },
    {
      item: "#10424",
      price: "$2390.00",
      quantity: "1",
      from: ["Andrew Nichel", { image: team3 }],
      to: "Bracelet Onu-Lino",
      time: "! Nov, 10:53 AM",
    },
    {
      item: "#10425",
      price: "$2390.00",
      quantity: "2",
      from: ["Sebastian Koga", { image: team4 }],
      to: ["Phone Case Pink", { suffix: "x 2" }],
      time: "! Nov, 10:53 AM",
    },
    {
      item: "#10426",
      price: "$2390.00",
      quantity: "1",
      from: ["Laur Gilbert", { image: "L" }],
      to: "Backpack Niver",
      time: "! Nov, 10:53 AM",
    },
    {
      item: "#10421",
      price: "$2390.00",
      quantity: "1",
      from: ["Orlando Imieto", { image: team2 }],
      to: "Nike Sport V2",
      time: "! Nov, 10:53 AM",
    },
    {
      item: "#10422",
      price:"$2390.00",
      quantity: "1",
      from: ["Alice Murinho", { image: team1 }],
      to: "Valvet T-shirt",
      time: "! Nov, 10:53 AM",
    },
    {
      item: "#10423",
      price: "$2390.00",
      quantity: "3",
      from: ["Michael Mirra", { image: "M" }],
      to: ["Leather Wallet", { suffix: "+1 more" }],
      time: "! Nov, 10:53 AM",
    },
    {
      item: "#10424",
      price: "$2390.00",
      quantity: "1",
      from: ["Andrew Nichel", { image: team3 }],
      to: "Bracelet Onu-Lino",
      time: "! Nov, 10:53 AM",
    },
    {
      item: "#10425",
      price: "$2390.00",
      quantity: "2",
      from: ["Sebastian Koga", { image: team4 }],
      to: ["Phone Case Pink", { suffix: "x 2" }],
      time: "! Nov, 10:53 AM",
    },
    {
      item: "#10426",
      price: "$2390.00",
      quantity: "1",
      from: ["Laur Gilbert", { image: "L" }],
      to: "Backpack Niver",
      time: "! Nov, 10:53 AM",
    },
    {
      item: "#10421",
      price: "$2390.00",
      quantity: "1",
      from: ["Orlando Imieto", { image: team2 }],
      to: "Nike Sport V2",
      time: "! Nov, 10:53 AM",
    },
    {
      item: "#10422",
      price:"$2390.00",
      quantity: "1",
      from: ["Alice Murinho", { image: team1 }],
      to: "Valvet T-shirt",
      time: "! Nov, 10:53 AM",
    },
    {
      item: "#10423",
      price: "$2390.00",
      quantity: "3",
      from: ["Michael Mirra", { image: "M" }],
      to: ["Leather Wallet", { suffix: "+1 more" }],
      time: "! Nov, 10:53 AM",
    },
    {
      item: "#10424",
      price: "$2390.00",
      quantity: "1",
      from: ["Andrew Nichel", { image: team3 }],
      to: "Bracelet Onu-Lino",
      time: "! Nov, 10:53 AM",
    },
    {
      item: "#10425",
      price: "$2390.00",
      quantity: "2",
      from: ["Sebastian Koga", { image: team4 }],
      to: ["Phone Case Pink", { suffix: "x 2" }],
      time: "! Nov, 10:53 AM",
    },
    {
      item: "#10426",
      price: "$2390.00",
      quantity: "1",
      from: ["Laur Gilbert", { image: "L" }],
      to: "Backpack Niver",
      time: "! Nov, 10:53 AM",
    },
    // {
    //   item: "#10427",
    //   price: "1 Nov, 3:42 AM",
    //   quantity: "paid",
    //   from: ["Iryna Innda", { image: "I" }],
    //   to: "Adidas Vio",
    //   time: "$200,00",
    // },
    // {
    //   item: "#10428",
    //   price: "2 Nov, 9:32 AM",
    //   quantity: "paid",
    //   from: ["Arrias Liunda", { image: "A" }],
    //   to: "Airpods 2 Gen",
    //   time: "$350,00",
    // },
    // {
    //   item: "#10429",
    //   price: "2 Nov, 10:14 AM",
    //   quantity: "paid",
    //   from: ["Rugna Ilpio", { image: team5 }],
    //   to: "Bracelet Warret",
    //   time: "$15,00",
    // },
    // {
    //   item: "#10430",
    //   price: "2 Nov, 10:14 AM",
    //   quantity: "refunded",
    //   from: ["Anna Landa", { image: ivana }],
    //   to: ["Watter Bottle India", { suffix: "x 3" }],
    //   time: "$25,00",
    // },
    // {
    //   item: "#10431",
    //   price: "$2390.00",
    //   quantity: "paid",
    //   from: ["Karl Innas", { image: "K" }],
    //   to: "Kitchen Gadgets",
    //   time: "$164,90",
    // },
    // {
    //   item: "#10432",
    //   price: "2 Nov, 5:12 PM",
    //   quantity: "paid",
    //   from: ["Oana Kilas", { image: "O", color: "info" }],
    //   to: "Office Papers",
    //   time: "$23,90",
    // },
  ],
};

export default TransactionsDataTableData;
