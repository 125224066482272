import { Avatar, Card, Checkbox, FormControlLabel, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Stack, Tab, Tabs, Typography } from '@mui/material';
import MDBox from 'components/MDBox';
import React from 'react'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Modal } from 'react-responsive-modal';
import team2 from "assets/images/team-2.jpg"
import 'react-responsive-modal/styles.css';
import { ReactComponent as VerifiedImg } from "assets/images/verified.svg";
import backgroundImage from "assets/images/bg-profile.jpeg";
import nftImage from "assets/images/nft.jpg";
// import {ReactComponent as DebitCardIcon} from "assets/images/debit-card.svg";
// import {ReactComponent as CryptoIcon} from "assets/images/crypto.svg";
// import {ReactComponent as BankIcon} from "assets/images/bank.svg";
import debitCardImg from "assets/images/debit-card.svg";
import cryptoImg from "assets/images/crypto.svg";
import bankImg from "assets/images/bank.svg";
import masterCardImg from "assets/images/logos/mastercard.png";
import visaImg from "assets/images/logos/visa.png";

import ethImg from "assets/images/eth.svg";
import bitcoinImg from "assets/images/bitcoin.svg";
import usdtImg from "assets/images/usdt.svg";
import PersonIcon from '@mui/icons-material/Person';



import "./BuyNFTModal.scss";

function BuyNFTModal({ open, handleClose, setOpenCreditModal }) {
    return (
        <div className='buy-nft-modal-container'>
            <Modal classNames="" showCloseIcon modalId="buy-nft-modal" open={open} onClose={handleClose} center>
                <MDBox className="modal-content" p={4}>
                    <Stack spacing={2}>
                        <Card sx={{ padding: "10px", position: "relative",cursor:"pointer" }} onClick={() => setOpenCreditModal(prev => !prev)}>
                            <Stack direction="row" spacing={1} sx={{ position: "absolute", right: "5px", top: "5px" }}>
                                <MDBox as="img" width="115px" height="50px" 
                                sx={{ border: "1px solid #9D9D9D", borderRadius: "5px",objectFit:"contain" }} src={masterCardImg} alt="master card" />
                                <MDBox as="img" width="115px" height="50px" 
                                sx={{ border: "1px solid #9D9D9D", borderRadius: "5px",objectFit:"contain" }} src={visaImg} alt="visa card" />
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={1} mb={1}>
                                <MDBox as="img" width="50px" height="50px" src={debitCardImg} alt="debit/credit card" />
                                <Typography variant='body1' component="span" fontSize={20} fontWeight="bold">Debit or credit</Typography>
                            </Stack>
                            <Typography variant='body1' component="span" px={1} fontSize={14}  >
                                To complete the payment process add a debit or credit card, this payment method is currently set as the default payment method for this billing profile.
                            </Typography>
                        </Card>
                        {/*<Card sx={{ padding: "10px", position: "relative",cursor:"pointer" }} onClick={() => setOpenCreditModal(prev => !prev)}>
                            <Stack direction="row" spacing={1} sx={{ position: "absolute", right: "5px", top: "5px" }}>
                                <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} width="115px" height="50px" sx={{ border: "1px solid #9D9D9D", borderRadius: "5px" }}>
                                    <Typography variant='subtitle2' component="span" fontSize={16} fontWeight="bold">ETH</Typography>
                                    <MDBox as="img" src={ethImg} alt="ETH" />
                                </Stack>
                                <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} width="115px" height="50px" sx={{ border: "1px solid #9D9D9D", borderRadius: "5px" }}>
                                    <Typography variant='subtitle2' component="span" fontSize={16} fontWeight="bold">BTC</Typography>
                                    <MDBox as="img" src={bitcoinImg} alt="Bitcoin" />
                                </Stack>
                                <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} width="115px" height="50px" sx={{ border: "1px solid #9D9D9D", borderRadius: "5px" }}>
                                    <Typography variant='subtitle2' component="span" fontSize={16} fontWeight="bold">BTC</Typography>
                                    <MDBox as="img" src={usdtImg} alt="USDT" />
                                </Stack>
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={1} mb={1}>
                                <MDBox as="img" width="50px" height="50px" src={cryptoImg} alt="crypto card" />
                                <Typography variant='body1' component="span" fontSize={20} fontWeight="bold">Crypto</Typography>
                            </Stack>
                            <Typography variant='body1' component="span" px={1} fontSize={14}  >
                                Loren Ipsum Loren Ipsum Loren Ipsum Loren Ipsum Loren Ipsum Loren Ipsum Loren Ipsum Loren Ipsum Loren Ipsum
                            </Typography>
                        </Card>*/}
                         <Card sx={{ padding: "10px", position: "relative",cursor:"pointer" }} onClick={() => setOpenCreditModal(prev => !prev)}>
                            <Stack direction="row" spacing={1} sx={{ position: "absolute", right: "5px", top: "5px" }}>
                                <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} width="115px" height="50px" sx={{ border: "1px solid #9D9D9D", borderRadius: "5px" }}>
                                    <Typography variant='subtitle2' component="span" fontSize={16} fontWeight="bold">ACH</Typography>
                                </Stack>
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={1} mb={1}>
                                <MDBox as="img" width="50px" height="50px" src={bankImg} alt="bank card" />
                                <Typography variant='body1' component="span" fontSize={20} fontWeight="bold">Bank Transfer</Typography>
                            </Stack>
                            <Typography variant='body1' component="span" px={1} fontSize={14}  >
                                Loren Ipsum Loren Ipsum Loren Ipsum Loren Ipsum Loren Ipsum Loren Ipsum Loren Ipsum Loren Ipsum Loren Ipsum
                            </Typography>
                        </Card>
                        <Card sx={{ padding: "10px", position: "relative" ,cursor:"pointer"}} onClick={() => setOpenCreditModal(prev => !prev)}>
                            <Stack direction="row" spacing={1} sx={{ position: "absolute", right: "5px", top: "5px" }}>
                                <Stack direction="row"
                                 alignItems="center"
                                  justifyContent="center"
                                   spacing={1} width="115px" height="50px" 
                                   sx={{ border: "1px solid #9D9D9D", borderRadius: "5px" }}>
                                    <Typography variant='subtitle2' component="span" fontSize={16} fontWeight="bold">ANY</Typography>
                                </Stack>
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={1} mb={1}>
                                <PersonIcon fontSize="large" sx={{ width: 50, height: 50 }} />
                                <Typography variant='body1' component="span" fontSize={20} fontWeight="bold">Purchase request with sales agent</Typography>
                            </Stack>
                            <Typography variant='body1' component="span" px={1} fontSize={14}  >
                                Loren Ipsum Loren Ipsum Loren Ipsum Loren Ipsum Loren Ipsum Loren Ipsum Loren Ipsum Loren Ipsum Loren Ipsum
                            </Typography>
                        </Card> 
                    </Stack>
                </MDBox>
            </Modal>
        </div>
    );
}

export default BuyNFTModal