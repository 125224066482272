import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Select from "react-select";
import { useState } from "react";
import InvestorImage from "assets/images/biota/investor.png";
import LandOwnerImage from "assets/images/biota/land_owner.png";

function Step0({ onChangeStep, userType, setUserType }) {
  const handleContinue = () => {
    onChangeStep((prev) => prev + 1);
  };
  // const [opcion, setOpcion] = useState(1);
  const handleUserType = (option, userType2) => {
    // setOpcion(option);
    setUserType(userType2);
  }
  return (
    <Card>
      <MDBox
        variant="gradient"
        bgColor="success"
        borderRadius="lg"
        coloredShadow="success"
        mx={2}
        mt={-3}
        p={3}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          Register
        </MDTypography>
      </MDBox>
      <MDBox pt={4} pb={3} px={3}>
        <MDBox component="form" role="form">
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <MDBox
                variant="gradient"
                bgColor="success"
                borderRadius="sm"
                coloredShadow="success"
                mt={-1}
                p={0}
                mb={1}
                textAlign="center"
              >
                {/* {opcion === 1 ? (
                  <MDButton
                    variant="gradient"
                    color="success"
                    onClick={() => handleUserType(0,1)}
                    fullWidth
                  >
                    <img alt="" src={LandOwnerImage} />
                    Land Owner
                  </MDButton>
                ) : (
                  <MDButton variant="gradient" color="info" onClick={() =>handleUserType(0,1)} fullWidth>
                    <img alt="" src={LandOwnerImage} />
                    Land Owner
                  </MDButton>
                )} */}
                <MDButton variant="gradient" color={userType === 1 ? "info" : "success"} onClick={() => handleUserType(0, 1)} fullWidth>
                  <img alt="" src={LandOwnerImage} />
                  Land Owner
                </MDButton>
              </MDBox>
            </Grid>
            <Grid item lg={6} md={6}>
              <MDBox
                variant="gradient"
                bgColor="success"
                borderRadius="sm"
                coloredShadow="success"
                mt={-1}
                p={0}
                mb={1}
                textAlign="center"
              >
                {/* {opcion === 0 ? (
                  <MDButton
                    variant="gradient"
                    color="success"
                    onClick={() => handleUserType(1,2)}
                    fullWidth
                  >
                    <img alt="" src={InvestorImage} />
                    Investor
                  </MDButton>
                ) : (
                  <MDButton variant="gradient" color="info" onClick={() => handleUserType(1,2)} fullWidth>
                    <img alt="" src={InvestorImage} />
                    Investor
                  </MDButton>
                )} */}
                <MDButton variant="gradient" color={userType === 2 ? "info" : "success"} onClick={() => handleUserType(1, 2)} fullWidth>
                  <img alt="" src={InvestorImage} />
                  Investor
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>

          <MDBox mt={4} mb={1}>
            <MDButton variant="gradient" color="success" onClick={() => handleContinue()} fullWidth>
              Continue
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Step0;
