/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
// ProductsList page components
import IdCell from "layouts/ecommerce/orders/order-list/components/IdCell";
import DefaultCell from "layouts/ecommerce/orders/order-list/components/DefaultCell";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";
import CustomerCell from "layouts/ecommerce/orders/order-list/components/CustomerCell";

// Images
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import team5 from "assets/images/team-5.jpg";
import ivana from "assets/images/ivana-squares.jpg";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton } from "@mui/material";
import { getTransactionTypeById } from "utils/utils";

const TransactionsDataTableData = {
  columns: [
    // { Header: "Id", accessor: "id", Cell: ({ value:[name,data] }) => <CustomerCell image={data.image} color={data.color || "dark"} name={name} />},
    {
      Header: "",
      accessor: "id",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "User",
      accessor: "username",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
/*     {
      Header: "Listing Id",
      accessor: "listing_id",
      Cell: ({ value }) => <DefaultCell value={value} />,
    }, */
    {
      Header: "Price",
      accessor: "amount",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Type",
      accessor: "transaction_type",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Time Stamp",
      accessor: "date_created",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ],

  rows: [
    {
      id: "id",
      status: "status 1",
      user_id: "uid",
      listing_id: "lid",
      amount: "am",
      transaction_type: "sell",
      date_created: "date",
    },
  ],
};

export default TransactionsDataTableData;
