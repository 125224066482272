import React from 'react'
import MDBox from 'components/MDBox';
import backgroundImage from "assets/images/bg-profile.jpeg";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Card, CardActions, CardContent, CardMedia, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import nftImage from "assets/images/nft.jpg";
import { Link } from 'react-router-dom';
import "./SingleNFTCard.scss"


function SingleNFTCard({nftData}) {
    const {key,assets,name,value,number_of:numberOf,key:nftid, project_id:projectid, value:price,owner:ownerid} = nftData || {};
    

    return (
        <Card sx={{ maxWidth: 345 }} component={Link} to={`/pages/projects/single-project-detail/${key}`}>
            <CardMedia
                component="img"
                height="194"
                image={assets || nftImage}
                alt="Project Item"
            />
            <CardContent>
                <Stack direction="row" justifyContent="space-between" spacing={3}>
                    <MDBox>
                        <Typography fontSize="11px" variant='body2' color="text.primary">
                           {name}
                        </Typography>
                        <Typography fontSize="11px" fontWeight="bold" variant='body2' color="text.primary">
                            Pass #{numberOf}
                        </Typography>
                    </MDBox>
                    <MDBox>
                        <Typography fontSize="11px" variant='body2' color="text.primary">
                            Price
                        </Typography>
                        <Typography fontSize="11px" fontWeight="bold" variant='body2' color="text.primary">
                            {value}
                        </Typography>
                    </MDBox>
                </Stack>
                <Stack direction="row" justifyContent="flex-end">
                    <MDBox>
                        <Typography fontSize="11px" variant='body2' color="text.primary">
                            {/* <AccessTimeIcon /> <span>Text</span> */}
                        </Typography>
                    </MDBox>
                </Stack>
            </CardContent>
            <CardActions sx={{ borderTop: "1px solid #ededed", paddingBlock: 0 }} disableSpacing as={Stack} direction="row" justifyContent="flex-end">
                <Stack direction="row" justifyContent="flex-end" alignItems="center">
                    <IconButton aria-label="add to favorites">
                        <FavoriteBorderIcon />
                    </IconButton>
                    <Typography fontSize="16px" variant='body2' color="text.primary">
                        1
                    </Typography>
                </Stack>
            </CardActions>
        </Card>
    )
}

export default SingleNFTCard