/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { useState, useEffect, createContext } from "react";
import { useMaterialUIController, setGridView } from "context";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "biota/LayoutContainers/DashboardLayout";
import DashboardNavbar from "biota/Navbars/DashboardNavbar";
import Footer from "biota/Footer";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import DefaultProjectCard from "biota/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/pages/projects/components/Header";

// Data
import profilesListData from "layouts/pages/profile/profile-overview/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

function ProjectList() {
  const [projects, setProjects] = useState([{listing: "Listing #1", proyecto: "Projecto 1", descripcion: "Descripcion del proyecto 1", location: "Location 1", favorito: true}, {listing: "Listing #2", proyecto: "Projecto 2", descripcion: "Descripcion del proyecto 2", location: "Location 2", favorito: false}]);
  
  const [controller, dispatch] = useMaterialUIController();
  const { gridView } = controller;

  const allProjects = projects.map((item) => (
    (gridView ?
    <Grid item xs={12} md={6} xl={4} key={item.listing}>
              <DefaultProjectCard
                image={homeDecor1}
                label={item.listing}
                title={item.proyecto}
                description={item.descripcion}
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view listing",
                }}
                authors={[
                  { image: team1, name: "Elena Morison" },
                  { image: team2, name: "Ryan Milly" },
                  { image: team3, name: "Nick Daniel" },
                  { image: team4, name: "Peterson" },
                ]}
              />
      </Grid> : <Grid item xl={12}>
        <MDBox bgColor="light" borderRadius="lg" p="24px">
          <Grid container>
            <Grid item xs={12} md={4}>
              {item.proyecto}
            </Grid>
            <Grid item xs={12} md={4}>
              <MDTypography variant="button">
                {item.descripcion}
              </MDTypography>
            </Grid>
            {/* <Grid item xs={12} md={1}>
              {(item.favorito ? <FavoriteIcon color="error" /> : <FavoriteBorderIcon />)}
            </Grid> */}
            <Grid item xs={12} md={3}>
              <MDTypography variant="button">
              {item.location}
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
      </Grid>)
  ));
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
        <Header>
          <MDBox mt={5} mb={3}>
            <Grid container spacing={2}>
              {allProjects}
            </Grid>
          </MDBox>
        </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default ProjectList;
