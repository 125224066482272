/* eslint-disable */

// import TimelineItem from "biota/Timeline/TimelineItem";
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

// Data
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { CircularProgress, Stack, Tooltip } from '@mui/material';

import { NotificationImportant } from '@mui/icons-material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import { useMarkSingleNotificationReadMutation } from 'app/services/notificationApi';
import { format } from 'date-fns'

function CustomTimelineItem({ id, name, body, created_at: createdAt, read_status: readStatus,setNotifications}) {
  const [markSingleNotificationRead, { isLoading:markSingleLoading }] = useMarkSingleNotificationReadMutation();
  
  const markAsRead =async (id)=>{
     const response = await markSingleNotificationRead(id);
     if(response?.data ==="Notifications marked as read"){
      setNotifications(prev=>prev.filter(item=>item.id!=id));
     }
     console.log("markSingleNotificationRead response:",response);
  }
    return (
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="primary">
            <NotificationImportant color="white" />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Stack direction="row" justifyContent="space-between" mb={1.2}>
            <Stack mb="10px">
              <Typography variant="subtitle2" fontWeight="bold">{name}</Typography>
              <Typography variant="caption">{format(new Date(createdAt),'dd MMM  hh:mm aaa')?.toUpperCase()}</Typography>
            </Stack>
            <IconButton aria-label="mark as read notification" disabled={markSingleLoading || readStatus === "read"}>
              {markSingleLoading ? (
                <CircularProgress color="info" size="20px"/>
              ):(
                <Tooltip title={readStatus === "unread" && "Mark as read"} placement="top" disableInteractive>
                  <VisibilityIcon color={readStatus === "unread" ? "success" : "grey"} onClick={()=>markAsRead(id)}/>
                </Tooltip>
              )}
            </IconButton>
          </Stack>
          <Typography variant="body2" fontWeight="400">{body}</Typography>
        </TimelineContent>
      </TimelineItem>
    )
  }
  
  export default CustomTimelineItem;