/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import MDInput from "components/MDInput";

function LaunchedProjectsList({ title, projects, shadow, isEdit }) {
  const renderProjects = projects.map(({ name, location, description, action }) => (
    <MDBox key={name} component="li" display="flex" alignItems="center" py={1} mb={1} bgColor="light" borderRadius="lg" p="16px">
      {isEdit ? <>
        <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
          <MDBox display="flex" flexDirection="row" alignItems="center" justifyContent="center" mb="8px">
            <MDTypography style={{ fontSize: 14, marginRight: 8, width: 80 }} variant="button" fontWeight="bold" mr="4px">
              Project:
            </MDTypography>
            <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
              {/* <InputLabel id="demo-simple-select-standard-label">Location</InputLabel> */}
              <Select
                labelId="demo-simple-select-standard-label"
                id="project"
                value="project 1"
                onChange={() => { }}
              // label="Location"
              >
                <MenuItem value="project 1">Project 1</MenuItem>
                <MenuItem value="project 2">Project 2</MenuItem>
                <MenuItem value="project 3">Project 3</MenuItem>

                {/* {Object.keys(countries).map(country => {
                      return <MenuItem key={country} value={country}>{country}</MenuItem>
                    })} */}
              </Select>
            </FormControl>
          </MDBox>
          <MDBox display="flex" flexDirection="row" alignItems="center" justifyContent="center" mb="8px">
            <MDTypography style={{ fontSize: 14, marginRight: 8, width: 80 }} as="label" variant="body1"
              htmlFor="description" fontWeight="bold" mr="4px">
              Description:
            </MDTypography>
            <MDInput variant="standard" id="description" value={description} />
          </MDBox>
        </MDBox>
        <MDBox ml="auto">
          <MDButton variant="text" color="error">
             <Icon>delete</Icon> Delete
          </MDButton>
          <MDButton
            variant="text"
            color="dark"
          >
           <Icon>edit</Icon> Edit
          </MDButton>

        </MDBox>
      </> : (
        <>
          <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
            <MDBox display="flex" flexDirection="row" alignItems="flex-start" justifyContent="center" mb="8px">
              <MDTypography variant="button" fontWeight="bold" mr="4px">
                Name:
              </MDTypography>
              <MDTypography variant="button" color="text">
                {name}
              </MDTypography>
            </MDBox>
            <MDBox display="flex" flexDirection="row" alignItems="flex-start" justifyContent="center" mb="8px">
              <MDTypography variant="button" fontWeight="bold" mr="4px">
                Location:
              </MDTypography>
              <MDTypography variant="button" color="text">
                {location}
              </MDTypography>
            </MDBox>
            <MDBox display="flex" flexDirection="row" alignItems="flex-start" justifyContent="center" mb="8px">
              <MDTypography variant="button" fontWeight="bold" mr="4px">
                Description:
              </MDTypography>
              <MDTypography variant="button" color="text">
                {description}
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox ml="auto">
            {action.type === "internal" ? (
              <MDButton variant="contained" color="light">
                View &nbsp;<Icon>visibility</Icon>
              </MDButton>
            ) : (
              <MDButton
                variant="contained"
                color={action.color}
              >
                {action.label}
              </MDButton>
            )}
          </MDBox>
        </>
      )}
    </MDBox>
  ));

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderProjects}
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the LaunchedProjectsList
LaunchedProjectsList.defaultProps = {
  shadow: true,
};

// Typechecking props for the LaunchedProjectsList
LaunchedProjectsList.propTypes = {
  title: PropTypes.string.isRequired,
  projects: PropTypes.arrayOf(PropTypes.object).isRequired,
  shadow: PropTypes.bool,
};

export default LaunchedProjectsList;
