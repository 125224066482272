/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
import selectData from "layouts/pages/account/settings/components/BasicSettings/data/selectData";
import { FormControl, InputLabel, MenuItem, NativeSelect, Stack, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { countriesData, languagesData, currenciesData } from "utils/utils";
import MDInput from "components/MDInput";

function BasicSettings({ userSettings, setUserSettings }) {
  const { register, control, handleSubmit, formState: { errors } } = useForm();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserSettings({
      ...userSettings, [name]: value,
    });

  }
  console.log("SSSSSSS", userSettings?.language)
  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Basic Settings</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Stack mb={3}  direction="row" spacing="10px" alignItems="baseline">
          <InputLabel className="field-label"
            htmlFor="basic-settings-language"
            sx={{ minWidth: 100, fontSize: 16, lineHeight: "inherit" }}>Language:</InputLabel>
          <FormControl fullWidth>
            <NativeSelect
              value={userSettings?.language}
              onChange={handleChange}
              id="basic-settings-language"
              inputProps={{
                name: 'language',
              }}
            >
              {languagesData.map(language => (
                <option key={language.value} value={language.value} >{language.label}</option>
              ))}
            </NativeSelect>
          </FormControl>
        </Stack>
        <Stack mb={3} direction="row" spacing="10px" alignItems="baseline">
          <InputLabel className="field-label"
            htmlFor="basic-settings-currency"
            sx={{ minWidth: 100, fontSize: 16, lineHeight: "inherit" }}>Currency:</InputLabel>
          <FormControl fullWidth sx={{"& select":{textTransform:"uppercase"}}}>
            <NativeSelect
              value={userSettings?.currency}
              onChange={handleChange}
              id="basic-settings-currency"
              inputProps={{
                name: 'currency',
              }}
            >
              {currenciesData.map(currency => (
                <option key={currency} value={currency} >{currency}</option>
              ))}
            </NativeSelect>
          </FormControl>
        </Stack>
        <Stack direction="row" spacing="10px" alignItems="baseline">
          <InputLabel className="field-label"
            htmlFor="basic-settings-location"
            sx={{ minWidth: 100, fontSize: 16, lineHeight: "inherit" }}>Location:</InputLabel>
          <FormControl fullWidth>
            <NativeSelect
              value={userSettings?.locaion}
              onChange={handleChange}
              id="basic-settings-location"
              inputProps={{
                name: 'locaion',
              }}
            >
              {countriesData.map(country => (
                <option key={country.label} value={country.value} >{country.label}</option>
              ))}
            </NativeSelect>
          </FormControl>
        </Stack>
       
      </MDBox>
    </Card>
  );
}

export default BasicSettings;
