/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { register } from "app/slices/authSlice";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

import { useEffect, useState } from "react";
import axios from "axios";

// Images
import bgImage from "assets/images/biota/sign-up.png";
import { Alert, Snackbar } from "@mui/material";

import { jsx } from "@emotion/react";
import * as yup from "yup";
import Step0 from "./step0";
import Step1 from "./step1";
import Step2 from "./step2";

function Cover() {
  const params = new URLSearchParams();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [userType, setUserType] = useState(2); //land owner : 1 / investor: 2
  const [idUser, setIdUser] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);


  const [datos, setDatos] = useState({
    password: "",
    email: "",
    first_name: "",
    last_name: "",
    country: "",
    phone_number: "",
    fecha_nac: "",
    area: "",
    sexo: "",
    type:2,
    status:1,
    username:""
  });
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const schema = yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    phone_number: yup.number().required().positive().integer(),
    email: yup.string().email(),
    fecha_nac: yup
      .date()
      .default(function () {
        return new Date();
      })
      .max(today, "Date must be before today"),
  });

  const onUpdate = (objeto) => {
    setDatos((prev) => {
      return {
        ...prev,
        ...objeto,
      };
    });
  };

  const signUp = async () => {
    params.append("password", datos.password);
    params.append("email", datos.email);
    params.append("first_name", datos.first_name);
    params.append("last_name", datos.last_name);
    params.append("phone_number", datos.area + datos.phone_number);
    params.append("country", "GT");
    params.append("password", "clave");
    params.append("sex", datos.sexo);
    params.append("type", userType);
    datos.username = datos.email;
   const response = await dispatch(register(datos));
   console.log("SIGNUP RESPONSE:",response);
   if (response?.payload?.success) {
    setOpen(true);
    navigate("/authentication/sign-in")
  }
  };

  const onSubmit = () => {
    schema.isValid(datos).then(function (valid) {
      //verificacion del formulario
      if (valid) {
        signUp(); //llamar al endpoint
      } else {
        schema.validate(datos, { abortEarly: false }).catch((e) => {
          let cadena = "";
          e.errors.forEach((el) => {
            cadena += "\n-" + el;
          });
          setErrorMessage(cadena);
        });
      }
    });
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  useEffect(() => {
    if (step !== 0) onSubmit(); //se ejecuta del paso 1 en delante
  }, [datos]);
  return (
    <CoverLayout image={bgImage}>
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Signup successfully!
        </Alert>
      </Snackbar>
      <div>
        {step === 0 && <Step0 onChangeStep={setStep} userType={userType} setUserType={setUserType}/>}
        {step === 1 && <Step1 update={onUpdate} userType={userType} errorMessage={errorMessage} />}
        {step === 2 && <Step2 onChangeStep={setStep} update={onUpdate} idUser={idUser} />}
      </div>
    </CoverLayout>
  );
}

export default Cover;
