import { Button, Card } from '@mui/material'
import DashboardLayout from 'biota/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'biota/Navbars/DashboardNavbar'
import MDBox from 'components/MDBox'
import DataTable from "examples/Tables/DataTable";
// Data
import React, { useState } from 'react'
import TransactionsModal from './components/TransactionsModal/TransactionsModal';
import TransactionsDataTableData from './data/dataTableData';

import "./TransactionsList.scss"

function TransactionsList() {
    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
  
    const handleViewClick = ()=>{
        alert(123)
    }
    const handleModal = ()=>{
        setOpen(prev=>!prev);
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Button type="button" onClick={handleModal}>Open Modal</Button>
            {open && <TransactionsModal open={open} setOpen={setOpen}/>}
            <MDBox mt={4} className="transactions-list-container">
                <MDBox className="transactions-list-title" mb={4}>
                    <h4>Transactions List</h4>
                    {/* <p>*Requiered Fields*</p> */}
                </MDBox>
                <Card>
                   <MDBox className="transactions-card-title">
                   <h5>Transactions</h5>
                    <p>View all the orders from the previous year for PROJECT NAME HERE</p>
                   </MDBox>
                    <DataTable onViewClick={handleViewClick} table={TransactionsDataTableData} entriesPerPage canSearch />
                </Card>
            </MDBox>
        </DashboardLayout>
    )
}

export default TransactionsList