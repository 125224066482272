/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert"


// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
import bgImage from "assets/images/biota/login-2.jpeg";

import { useAuth } from "use-auth";
import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import MDBadge from "components/MDBadge";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux'
import { CircularProgress, Typography } from "@mui/material";
import { login, reset } from '../../../../app/slices/authSlice';

function Illustration() {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const auth = useAuth();

  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = async (data) => {
     dispatch(login(data))
  }

  const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isError) {
      console.log("something went wrong while login!")
    }

    if (isSuccess || user) {
      navigate('/dashboards/biota')
      dispatch(reset())
    }

  }, [user, isError, isSuccess, message, navigate, dispatch])

  return (
    <IllustrationLayout
      title="Sign In"
      description="Enter your email and password to sign in"
      illustration={bgImage}
    >
      <Typography fontSize="12px" color="error" mb={2}>{message}</Typography>
      <MDBox component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
        <MDBox mb={2}>
          <MDInput type="email" name="username" label="Email"
            fullWidth
            error={!!errors?.username}
            {...register("username", {
              required: "This field is required!",
              pattern: {
                value:/^(.+)@(.+)$/i,
                message: "Invalid email address"
              },
            })}
            // helperText={errors?.username? errors.username.message:null}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput type="password" name="password" label="Password"
            fullWidth
            error={!!errors?.password}
            {...register("password", { required: "This field is required!" })}
          />
        </MDBox>

        <MDBox display="flex" alignItems="center" ml={-1}>
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
          >

            &nbsp;&nbsp;Remember me
          </MDTypography>
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton variant="gradient" type="submit"  color="success" size="large" disabled={isLoading} fullWidth>
            <MDBox component="span" color="inherit" mr={1}>sign in</MDBox> {isLoading && <CircularProgress color="inherit" size={20} />}
          </MDButton>
        </MDBox>
        <MDBox mt={3} textAlign="center">
          <MDTypography variant="button" color="text">
            Don&apos;t have an account?{" "}
            <MDTypography
              component={Link}
              to="/authentication/sign-up/cover"
              variant="button"
              color="success"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </MDTypography>
          </MDTypography>
        </MDBox>
      </MDBox>
    </IllustrationLayout>
  );
}

export default Illustration;
