import { Subtitles } from '@mui/icons-material';
import React from 'react'

import "./StepperTitle.scss";

function StepperTitle({ title, subTitle }) {
    return (
        <div className="stepper-title-container">
            <h2>{title}</h2>
            {Subtitles && <p>{subTitle}</p>}
        </div>
    )
}

export default StepperTitle