import ScaleLoader from "react-spinners/ScaleLoader";

const divStyle = {
   width: "100vw",
   height: "100vh",
   backgroundColor: "#ffffff",
   position: "relative"
}

const divStyleInner = {
    position: "absolute",
    top:"50%",
    left:"50%"
}

function Loading() {
    return (
        <div style={divStyle}>
            <div style={divStyleInner}>
            <ScaleLoader color="#024959" css="margin:auto" />
            </div>
        </div>
    );
}

export default Loading;
