import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


import "./SingleProjectOfferTable.scss";

function SingleProjectOfferTable({rows}) {
  return (
    <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableRow sx={{ backgroundColor: "white", color: "black" }}>
            <TableCell sx={{ fontWeight: "bold" }}>Price</TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="right">USD Price</TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="right">Floor Difference</TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="right">Expiration</TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="right">From</TableCell>
        </TableRow>
        <TableBody>
            {rows.map((row) => (
                <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row">
                        {row.price}
                    </TableCell>
                    <TableCell align="right">{row.usdPrice}</TableCell>
                    <TableCell align="right">{row.floorDiff}</TableCell>
                    <TableCell align="right">{row.expiration}</TableCell>
                    <TableCell align="right">{row.from}</TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>
</TableContainer>
  )
}

export default SingleProjectOfferTable