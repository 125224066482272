/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import { Link } from 'react-router-dom';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Material Dashboard 2 PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import burceMars from "assets/images/biota/pix-1.png";
import backgroundImage from "assets/images/biota/bg-1.jpeg";
import verifiedIcon from "assets/images/verified-blue.svg";
import axios from "axios";
import CurrentDateTime from "components/CurrentDateTime/CurrentDateTime";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { useAuth } from "../../../../../use-auth";

function Header({ children, isEdit, setIsEdit, user, isLoading }) {
  const [userData, setUserData] = useState({ nombre: "David Eliseo", tipo: "Land Owner", verified: true });
  const [userInfo, setUserInfo] = useState(null);

  const auth = useAuth();

  // useEffect(() => {
  //   axios({
  //     url: "https://biota-backend.herokuapp.com/api/view/user/" + auth.user.id,
  //     method: 'GET',
  //     headers: {
  //       "Authorization": "Bearer " + auth.user.token
  //     }
  //   })
  //     .then(response => {
  //       if (response.status === 200) {
  //         console.log(response.data);
  //       }
  //     })
  //     .catch(error => {
  //       if (error.response.status === 401) {
  //         console.log(error);
  //       }
  //     });
  //   setUserData({ nombre: "David Eliseo", tipo: "Land Owner", verified: true });
  // }, []);

  useEffect(() => {
    if (user) {
      setUserInfo(user)
    }
  }, [user]);
  console.log("userInfo", user)
  // if(isLoading){
  //   return "loading..."
  // }

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.1),
              rgba(gradients.info.state, 0.3)
            )}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      >
        <MDBox
          position="absolute"
          top={10}
          left={10}
          color="white">
          <CurrentDateTime />
        </MDBox>
      </MDBox>
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        {isLoading ? (
          <MDBox height={150}>
            <CircularProgress color="info" />
          </MDBox>
        ) : (
          <>
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <MDAvatar src={burceMars} alt="profile-image" size="xl" shadow="sm" />
              </Grid>

              <Grid item>
                <MDBox height="100%" mt={0.5} lineHeight={1}>
                  <MDTypography variant="h5" fontWeight="medium">
                    {userInfo?.first_name} {userInfo?.last_name} {userInfo?.verified && (<Icon color="info">verified</Icon>)}
                  </MDTypography>
                  <MDTypography variant="button" color="text" fontWeight="regular">
                    {userInfo?.type === 1 ? "Land Owner" : "Investor"}
                  </MDTypography>
                </MDBox>
              </Grid>
              {!userInfo?.verified && (
                <Grid item>
                  <MDButton component={Link} color="light" to="/pages/user-varification" >Verify Account</MDButton>
                </Grid>
              )}
              <Grid item xs={12} md={6} gap={2} lg={4} sx={{ ml: "auto" }} container direction="row-reverse" justifyContent="flex-start" alignItems="flex-end">
                {isEdit ? (
                  <>
                    <MDButton color="success" onClick={() => setIsEdit(prev => !prev)}>Save Profile</MDButton>
                    <MDButton color="warning" onClick={() => setIsEdit(prev => !prev)}>Cancel</MDButton>
                  </>
                ) : (
                  <MDButton color="success" onClick={() => setIsEdit(prev => !prev)}>Edit Profile</MDButton>
                )}
              </Grid>
            </Grid>
            {children}
          </>
        )}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
