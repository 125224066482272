import React, { useState, useContext, useEffect, createContext } from "react";
import axios from "axios";
import { useMaterialUIController, setAuthenticated } from "context";

const authContext = createContext();

export const useAuth = () => { 
  return useContext(authContext); 
}

function useProvideAuth() {
  const [user, setUser] = useState({id:"", email: "", token:"", tipo: ""});
  const [controller, dispatch] = useMaterialUIController();
  const params = new URLSearchParams();

  const signin = async (email, password) => {    

    params.append('username', email);
    params.append('password', password);

    try {
      //const response = await axios.post("https://biota-backend.herokuapp.com/api/login",params);
      const response = {data: {JWT : "1"}}; 
      setUser({"id":7, "email": email, "token": response.data.JWT, "tipo": "landowner" });  
      
      setAuthenticated(dispatch, true);
           
      return {"token": response.data.JWT, error: ""};
    } catch (error) {
      
      return {"token": "", error: error.response.statusText}; 
    }  
 

  };

  const signup = (email, password) => {
    const Hello = "Hello" + email + password;
    return Hello;
  };

  const signout = () => {
    return "";
  };

  const sendPasswordResetEmail = (email) => {
    return email;
  };

  const confirmPasswordReset = (code, password) => {
    return code + password;
  };

  useEffect(() => {
    const unsubscribe = () => {
      /* if (user) {
        setUser(user);
      } else { */
        setUser(false);
      // }
    };

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Return the user object and auth methods
  return {
    user,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
  };
}

export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}