/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components

import FundingList from "biota/Lists/FundingMethodList";

function FundingMethods() {
  return (
    <Card id="funding-methods" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Funding Methods</MDTypography>
        <MDTypography variant="caption">Here you can set and manage</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FundingList title="" categories={[
                                            {
                                              color: "dark",
                                              icon: "account_balance",
                                              name: "Bank account 1",
                                              description: (
                                                <>
                                                  250 in stock,{" "}
                                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                                    346+ sold
                                                  </MDTypography>
                                                </>
                                              ),
                                              route: "/",
                                            },{
                                              color: "dark",
                                              icon: "account_balance",
                                              name: "paypal",
                                              description: (
                                                <>
                                                  250 in stock,{" "}
                                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                                    346+ sold
                                                  </MDTypography>
                                                </>
                                              ),
                                              route: "/",
                                            },{
                                              color: "dark",
                                              icon: "account_balance",
                                              name: "wallet",
                                              description: (
                                                <>
                                                  250 in stock,{" "}
                                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                                    346+ sold
                                                  </MDTypography>
                                                </>
                                              ),
                                              route: "/",
                                            }]} />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default FundingMethods;
