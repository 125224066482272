import MDBox from 'components/MDBox';
import React, { useState } from 'react'
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import 'react-credit-cards/es/styles-compiled.css';
import Card from 'react-credit-cards';
import { Grid, TextField } from '@mui/material';
import MDButton from 'components/MDButton';
import { useBuyNFTItemMutation } from 'app/services/nftApi';
import {baseURL} from 'utils/utils';
import Swal from 'sweetalert2'

import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  formatFormData,
} from './creditCartUtils';



import "./BuyNFTCreditCardModal.scss";


export class PaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      number: '',
      name: '',
      expiry: '',
      cvc: '',
      issuer: '',
      focused: '',
      formData: null,
    };

    this.nftInformation = this.props;
  }

  handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      this.setState({ issuer });
    }
  };

  handleInputFocus = ({ target }) => {
    this.setState({
      focused: target.name,
    });
  };

  handleInputChange = ({ target }) => {
    let { value } = target;
    const { name } = target;
    if (name === 'number') {
      value = formatCreditCardNumber(value);
    } else if (name === 'expiry') {
      value = formatExpirationDate(value);
    } else if (name === 'cvc') {
      value = formatCVC(value);
    }
    this.setState({ [target.name]: target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { issuer } = this.state;
    const formData = [...e.target.elements]
      .filter(d => d.name)
      .reduce((acc, d) => {
        acc[d.name] = d.value;
        return acc;
      }, {});
    //console.log('En el submit',this.nftInformation);
    const buyerId = parseInt(localStorage.getItem("userId"),10);
    const itemData=JSON.stringify({
      "nft_id":parseInt(this.nftInformation.nftData.nfts.key,10),
      "owner_id":buyerId,
      "seller_id":this.nftInformation.nftData.nfts.owner,
      "project_id":this.nftInformation.nftData.nfts.project_id,
      "from_wallet_address":"0x209083AD5172510504D4c9AE1790844236729173",
      "to_wallet_address":"0x209083AD5172510504D4c9AE1790844236444444",
      "finantial_method_id":1,
      "description":"Buy NFT Test",
      "meta":{"sample":"test"},
      "currency":"USD",
      "amount":this.nftInformation.nftData.nfts.value,
      "token":"Rjc4N0M5OEQ0MEVCMDQ1OTYxNDkyRTI0QUMxRTUwMDhBRDBCMzc3NjFCMzM2QTdCRThBREYyMTAwRTkwMkVDRTZGM0NBQkVDM0RFNTk0ODFBQjBBQUM3QUI0OTU2Q0EzNTlBM0VERkI4OTdBMkQyNjQ2MzZGNTcyRDJCODJBNjg="
    })
    
    console.log(itemData);
    const token = JSON.parse(localStorage.getItem("user")).JWT;
    fetch(baseURL+"/nft/buy-item",{
      "method":"POST",
      "headers":{
        "X-Authorization":"Bearer "+token,
        'Content-Type':'application/json'
      },
      "body":itemData
    })
    .then(response => response.json())
    .then(response => {
      console.log(response)
      this.form.reset();
      
      if(response===false){
        Swal.fire({
          title: 'Error!',
          text: 'You cannot buy this NFT, it already has an owner.',
          icon: 'error',
          confirmButtonText: 'ok'
        })
      }else{
        Swal.fire({
          title: 'Congratulations!',
          text: 'Congratulations, you now own this BIOTA NFT',
          icon: 'success',
          confirmButtonText: 'Cool'
        })
      }

    })
    .catch(err => {
      console.log(err);
    });
  };

  render() {
    const { name, number, expiry, cvc, focused, issuer, formData } = this.state;

    return (
      <MDBox key="Payment" maxWidth="400px">
        <div className="App-payment">
          <Card
            number={number}
            name={name}
            expiry={expiry}
            cvc={cvc}
            focused={focused}
            callback={this.handleCallback}
          />
          <form ref={c => { this.form = c }} onSubmit={this.handleSubmit}>
            <Grid container spacing={2} my={2}>
              <Grid item xs={12}>
                <TextField id="outlined-basic" label="Card Number" variant="outlined"
                  fullWidth
                  type="tel"
                  name="number"
                  pattern="[\d| ]{16,22}"
                  helperText="E.g.: 49..., 51..., 36..., 37..."
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="text"
                  name="name"
                  label="Name"
                  required
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  type="tel"
                  name="expiry"
                  label="Valid Thru"
                  pattern="\d\d/\d\d"
                  required
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  type="tel"
                  name="cvc"
                  label="CVC"
                  pattern="\d{3,4}"
                  required
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                />
              </Grid>
            </Grid>
            <input type="hidden" name="issuer" value={issuer} />
            <MDButton fullWidth variant="contained" type="submit" color="primary">PAY</MDButton>

          </form>
          {formData && (
            <div className="App-highlight">
              {formatFormData(formData).map((d, i) => <div key={d}>{d}</div>)}
            </div>
          )}
        </div>
      </MDBox>
    );
  }
}


function BuyNFTCreditCardModal({ open, handleClose, nftData }) {
  return (
    <div className='buy-nft-credit-card-modal-container'>
      <Modal classNames="" showCloseIcon modalId="buy-nft-modal" open={open} onClose={handleClose} center>
        <MDBox className="modal-content" p={4}>
          <PaymentForm  nftData={nftData}/>
        </MDBox>
      </Modal>
    </div>
  );
}

export default BuyNFTCreditCardModal
