/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useForm } from "react-hook-form";
import { Alert, Snackbar, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { useChangePasswordMutation } from "app/services/changePasswordApi";
import { useGetUserQuery } from "app/services/userApi";
import { useState } from "react";

function ChangePassword({ user }) {
  const [changePassword, { isLoading, isSuccess, status, isError }] = useChangePasswordMutation();
  const { register, control, watch, reset, handleSubmit, formState: { errors, isDirty, isValid } } = useForm();
  const [open, setOpen] = useState(false);
  const onSubmit = async ({ confirmPassword, ...data }) => {

    console.log("Password data:", data);
    const response = await changePassword({ id: user?.id, ...data });
    console.log("RESPONSE", response);
    if (response?.data?.success) {
      setOpen(true);
      reset();
    }
  }
  const passwordRequirements = [
    "One special characters",
    "Min 6 characters",
    "One number (2 are recommended)",
    "Change it often",
  ];

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <MDBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
        <MDTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
          {item}
        </MDTypography>
      </MDBox>
    );
  });

  return (
    <Card id="change-password">
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Updated successfully!
        </Alert>
      </Snackbar>
      <MDBox p={3}>
        <MDTypography variant="h5">Change Password</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Current Password"
              error={!!errors?.old_pwd}
              {...register("old_pwd", { required: "This field is required!" })}
              inputProps={{ type: "password", autoComplete: "" }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="New Password"
              error={!!errors?.password}
              {...register("password", {
                required: "This field is required!",
                pattern: /(?=.*\d)(?=.*?[.=_#?!@$%^&*-])/,
                minLength: {
                  value: 6,
                  message: "Password must have at least 6 characters"
                }
              })}
              inputProps={{ type: "password", autoComplete: "" }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Confirm New Password"
              error={!!errors?.confirmPassword}
              {...register("confirmPassword", {
                required: true,
                validate: (val) => {
                  if (watch('password') !== val) {
                    return "Your passwords do no match";
                  }
                  return undefined;
                },
              })}
              helperText={errors?.confirmPassword?.message}
              inputProps={{ type: "password", autoComplete: "" }}
            />
          </Grid>
        </Grid>
        <MDBox mt={6} mb={1}>
          <MDTypography variant="h5">Password requirements</MDTypography>
        </MDBox>
        <MDBox mb={1}>
          <MDTypography variant="body2" color="text">
            Please follow this guide for a strong password
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
          <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
            {renderPasswordRequirements}
          </MDBox>
          <MDBox ml="auto">
            <MDButton type="submit" variant="gradient" color="dark" size="small">
              update password
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default ChangePassword;
