import { format } from 'date-fns';
import React, { useEffect, useState } from 'react'

import "./CurrentDateTime.scss";

function CurrentDateTime()  {
    const [date,setDate] = useState(new Date());
    
    useEffect(() => {
        const timer = setInterval(()=>setDate(new Date()), 1000 )
        return function cleanup() {
            clearInterval(timer)
        }
    
    });

    return(
        <div className='current-date-time'>
            <span>{format(date,'dd.MM.yyyy')}</span> &nbsp; <span>{format(date,'hh.mmaaa')}</span>
        </div>
    )
}

export default CurrentDateTime