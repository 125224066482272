/* eslint-disable */

import axios from 'axios'
import { baseURL } from 'utils/utils';

// const API_URL = '/api/users/'
// const API_URL = 'https://927d1d30.us-south.apigw.appdomain.cloud/biota-api'
const API_URL = baseURL;


// Register user
const register = async (userData) => {
    const response = await axios.post(API_URL + "/register", userData)
console.log("register response",response);
    if (response.data) {
        localStorage.setItem('user', JSON.stringify(response.data))
    }

    return response.data
}

// Login user
const login = async (userData) => {
    const params = new URLSearchParams()
    params.append('username', userData.username);
    params.append('password', userData.password);

    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    // axios.post(url, params, config)
    const response = await axios.post(API_URL + '/login',params, config);
    if (response.data) {
        localStorage.setItem('user', JSON.stringify(response.data))
    }

    return response.data
}

// Logout user
const logout = () => {
    localStorage.removeItem('user')
    window.location.href="/authentication/sign-in";
}

const authService = {
    register,
    logout,
    login,
}

export default authService