/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo, Suspense } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import LocaleContext from "context/LocaleContext";
import ProtectedRoute from "components/ProtectedRoute";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import Sidenav from "biota/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 PRO React routes
import routes from "routes";
import landOwnerRoutes from "landOwnerRoutes";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUp from "layouts/authentication/sign-up/cover";
import BiotaFaq from "layouts/pages/biota-faq";

// Images
import brandWhite from "assets/images/logobiota.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { useSelector,useDispatch } from "react-redux";
import { logout } from "app/slices/authSlice";
import { isJwtExpire,userTypesEnum } from "utils/utils";
import { useGetUserQuery } from "app/services/userApi";


import Loading from "./biota/Loading";

import i18n from "./i18n";
import { ProvideAuth, useAuth } from "./use-auth";


const updateUserNameInRoute = (routesData,user)=>{
  return routesData?.map(item=>{
    if(item?.key==="user-profile"){
      return {...item,name:`${user?.first_name || "User"} ${user?.last_name || "Mngmt"}`}
    }
    return item;
  });
}

export default function App() {
  const [locale, setLocale] = useState(i18n.language);
  const auth = useAuth();
  i18n.on("languageChanged", () => setLocale(i18n.language));
  const navigate = useNavigate();
  const { status, data:user,isError, error, isLoading, isSuccess } = useGetUserQuery();
const [appRoutes, setAppRoutes] = useState([]);
  const reduxDispatch = useDispatch();



  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    authenticated,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    // alert("path change")
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if(isJwtExpire()){
      reduxDispatch(logout());
    }
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

    //settings button at the bottom right corner
  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  useEffect(()=>{
    if(user?.type === userTypesEnum.Owner){
      setAppRoutes(updateUserNameInRoute(landOwnerRoutes,user));
    }else{
      setAppRoutes(updateUserNameInRoute(routes,user));
    }
  },[user]);

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName=""
              routes={appRoutes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {/* {configsButton} */}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          <Route element={<ProtectedRoute user={auth} />}>
            {getRoutes(appRoutes)}
            <Route path="*" element={<Navigate to="/dashboards/biota" />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <LocaleContext.Provider value={useMemo(() => ({ locale, setLocale }), [locale, setLocale])}>
      <Suspense fallback={<Loading />}>
        <ThemeProvider theme={darkMode ? themeDark : theme}>
          <CssBaseline />
          <ProvideAuth>
            <Routes>
              <Route element={<ProtectedRoute user={auth} />}>
                {getRoutes(appRoutes)}
                <Route path="*" element={<Navigate to="/dashboards/biota" />} />
              </Route>
              <Route
                exact
                path="/authentication/sign-in"
                element={<SignInIllustration />}
                key="illustration"
              />
              ;
              <Route exact path="/authentication/sign-up/cover" element={<SignUp />} key="cover" />
              ;
              <Route exact path="/pages/biota-faq" element={<BiotaFaq />} key="biota-faq" />;
            </Routes>
          </ProvideAuth>
          {!authenticated && layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                brandName=""
                routes={appRoutes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
              {/* {configsButton} */}
            </>
          )}
          {layout === "vr" && <Configurator />}
        </ThemeProvider>
      </Suspense>
    </LocaleContext.Provider>
  );
}
