import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Select from "react-select";
import DocumentImage from "assets/images/biota/document.png";
import { React, useState } from "react";
import FileUploader from "biota/Components/FileUploader";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

function Step2({ submit, errorMessage, idUser }) {
  const navigate = useNavigate();
  const params = new URLSearchParams();
  const [file, setFile] = useState("");

  const handleUploadButton = async () => {
    params.append("files", file);
    params.append("related_id", "1");
    params.append("related_table", idUser);
    try {
      const response = await axios.post(
        "https://biota-microservice.herokuapp.com/files/upload-multiple",
        params
      );
      console.log(response.status);
      // setStep((prev) => prev + 1);
      navigate("/dashboards/biota");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Card>
      <MDBox
        variant="gradient"
        bgColor="success"
        borderRadius="lg"
        coloredShadow="success"
        mx={2}
        mt={-3}
        p={3}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          Document Upload
        </MDTypography>
        <MDTypography display="block" fontWeight="medium" variant="button" color="white" my={1}>
          Investor account
        </MDTypography>
      </MDBox>
      <MDBox pt={4} pb={3} px={3}>
        <MDBox component="form" role="form">
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <MDBox
                variant="gradient"
                bgColor="white"
                borderRadius="lg"
                coloredShadow="success"
                mt={-1}
                p={2}
                mb={1}
                textAlign="center"
              >
                <FileUploader setFile={setFile}>
                  {" "}
                  <img alt="" src={DocumentImage} />{" "}
                </FileUploader>

                <MDTypography
                  display="block"
                  fontWeight="small"
                  variant="button"
                  color="black"
                  my={1}
                >
                  Identification
                </MDTypography>
                <MDTypography
                  display="block"
                  fontWeight="medium"
                  variant="button"
                  color="black"
                  my={1}
                >
                  Upload
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item lg={6} md={6}>
              <MDBox
                variant="gradient"
                bgColor="white"
                borderRadius="lg"
                coloredShadow="success"
                mt={-1}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MDButton variant="gradient" color="dark" fullWidth>
                  <img alt="" src={DocumentImage} />
                </MDButton>
                <MDTypography
                  display="block"
                  fontWeight="small"
                  variant="button"
                  color="black"
                  my={1}
                >
                  Pending
                </MDTypography>
                <MDTypography
                  display="block"
                  fontWeight="medium"
                  variant="button"
                  color="black"
                  my={1}
                >
                  Upload
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>

          <MDBox mt={4} mb={1}>
            <MDButton
              variant="gradient"
              color="success"
              onClick={() => {
                navigate("/dashboards/biota");
              }}
              fullWidth
            >
              Skip
            </MDButton>
          </MDBox>
          <MDBox mt={2} mb={1}>
            <MDButton
              variant="gradient"
              color="success"
              onClick={() => handleUploadButton()}
              fullWidth
            >
              Upload
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Step2;
