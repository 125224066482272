import { useEffect, useState } from 'react';
import DashboardLayout from 'biota/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'biota/Navbars/DashboardNavbar'
import MDBox from 'components/MDBox'
import backgroundImage from "assets/images/biota/banner_image.jpeg";
import chairImage from "assets/images/ecommerce/black-chair.jpeg";
import cardImage from "assets/images/bg-player.jpeg";
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Button, FormControl, FormControlLabel, Grid, Icon, IconButton, InputBase, InputLabel, MenuItem, Paper, Select, Switch, TextField } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';

import { useGetUserQuery } from 'app/services/userApi';
import { useGetProjectsWithFilesQuery, useGetAllProjectsQuery } from 'app/services/projectsApi';
import MDButton from 'components/MDButton';
import ProjectGridCard from './ProjectGridCard/ProjectGridCard';
import ProjectListCard from './ProjectListCard/ProjectListCard';

import "./AllProjects.scss";



function AllProjects() {
  const [gridChecked, setGridChecked] = useState(true);
  const [listChecked, setListChecked] = useState(false);
  const [projectsData, setProjectsData] = useState([]);
  const [projectsFilteredData, setProjectFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("")
  const { status: userStatus, data: user, error: userError, isLoading: userLoading, isSuccess: userSuccess } = useGetUserQuery();
  const { status, data, isError, error, isLoading, isSuccess } = useGetAllProjectsQuery();

  const handleViewMode = (view) => {
    setListChecked(prev => !prev);
    setGridChecked(prev => !prev);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  }
  useEffect(() => {
    setProjectsData(data?.projects)
  }, [data])
  useEffect(() => {
    // if (searchTerm.length) {
    //   const filterdData = projectsData?.filter(function (v, i) {
    //     return (v.listing_title.toLowerCase().includes(searchTerm.toLowerCase()) || v.description.toLowerCase().includes(searchTerm.toLowerCase()));
    //   });
    //   setProjectFilteredData(filterdData);
    // } else {
    //   setProjectsData(projectsData);
    //   setProjectFilteredData([]);
    // }
    if (searchTerm.length) {
      const filterdData = data?.projects?.filter(function (v, i) {
        return (v.listing_title.toLowerCase().includes(searchTerm.toLowerCase()) || v.description.toLowerCase().includes(searchTerm.toLowerCase()));
      });
      setProjectFilteredData(filterdData);
    } else {
      setProjectsData(data?.projects);
      setProjectFilteredData([]);
    }

  }, [searchTerm])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <div className="all-projects-container">
        <MDBox
          className="top-banner"
          position="relative"
          minHeight="18.75rem"
          borderRadius="xl"
          style={{ padding: "10px 20px" }}
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.5),
                rgba(gradients.dark.main, 0.5)
              )}, url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        >
          <MDBox mb={2} color="white">{user?.type === 1 ? "My Projects" : "Discover"}</MDBox>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <MDBox>
                <Paper
                  sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "100%" }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                </Paper>
              </MDBox>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2} className="project-banner-right">
                <Grid item xs={4}>
                  <FormControlLabel
                    className='grid-view-btn'
                    control={
                      <Switch checked={gridChecked} onChange={() => handleViewMode("grid")} color="secondary" />
                    }
                    label="Grid View"
                  />
                  <FormControlLabel
                    className='list-view-btn'
                    control={
                      <Switch checked={listChecked} onChange={() => handleViewMode("list")} color="secondary" />
                    }
                    label="List View"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    {/* <InputLabel id="demo-simple-select-standard-label">Age</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={10}
                      onChange={() => { }}
                      label="Age"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={10}>0.25 ha</MenuItem>
                      <MenuItem value={20}>0.50 ha</MenuItem>
                      <MenuItem value={30}>1 ha</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {/* <Grid item xs={4}>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} white>
                    <InputLabel id="demo-simple-select-standard-label">Age</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={10}
                      onChange={() => { }}
                      label="Age"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
          
          {user?.type !==2 &&
          <Grid container justifyContent="flex-end">
            <MDButton variant="contained" color="success" startIcon={<Icon>add</Icon>}>
              Create new project
            </MDButton>
          </Grid>
          }
        </MDBox>
        <MDBox mt={10}>
          {isLoading && "Loading..."}
          {!isLoading && (
            gridChecked ? (
              <Grid container columnSpacing={4} rowSpacing={8}>
                {projectsFilteredData.length ? projectsFilteredData?.map((project) => (
                  <Grid item sm={6} md={4} key={project.id} >
                    <ProjectGridCard project={project} />
                  </Grid>
                )) : data?.projects?.map((project) => (
                  <Grid item sm={6} md={4} key={project.id} >
                    <ProjectGridCard project={project} />
                  </Grid>
                ))
                }
              </Grid>
            ) : <Grid container rowSpacing={4}>
              {projectsFilteredData.length ? projectsFilteredData?.map((project) => (
                <Grid item sm={6} md={12} key={project.id} >
                  <ProjectListCard project={project} />
                </Grid>
              )) : data?.projects?.map((project) => (
                <Grid item sm={6} md={12} key={project.id} >
                  <ProjectListCard project={project} />
                </Grid>
              ))
              }
            </Grid>
          )}
        </MDBox>
      </div>
    </DashboardLayout>
  )
}

export default AllProjects