/* eslint-disable */

// export const baseURL = "https://api.biotanft.com/api";
export const baseURL = "https://secure.biota.land/api.biota.land/api";


const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};
export const isJwtExpire = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    const decodedJwt = parseJwt(user.JWT);
    if (decodedJwt) {
      return decodedJwt.exp * 1000 < Date.now()
    }
  }
};


export const countriesData = [{
  label: "United States",
  value: "us",
  code: "+1",//usd //eng
  currency: "USD",
  language: "eng"
}, {
  label: "Guatemala",
  value: "gt",
  code: "+502",//usd // spa
  currency: "USD",
  language: "spa"
}, {
  label: "El Salvador",
  value: "es",
  code: "+503",//usd //span
  currency: "USD",
  language: "spa"
}, {
  label: "Costa Rica",
  value: "cr",
  code: "+506",//crc //span
  currency: "CRC",
  language: "spa"
}];

export const languagesData = [{
  label: "English",
  value: "en"
},
{
  label: "Spanish",
  value: "spa"
}];
export const currenciesData = ["usd", "crc"];

export const topicsSelectData = [{
  label: "Security",
  value: "security"
},
{
  label: "NFT",
  value: "nft"
},
{
  label: "Payments",
  value: "payments"
},
{
  label: "Other",
  value: "other"
}];


export const userTypesEnum = {
  Owner: 1,
  Investor: 2
}

//1= Buy, 2 Sell
export const transactionTypesEnum = {
  Buy: 1,
  Sell: 2
}

export const getTransactionTypeById = (typeId) => {
  return Object.keys(transactionTypesEnum).find(key => transactionTypesEnum[key] == typeId);
}




export const groupByObjectByKey = (input, key) => {
  return input?.reduce((acc, currentValue) => {
    let groupKey = currentValue[key];
    console.log("currentValue", currentValue)
    if (!acc[groupKey]) {
      acc[groupKey] = [];
    }
    acc[groupKey].push(currentValue.amount);
    return acc;
  }, {});
};
// export const sortObjectByKey = (obj) => {
//   return Object.keys(obj).sort().reduce(
//     (obj, key) => {
//       obj[key] = obj[key];
//       return obj;
//     },
//     {}
//   );
// }



export const monthsShortNamesData = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sept", "Oct", "Nov", "Dec"]