import { configureStore } from '@reduxjs/toolkit';
import authReducer from "./slices/authSlice";
import userReducer from "./slices/userSlice";
import {userSettingsApi} from "./services/userSettingsApi";
import {userApi} from "./services/userApi";
import {changePasswordApi} from "./services/changePasswordApi";
import {notificationApi} from "./services/notificationApi";
import {projectsApi} from "./services/projectsApi";
import {uploadFilesApi} from "./services/uploadFilesApi";
import {nftApi} from "./services/nftApi";

export default configureStore({
  reducer: {
    auth:authReducer,
    user:userReducer,
    [userSettingsApi.reducerPath]: userSettingsApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [changePasswordApi.reducerPath]: changePasswordApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [projectsApi.reducerPath]: projectsApi.reducer,
    [uploadFilesApi.reducerPath]: uploadFilesApi.reducer,
    [nftApi.reducerPath]: nftApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(userSettingsApi.middleware,userApi.middleware,
    changePasswordApi.middleware,
    notificationApi.middleware,
    projectsApi.middleware,
    uploadFilesApi.middleware,
    nftApi.middleware)
})