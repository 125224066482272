/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import * as React from 'react';
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples

import DashboardLayout from "biota/LayoutContainers/DashboardLayout";
import DashboardNavbar from "biota/Navbars/DashboardNavbar";
import Footer from "biota/Footer";

import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";
// Data
import Timeline from '@mui/lab/Timeline';

import { Alert, CircularProgress, Paper, Stack } from '@mui/material';

import MDButton from 'components/MDButton';
import { useGetAllUnReadNotificationsQuery } from 'app/services/notificationApi';
import { useGetUserQuery } from 'app/services/userApi';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetAllNotificationsQuery } from 'app/services/notificationApi';
import CustomTimelineItem from './components/CustomTimelineItem/CustomTimelineItem';
import { useMarkAllNotificationsReadMutation } from 'app/services/notificationApi';

function NotificationsTimeline() {
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState([]);
  const { status: userStatus, data: user, error: userError, isLoading: userLoading, isSuccess: userSuccess } = useGetUserQuery();
  
  
  const { data: unreadNotifications, status, isError, error, isLoading, isSuccess } = useGetAllUnReadNotificationsQuery(user?.id ?? skipToken);
  const { data: allNotifications, status: allStatus, isError: allIsError, error: allError, isLoading: allIsLoading, isSuccess: allIsSuccess } = useGetAllNotificationsQuery(user?.id ?? skipToken);
  const [markAllNotificationRead, { isLoading:markAllLoading }] = useMarkAllNotificationsReadMutation();
  const showAllNotifications = ()=>{
    setNotifications(allNotifications);
  }
  const markAllRead =async ()=>{
   const response = await markAllNotificationRead(user?.id);
   console.log("mark all read response:",response);
  }

  useEffect(() => {
    setNotifications(unreadNotifications)
  }, [unreadNotifications]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Paper sx={{ my: 4, px: 2 }}>
        {notifications?.error ? (
          <MDBox p={4}>
            <Alert severity="error">There is something went wrong!</Alert>
          </MDBox>
        ) : isLoading || userLoading ? (<MDBox p={4}>
          <CircularProgress color="info" />
        </MDBox>) : (
          <>
            <Grid container spacing={3} >
              <Grid item xs={12} lg={6}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography fontWeight="500">{t("notifications-title")}</Typography>
                  <MDButton variant="outlined" color="primary" onClick={markAllRead} >Mark all as read</MDButton>
                </Stack>
              </Grid>
              <Grid item xs={12} lg={4}>
                <MDButton color="primary" onClick={showAllNotifications}>List All Notifications</MDButton>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <Timeline sx={{
                  mt: 3,
                  "& .MuiTimelineItem-root:before": { display: "none" }, "& .MuiTimelineContent-root": {
                    mb: 4
                  }
                }}>
                  {notifications && notifications?.map((item, idx) => (
                    <CustomTimelineItem {...item} key={item.id} setNotifications={setNotifications}/>
                  ))}
                  {!notifications?.length  && (
                      <Typography fontWeight="500">There is no unread notification.</Typography>
                  )}
                </Timeline>
              </Grid>
            </Grid>
          </>
        )}

      </Paper>
      <Footer />

    </DashboardLayout>
  );
}

export default NotificationsTimeline;
