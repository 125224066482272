/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import Analytics from "layouts/dashboards/analytics";
import Sales from "layouts/dashboards/sales";
import BiotaDashboard from "layouts/dashboards/biota";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import ProjectList from "layouts/pages/projects/projectlist";
import NewUser from "layouts/pages/users/new-user";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import Timeline from "layouts/pages/projects/timeline";
import PricingPage from "layouts/pages/pricing-page";
import AllProjects from "layouts/pages/AllProjects/AllProjects";
import Widgets from "layouts/pages/widgets";
import RTL from "layouts/pages/rtl";
import Charts from "layouts/pages/charts";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import Calendar from "layouts/applications/calendar";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignUpCover from "layouts/authentication/sign-up/cover";
import ForgotPassword from "layouts/authentication/reset-password/forgotpassword";
import ChangePassword from "layouts/authentication/reset-password/changepassword";
import NotificationsTimeline from "layouts/pages/notifications/timeline";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-3.jpg";
import UserVerification from "layouts/applications/userverification";
import WithdrawalProcess from "layouts/pages/WithdrawalProcess/WithdrawalProcess";
import CreateProject from "layouts/pages/projects/CreateProject/CreateProject";
import TransactionsList from "layouts/pages/account/TransactionsList/TransactionsList";
import SingleProject from "layouts/pages/projects/SingleProject/SingleProject";
import SingleProjectDetail from "layouts/pages/projects/SingleProject/SingleProjectDetail/SingleProjectDetail";
import PersonIcon from '@mui/icons-material/Person';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ProjectPreview from "layouts/pages/projects/ProjectPreview/ProjectPreview";

const routes = [
  {
    type: "collapse",
    name: "Land Owner Routes",
    key: "user-profile",
    icon: <AccountBoxIcon  sx={{width:"28px",height:"28px"}} />,
    collapse: [
      {
        name: "My Profile",
        key: "my-profile",
        route: "/pages/profile/profile-overview",
        component: <ProfileOverview />,
      },
      {
        name: "Settings",
        key: "profile-settings",
        route: "/pages/account/settings",
        component: <Settings />,
      },
      {
        name: "Notifications",
        key: "notifications",
        route: "/pages/notifications/timeline",
        component: <NotificationsTimeline />,
      },
      // {
      //   name: "Logout",
      //   key: "logout",
      //   route: "/authentication/sign-in/basic",
      //   component: <SignInBasic />,
      // },
    ],
  },
  { type: "divider", key: "divider-0" },

  {
    type: "collapse",
    name: "Explore",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Dashboard",
        key: "home",
        route: "/dashboards/biota",
        component: <BiotaDashboard />,
        icon: <Icon fontSize="medium">dashboard</Icon>,
      },
      {
        name: "Wallet",
        key: "billing",
        route: "/pages/account/billing",
        component: <Billing />,
        icon: <Icon fontSize="medium">dashboard</Icon>,
      },
      {
        name: "My projects",
        key: "discover",
        route: "/dashboards/my-projects",
        component: <AllProjects />,
        icon: <Icon fontSize="medium">dashboard</Icon>,
      },
      // {
      //   name: "Biota",
      //   key: "biota",
      //   route: "/dashboards/biota",
      //   component: <BiotaDashboard />,
      // },
      // {
      //   name: "Analytics",
      //   key: "analytics",
      //   route: "/dashboards/analytics",
      //   component: <Analytics />,
      // },
      // {
      //   name: "Sales",
      //   key: "sales",
      //   route: "/dashboards/sales",
      //   component: <Sales />,
      // },
    ],
  },
  { type: "title", title: "Pages", key: "title-pages" },
  
  {
    type: "collapse",
    name: "Pages",
    key: "pages",
    icon: <Icon fontSize="medium">image</Icon>,
    collapse: [
      // {
      //   name: "Withdrawal Request",
      //   key: "withdrawal-request",
      //   route: "/pages/withdrawal-request",
      //   component: <WithdrawalProcess />,
      // },
      // {
      //   name: "Profile",
      //   key: "profile",
      //   collapse: [
      //     {
      //       name: "Profile Overview",
      //       key: "profile-overview",
      //       route: "/pages/profile/profile-overview",
      //       component: <ProfileOverview />,
      //     },
      //     {
      //       name: "All Projects",
      //       key: "all-projects",
      //       route: "/pages/projects/projectslist",
      //       component: <ProjectList />,
      //     },
      //   ],
      // },
      {
        name: "Users",
        key: "users",
        collapse: [
          {
            name: "User Varification",
            key: "user-varification",
            route: "/pages/user-varification",
            component: <UserVerification />,
          },
          {
            name: "Withdrawal Request",
            key: "withdrawal-request",
            route: "/pages/withdrawal-request",
            component: <WithdrawalProcess />,
          },
          {
            name: "New User",
            key: "new-user",
            route: "/pages/users/new-user",
            component: <NewUser />,
          },
          {
            name: "User Wallet",
            key: "billing",
            route: "/pages/account/billing",
            component: <Billing />,
          },
        ],
      },
      // {
      //   name: "Account",
      //   key: "account",
      //   collapse: [
      //     // {
      //     //   name: "Transactions List",
      //     //   key: "transactions-list",
      //     //   route: "/pages/account/transactions",
      //     //   component: <TransactionsList />,
      //     // },
      //     {
      //       name: "Settings",
      //       key: "settings",
      //       route: "/pages/account/settings",
      //       component: <Settings />,
      //     },
      //     // {
      //     //   name: "Billing",
      //     //   key: "billing",
      //     //   route: "/pages/account/billing",
      //     //   component: <Billing />,
      //     // },
      //     // {
      //     //   name: "Invoice",
      //     //   key: "invoice",
      //     //   route: "/pages/account/invoice",
      //     //   component: <Invoice />,
      //     // },
      //   ],
      // },
      {
        name: "Project Mgmt",
        key: "projects",
        collapse: [
          {
            name: "Single Project",
            key: "single-project",
            route: "/pages/projects/single-project/:id",
            display:false,
            component: <SingleProject />,
          },
          {
            name: "Single Project Detail",
            key: "single-project-detail",
            route: "/pages/projects/single-project-detail/:id",
            component: <SingleProjectDetail />,
          },
          {
            name: "Create Project",
            key: "create-project",
            route: "/pages/projects/create",
            component: <CreateProject />,
          },
          {
            name: "Project Preview",
            key: "project-preview",
            route: "/pages/projects/preview",
            component: <ProjectPreview />,
          },
          {
            name: "Transactions List",
            key: "transactions-list",
            route: "/pages/account/transactions",
            component: <TransactionsList />,
          }
          // {
          //   name: "Timeline",
          //   key: "timeline",
          //   route: "/pages/projects/timeline",
          //   component: <Timeline />,
          // },
        ],
      },
      // {
      //   name: "Pricing Page",
      //   key: "pricing-page",
      //   route: "/pages/pricing-page",
      //   component: <PricingPage />,
      // },
      // {
      //   name: "All Projects",
      //   key: "all-projects",
      //   route: "/pages/all-projects",
      //   component: <AllProjects />,
      // },
      // {
      //   name: "User Varification",
      //   key: "user-varification",
      //   route: "/pages/user-varification",
      //   component: <UserVerification />,
      // },
      // { name: "RTL", key: "rtl", route: "/pages/rtl", component: <RTL /> },
      // { name: "Widgets", key: "widgets", route: "/pages/widgets", component: <Widgets /> },
      // { name: "Charts", key: "charts", route: "/pages/charts", component: <Charts /> },
      {
        name: "Notifications",
        key: "notifications",
        route: "/pages/notifications/timeline",
        component: <NotificationsTimeline />,
      },
    ],
  },
  // {
  //   type: "collapse",
  //   name: "Applications",
  //   key: "applications",
  //   icon: <Icon fontSize="medium">apps</Icon>,
  //   collapse: [
  //     {
  //       name: "Kanban",
  //       key: "kanban",
  //       route: "/applications/kanban",
  //       component: <Kanban />,
  //     },
  //     {
  //       name: "Wizard",
  //       key: "wizard",
  //       route: "/applications/wizard",
  //       component: <Wizard />,
  //     },
  //     {
  //       name: "Data Tables",
  //       key: "data-tables",
  //       route: "/applications/data-tables",
  //       component: <DataTables />,
  //     },
  //     {
  //       name: "Calendar",
  //       key: "calendar",
  //       route: "/applications/calendar",
  //       component: <Calendar />,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Ecommerce",
  //   key: "ecommerce",
  //   icon: <Icon fontSize="medium">shopping_basket</Icon>,
  //   collapse: [
  //     {
  //       name: "Products",
  //       key: "products",
  //       collapse: [
  //         {
  //           name: "New Product",
  //           key: "new-product",
  //           route: "/ecommerce/products/new-product",
  //           component: <NewProduct />,
  //         },
  //         {
  //           name: "Edit Product",
  //           key: "edit-product",
  //           route: "/ecommerce/products/edit-product",
  //           component: <EditProduct />,
  //         },
  //         {
  //           name: "Product Page",
  //           key: "product-page",
  //           route: "/ecommerce/products/product-page",
  //           component: <ProductPage />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Orders",
  //       key: "orders",
  //       collapse: [
  //         {
  //           name: "Order List",
  //           key: "order-list",
  //           route: "/ecommerce/orders/order-list",
  //           component: <OrderList />,
  //         },
  //         {
  //           name: "Order Details",
  //           key: "order-details",
  //           route: "/ecommerce/orders/order-details",
  //           component: <OrderDetails />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Authentication",
  //   key: "authentication",
  //   icon: <Icon fontSize="medium">content_paste</Icon>,
  //   collapse: [
  //     {
  //       name: "Sign In",
  //       key: "sign-in",
  //       collapse: [
  //         {
  //           name: "Basic",
  //           key: "basic",
  //           route: "/authentication/sign-in/basic",
  //           component: <SignInBasic />,
  //         },
  //         {
  //           name: "Cover",
  //           key: "cover",
  //           route: "/authentication/sign-in/cover",
  //           component: <SignInCover />,
  //         },
  //       ],
  //     },

  //     {
  //       name: "Forgot Password",
  //       key: "forgot-password",
  //       collapse: [
  //         {
  //           name: "Reset Password",
  //           key: "reset",
  //           route: "/authentication/reset-password/forgotpassword",
  //           component: <ForgotPassword />,
  //         },
  //         {
  //           name: "Change Password",
  //           key: "changepass",
  //           route: "/authentication/reset-password/changepassword",
  //           component: <ChangePassword />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // { type: "divider", key: "divider-1" },
  // { type: "title", title: "Docs", key: "title-docs" },
  // {
  //   type: "collapse",
  //   name: "Basic",
  //   key: "basic",
  //   icon: <Icon fontSize="medium">upcoming</Icon>,
  //   collapse: [
  //     {
  //       name: "Getting Started",
  //       key: "getting-started",
  //       collapse: [
  //         {
  //           name: "Overview",
  //           key: "overview",
  //           href: "https://www.creative-tim.com/learning-lab/react/overview/material-dashboard/",
  //         },
  //         {
  //           name: "License",
  //           key: "license",
  //           href: "https://www.creative-tim.com/learning-lab/react/license/material-dashboard/",
  //         },
  //         {
  //           name: "Quick Start",
  //           key: "quick-start",
  //           href: "https://www.creative-tim.com/learning-lab/react/quick-start/material-dashboard/",
  //         },
  //         {
  //           name: "Build Tools",
  //           key: "build-tools",
  //           href: "https://www.creative-tim.com/learning-lab/react/build-tools/material-dashboard/",
  //         },
  //       ],
  //     },
  //     {
  //       name: "Foundation",
  //       key: "foundation",
  //       collapse: [
  //         {
  //           name: "Colors",
  //           key: "colors",
  //           href: "https://www.creative-tim.com/learning-lab/react/colors/material-dashboard/",
  //         },
  //         {
  //           name: "Grid",
  //           key: "grid",
  //           href: "https://www.creative-tim.com/learning-lab/react/grid/material-dashboard/",
  //         },
  //         {
  //           name: "Typography",
  //           key: "base-typography",
  //           href: "https://www.creative-tim.com/learning-lab/react/base-typography/material-dashboard/",
  //         },
  //         {
  //           name: "Borders",
  //           key: "borders",
  //           href: "https://www.creative-tim.com/learning-lab/react/borders/material-dashboard/",
  //         },
  //         {
  //           name: "Box Shadows",
  //           key: "box-shadows",
  //           href: "https://www.creative-tim.com/learning-lab/react/box-shadows/material-dashboard/",
  //         },
  //         {
  //           name: "Functions",
  //           key: "functions",
  //           href: "https://www.creative-tim.com/learning-lab/react/functions/material-dashboard/",
  //         },
  //         {
  //           name: "Routing System",
  //           key: "routing-system",
  //           href: "https://www.creative-tim.com/learning-lab/react/routing-system/material-dashboard/",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Components",
  //   key: "components",
  //   icon: <Icon fontSize="medium">view_in_ar</Icon>,
  //   collapse: [
  //     {
  //       name: "Alerts",
  //       key: "alerts",
  //       href: "https://www.creative-tim.com/learning-lab/react/alerts/material-dashboard/",
  //     },
  //     {
  //       name: "Avatar",
  //       key: "avatar",
  //       href: "https://www.creative-tim.com/learning-lab/react/avatar/material-dashboard/",
  //     },
  //     {
  //       name: "Badge",
  //       key: "badge",
  //       href: "https://www.creative-tim.com/learning-lab/react/badge/material-dashboard/",
  //     },
  //     {
  //       name: "Badge Dot",
  //       key: "badge-dot",
  //       href: "https://www.creative-tim.com/learning-lab/react/badge-dot/material-dashboard/",
  //     },
  //     {
  //       name: "Box",
  //       key: "box",
  //       href: "https://www.creative-tim.com/learning-lab/react/box/material-dashboard/",
  //     },
  //     {
  //       name: "Buttons",
  //       key: "buttons",
  //       href: "https://www.creative-tim.com/learning-lab/react/buttons/material-dashboard/",
  //     },
  //     {
  //       name: "Date Picker",
  //       key: "date-picker",
  //       href: "https://www.creative-tim.com/learning-lab/react/datepicker/material-dashboard/",
  //     },
  //     {
  //       name: "Dropzone",
  //       key: "dropzone",
  //       href: "https://www.creative-tim.com/learning-lab/react/dropzone/material-dashboard/",
  //     },
  //     {
  //       name: "Editor",
  //       key: "editor",
  //       href: "https://www.creative-tim.com/learning-lab/react/quill/material-dashboard/",
  //     },
  //     {
  //       name: "Input",
  //       key: "input",
  //       href: "https://www.creative-tim.com/learning-lab/react/input/material-dashboard/",
  //     },
  //     {
  //       name: "Pagination",
  //       key: "pagination",
  //       href: "https://www.creative-tim.com/learning-lab/react/pagination/material-dashboard/",
  //     },
  //     {
  //       name: "Progress",
  //       key: "progress",
  //       href: "https://www.creative-tim.com/learning-lab/react/progress/material-dashboard/",
  //     },
  //     {
  //       name: "Snackbar",
  //       key: "snackbar",
  //       href: "https://www.creative-tim.com/learning-lab/react/snackbar/material-dashboard/",
  //     },
  //     {
  //       name: "Social Button",
  //       key: "social-button",
  //       href: "https://www.creative-tim.com/learning-lab/react/social-buttons/material-dashboard/",
  //     },
  //     {
  //       name: "Typography",
  //       key: "typography",
  //       href: "https://www.creative-tim.com/learning-lab/react/typography/material-dashboard/",
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Change Log",
  //   key: "changelog",
  //   href: "https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md",
  //   icon: <Icon fontSize="medium">receipt_long</Icon>,
  //   noCollapse: true,
  // },
];

export default routes;
