import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import MDBox from 'components/MDBox';
import StepperTitle from 'components/StepperTitle/StepperTitle';
import FormField from 'layouts/applications/userverification/components/FormField';
import React from 'react'

import "./WithdrawalRequest.scss";

function WithdrawalRequest() {
    return (
        <MDBox className="withdrawal-request">
            <StepperTitle
                title="Withdrawal Request"
                subTitle="Please confirm the following information" />
            <MDBox mt={2}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <MDBox>
                            <FormField type="text"  label="First Name" />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MDBox>
                            <FormField type="text" label="Last Name" />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MDBox>
                            <FormField type="number" label="Personal ID Number" />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MDBox>
                            <FormField type="email" label="Email Address" />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MDBox>
                            <FormField type="password" autocomplete="false" label="Password" />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MDBox>
                            <FormField type="password" autocomplete="false" label="Repeat Password" />
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
        </MDBox>
    )
}

export default WithdrawalRequest