/* eslint-disable */

import Footer from 'biota/Footer';
import DashboardLayout from 'biota/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'biota/Navbars/DashboardNavbar';
import MDBox from 'components/MDBox';
import React, { useState,useEffect } from 'react'
import backgroundImage from "assets/images/bg-profile.jpeg";
import GridOnIcon from '@mui/icons-material/GridOn';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import chairImage from "assets/images/ecommerce/black-chair.jpeg";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Button, Card, CardActions, CardContent, CardMedia, Divider, FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, Select, Stack, Typography } from '@mui/material';
import { Grid3x3Outlined } from '@mui/icons-material';
import MDInput from 'components/MDInput';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DescriptionIcon from '@mui/icons-material/Description';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ApprovalIcon from '@mui/icons-material/Approval';
import { ReactComponent as CharacteristicsImg } from "assets/images/characteristics.svg";
import { ReactComponent as CertificateImg } from "assets/images/certificate.svg";
import { ReactComponent as DescriptionImg } from "assets/images/description.svg";
import { ReactComponent as GalleryImg } from "assets/images/gallery.svg";
import { ReactComponent as VerifiedImg } from "assets/images/verified.svg";
import TimelineIcon from '@mui/icons-material/Timeline';
import ListIcon from '@mui/icons-material/List';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import DefaultLineChart from "biota/Charts/LineCharts/DefaultLineChart";
import MDBadgeDot from "components/MDBadgeDot";
import MDButton from "components/MDButton";
import priceHistoryLineChart from "./data/priceHistoryLineChart";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DataTable from "examples/Tables/DataTable";
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import nftImage from "assets/images/nft.jpg";
import AreaIcon from "assets/images/area.svg";
import RegionIcon from "assets/images/region.svg";
import CountryIcon from "assets/images/country.svg";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

import { useParams } from 'react-router-dom';
import { useGetProjectFilesQuery, useGetSingleProjectQuery } from 'app/services/projectsApi';
import { useGetSingleNFTQuery } from 'app/services/nftApi';
import { useGetNftPriceHistoryQuery } from 'app/services/nftApi';


import { userTypesEnum } from 'utils/utils';
import { useGetUserQuery } from 'app/services/userApi';
import TransactionsDataTableData from './data/activityDataTableData';
import MoreCollectionCard from './MoreCollectionCard/MoreCollectionCard';
import { format } from 'date-fns';

import "./SingleProjectDetail.scss";
import SingleProjectOfferTable from './SingleProjectOfferTable/SingleProjectOfferTable';
import SingleProjectDetailModal from './SingleProjectDetailModal/SingleProjectDetailModal';
import BuyNFTModal from './BuyNFTModal/BuyNFTModal';
import BuyNFTCreditCardModal from './BuyNFTCreditCardModal/BuyNFTCreditCardModal';
import { useGetNftTransactionsQuery,useGetProjectNFTSQuery } from 'app/services/nftApi';
import { getTransactionTypeById } from 'utils/utils';
import Lightroom from 'react-lightbox-gallery'
import SingleNFTCard from './SingleNFTCard/SingleNFTCard';

function SingleProjectDetail() {
    const [open, setOpen] = useState(false);
    const [openCreditModal, setOpenCreditModal] = useState(false);
    const [galleryClicked, setGalleryClicked] = useState(false);
    const [openBuyNow, setOpenBuyNow] = useState(false);
    const { data:user, isLoading:userIsLoading} = useGetUserQuery();
    const { id } = useParams();

    const { status, data: nftData, isError, error, isLoading, isSuccess } = useGetSingleNFTQuery(id);
    const { data: nftPriceData, isLoading:PriceHistoyIsLoading} = useGetNftPriceHistoryQuery(id);
    const {assets,description,name,nft_area:nftArea,number_of:numberOf,project_id:projectId,value,status:verifiedStatus} = nftData?.nfts || {};
    const {  data: projectFilesData, isLoading:filesIsLoading } = useGetProjectFilesQuery(projectId);
    const {  data: projectData, isLoading:projectIsLoading } = useGetSingleProjectQuery(projectId);
    const { data: projectNFTSData, isLoading: isNftLoading } = useGetProjectNFTSQuery(projectId);
    const { listing_title: listingTitle, description:projectDescription, items,
        owners, avg_price: avgPrice, volume_traded: volumeTraded, banner, logo,
        owner_name: ownerName,biota_index:biotaIndex, wetlands,protected_wild_areas:wildAreas,hydro_priority:hydroPriority,social_development_index:socialIndex,
        biologic_corridors:biologicCorridors,
        social } = projectData?.projects || {};
    const {  data: nftTransactions, isLoading:traansactionIsLoading } = useGetNftTransactionsQuery(id);
    
    console.log("nftData",nftData,nftPriceData);
    console.log("projectData",projectData);
    function createData(price, usdPrice, floorDiff, expiration, from) {
        return { price, usdPrice, floorDiff, expiration, from };
    }

    localStorage.setItem('userId',user.id);
    const images=[
    ];
    const settings= {
        columnCount:{
          default:2,
          mobile:2,
          tab:2
        },
        mode: 'light'
      }
    
    const loadImages= ()=>{
        projectFilesData?.filter(galleryItem=>galleryItem.use==="media")?.map((item,idx) =>
            (
                images.push({src:item.file_path,sub:name})
            )
        )
    };

    loadImages();

    const rows = [
        createData('13.43', "$234.43", "3% below", "About 16 hours", "AI_44"),
        createData('13.43', "$234.43", "3% below", "About 16 hours", "AI_44"),
        createData('13.43', "$234.43", "3% below", "About 16 hours", "AI_44"),
        createData('13.43', "$234.43", "3% below", "About 16 hours", "AI_44"),
        createData('13.43', "$234.43", "3% below", "About 16 hours", "AI_44"),
    ];
    const handleClose = () => {
        setOpen(false);
        setOpenCreditModal(false);
        setOpenBuyNow(false);
        setGalleryClicked(false);

    }
    const handleGalleryClick = () => {
        setOpen(prev => !prev);
        setGalleryClicked(prev => !prev);
    }
    const handleBuyClick = ()=>{
        setOpenBuyNow(true)
    }
    useEffect(()=>{
        if(openCreditModal){
            setOpenBuyNow(false)
        }
    },[openCreditModal]);
    const [transactionsTableData, setTransactionsTableData] = useState(TransactionsDataTableData)
    useEffect(()=>{
        if(nftTransactions){
            const data = nftTransactions?.map(item=>({...item,
                transaction_type:getTransactionTypeById(item.transaction_type),
                date_created:`${format(new Date(item.date_created),'dd.MM.yyyy')}  ${format(new Date(item.date_created),'hh.mm')}`,
            }));
                
            setTransactionsTableData((prev)=>({...prev,rows:data}));
        }

    },[nftTransactions])
    return (
        <DashboardLayout>
            <DashboardNavbar />
            {open && <SingleProjectDetailModal galleryClicked={galleryClicked} open={open} handleClose={handleClose} />}
            {openBuyNow &&  <BuyNFTModal  open={openBuyNow} handleClose={handleClose} setOpenCreditModal={setOpenCreditModal} />}
            {openCreditModal && <BuyNFTCreditCardModal  open={openCreditModal} handleClose={handleClose} nftData={nftData} />}

            <MDBox mt={4} className="single-project-detail-container">
                <Card sx={{ padding: "20px" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Stack spacing={2}>
                                <MDBox className="project-img-container" onClick={() => setOpen(prev => !prev)}>
                                    <img src={assets || nftImage} alt="product 1" />
                                </MDBox>
                                <MDBox className="single-project-detail-description">
                                    <Stack direction="row" alignItems="center" spacing={1} mb={1}>
                                        <DescriptionImg />
                                        <Typography variant='body2' component="span" fontSize={16} fontWeight="bold">Description</Typography>
                                    </Stack>
                                    <Typography variant='body2' color="text.primary">
                                        {description}
                                    </Typography>
                                </MDBox>
                                <Stack>
                                    <Accordion disableGutters defaultExpanded>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Stack direction="row" alignItems="center" spacing={1} mb={1}>
                                                <GalleryImg />
                                                <Typography variant='body2' component="span" fontSize={16} fontWeight="bold">Gallery</Typography>
                                            </Stack>
                                        </AccordionSummary>
                                       
                                        <AccordionDetails>
                                            <Grid container spacing={1}>
                                                {/* {projectFilesData?.filter(galleryItem=>galleryItem.use==="media")?.map((item,idx) =>
                                                (<Grid key={item.id + idx} item xs={6} sm={4}>
                                                    <MDBox className="project-gallery-img-container" onClick={handleGalleryClick}>
                                                        <img style={{ maxWidth: "100%", height: "100%", borderRadius: 5 }} 
                                                        src={item.file_path || nftImage} alt={item.listing_title} />
                                                    </MDBox>
                                                </Grid>))} */}
                                                <Lightroom images={images} settings={settings} />  
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion disableGutters>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Stack direction="row" alignItems="center" spacing={1} mb={1}>
                                                <CharacteristicsImg />
                                                <Typography variant='body2' component="span"
                                                    fontSize={16} fontWeight="bold">Biota characteristics</Typography>
                                            </Stack>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                            <Stack direction="row" spacing={1}>
                                                <Typography variant='body1' component="span" fontSize={18} fontWeight="bold">Biota index:</Typography>
                                                <Typography variant='body1' component="span" fontSize={18} >{biotaIndex|0}%</Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={1}>
                                                <Typography variant='body1' component="span" fontSize={18} fontWeight="bold">Wetlands:</Typography>
                                                <Typography variant='body1' component="span" fontSize={18} >{wetlands|0}%</Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={1}>
                                                <Typography variant='body1' component="span" fontSize={18} fontWeight="bold">Hydro priority:</Typography>
                                                <Typography variant='body1' component="span" fontSize={18} >{hydroPriority|0}%</Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={1}>
                                                <Typography variant='body1' component="span" fontSize={18} fontWeight="bold">Biologic corridors:</Typography>
                                                <Typography variant='body1' component="span" fontSize={18} >{biologicCorridors|0}%</Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={1}>
                                                <Typography variant='body1' component="span" fontSize={18} fontWeight="bold">Protected wild areas:</Typography>
                                                <Typography variant='body1' component="span" fontSize={18} >{wildAreas|0}%</Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={1}>
                                                <Typography variant='body1' component="span" fontSize={18} fontWeight="bold">Social development index:</Typography>
                                                <Typography variant='body1' component="span" fontSize={18} >{socialIndex|0}%</Typography>
                                            </Stack>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion disableGutters>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Stack direction="row" alignItems="center" spacing={1} mb={1}>
                                                <CertificateImg />
                                                <Typography variant='body2' component="span"
                                                    fontSize={16} fontWeight="bold">Certificates</Typography>
                                            </Stack>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                            Group member of the FSC® forest certification within the scope of the FUNDECOR certification, certificate code GFA-FM/COC-003954
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>

                                    

                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={8}>
                                    <Card>
                                        <Grid container p={2} spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <Stack spacing={1}>
                                                    <Stack direction="row" spacing={1}>
                                                        <Typography variant='body1' sx={{ color: "success.main" }} component="span" fontSize={18} fontWeight="bold">#00{numberOf}</Typography>
                                                        <Typography variant='body1' component="span" fontSize={18} fontWeight="bold">of</Typography>
                                                        <Typography variant='body1' component="span" fontSize={18} fontWeight="bold">20</Typography>
                                                    </Stack>
                                                    <Stack direction="row" spacing={1}>
                                                        <Typography variant='body1' component="span" fontSize={18} fontWeight="bold">Price:</Typography>
                                                        <Typography variant='body1' component="span" fontSize={18} >${value}</Typography>
                                                    </Stack>

                                                    {user?.type!==userTypesEnum.Owner && (
                                                        verifiedStatus ===1 
                                                            ? <MDButton variant="contained" size="large" color="success"
                                                            endIcon={<AccountBalanceWalletIcon />} onClick={handleBuyClick}>Buy Now</MDButton>
                                                       
                                                            :<MDButton variant="contained" size="large" color="warning"
                                                            endIcon={<AccountBalanceWalletIcon />} >Not available</MDButton>
                                                        
                                                    )}
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Stack spacing={1}>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <img src={AreaIcon} alt="area" />
                                                        <MDBox>
                                                            <Typography variant='body1' component="span" fontSize={16} fontWeight="bold" mr={0.5}>Area Coverage:</Typography>
                                                            <Typography variant='body1' component="span" fontSize={16} >{nftArea} ha</Typography>
                                                        </MDBox>
                                                    </Stack>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <img src={RegionIcon} alt="Region" />
                                                        <MDBox>
                                                            <Typography variant='body1' component="span" fontSize={16} fontWeight="bold" mr={0.5}>Region:</Typography>
                                                            <Typography variant='body1' component="span" fontSize={16} >Limon</Typography>
                                                        </MDBox>
                                                    </Stack>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <img src={CountryIcon} alt="Country" />
                                                        <MDBox>
                                                            <Typography variant='body1' component="span" fontSize={16}fontWeight="bold" mr={0.5}>Country:</Typography>
                                                            <Typography variant='body1' component="span" fontSize={16}>Costa Rica</Typography>
                                                        </MDBox>
                                                    </Stack>

                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography variant='body2' component="span" fontSize={18} fontWeight="bold">{name}</Typography>
                                        {verifiedStatus === 1 && (<VerifiedImg style={{ width: 25, height: 25 }} />)}
                                    </Stack>
                                    <Typography variant='body2' fontSize={12} >Owned By :Fundecor</Typography>
                                </Grid>
                            </Grid>
                            <Stack mt={2}>
                                <Accordion disableGutters defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Stack direction="row" alignItems="center" spacing={1} mb={1}>
                                            <TimelineIcon />
                                            <Typography variant='body2' component="span" fontSize={16} fontWeight="bold">Price History</Typography>
                                        </Stack>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <DefaultLineChart
                                            chart={priceHistoryLineChart}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                                {/* <Accordion disableGutters>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Stack direction="row" alignItems="center" spacing={1} mb={1}>
                                            <LocalOfferIcon />
                                            <Typography variant='body2' component="span"
                                                fontSize={16} fontWeight="bold">Listings</Typography>
                                        </Stack>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                            malesuada lacus ex, sit amet blandit leo lobortis eget.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion> */}

                                <Accordion disableGutters>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Stack direction="row" alignItems="center" spacing={1} mb={1}>
                                            <ListIcon />
                                            <Typography variant='body2' component="span"
                                                fontSize={16} fontWeight="bold">Offers</Typography>
                                        </Stack>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <SingleProjectOfferTable rows={rows} />
                                    </AccordionDetails>
                                </Accordion>

                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid container mt={4}>
                        <Grid item xs={12}>
                            <Stack spacing={2}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Stack direction="row" alignItems="center" spacing={1} mb={1}>
                                            <CalendarViewMonthIcon />
                                            <Typography variant='body2' component="span" fontSize={16} fontWeight="bold">Item Activity</Typography>
                                        </Stack>
                                    </AccordionSummary>
                                    <AccordionDetails className="activity-data-table-container">
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value="10"
                                                label="Filter"
                                                sx={{ height: "40px" }}
                                                onChange={() => { }}
                                            >
                                                <MenuItem value={10}>Filter 1</MenuItem>
                                                <MenuItem value={20}>Filter 2</MenuItem>
                                                <MenuItem value={30}>Filter 3</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <DataTable table={transactionsTableData} entriesPerPage canSearch />
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Stack direction="row" alignItems="center" spacing={1} mb={1}>
                                            <MarkUnreadChatAltIcon />
                                            <Typography variant='body2' component="span"
                                                fontSize={16} fontWeight="bold">More From This Collection</Typography>
                                        </Stack>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={2} sx={{ overflow: "auto", flexWrap: "nowrap", paddingBottom: 4 }}>
                                        {projectNFTSData?.length ? projectNFTSData?.map(nft => (
                                            <Grid key={nft.key} item xs={12} md={6} lg={4} xl={3}>
                                                <SingleNFTCard nftData={nft} />
                                            </Grid>
                                        )) : (
                                            <Grid item xs={12}>
                                                There is no NFTS available.
                                            </Grid>
                                        )} 
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Stack>

                        </Grid>
                    </Grid>
                </Card>
            </MDBox>
            <Footer />
        </DashboardLayout>
    )
}

export default SingleProjectDetail