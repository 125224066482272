/* eslint-disable */

import axios from 'axios'
import { baseURL } from 'utils/utils';

// const API_URL = '/api/users/'
// const API_URL = 'https://927d1d30.us-south.apigw.appdomain.cloud/biota-api'
const API_URL = baseURL
// get users list
const userList = async () => {
    const token = JSON.parse(localStorage.getItem("user")).JWT;
    const response = await axios.get(API_URL + '/list/user',
    { headers: {"X-Authorization" : `Bearer ${token}`} });
    console.log("user list response:",response)

    return response.data
}
const getUser = async () => {
    const token = JSON.parse(localStorage.getItem("user")).JWT;
    const response = await axios.get(API_URL + '/list/user',
    { headers: {"X-Authorization" : `Bearer ${token}`} });
    console.log("get user response:",response)

    return response.data
}
const updateUser = async (updatedUser) => {
    const token = JSON.parse(localStorage.getItem("user")).JWT;
    const {id, ...data} = updatedUser;
    const response = await axios.post(API_URL + `/edit/user/${id}`,data,
    { headers: {"X-Authorization" : `Bearer ${token}`} });
    console.log("updated user response:",response)

    return response.data
}

const userService = {
    userList,
    getUser,
    updateUser
}

export default userService