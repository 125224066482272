/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
// import { useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Pricing page components
import FaqCollapse from "layouts/pages/pricing-page/components/FaqCollapse";

import axios from "axios";

const baseURL = "https://biota-backend.herokuapp.com/api/faq/list";

function Faq() {
  const [faqItems, setFaqItems] = useState([]);

  useEffect(() => {
    axios.get(baseURL).then((response) => {
      setFaqItems(response.data);
    });
  }, []);

  const [collapse, setCollapse] = useState(false);
  const faq = faqItems.map(({question,body}) => (
    <FaqCollapse
      title={question}
      open={collapse === 1}
      onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
    >
     {body}       
    </FaqCollapse>
    ));

  return (
    <MDBox mt={8} mb={6}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={10}>
          {faq}
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Faq;
