import { Avatar, Checkbox, FormControlLabel, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Stack, Tab, Tabs, Typography } from '@mui/material';
import MDBox from 'components/MDBox';
import React from 'react'
import { useParams } from 'react-router-dom';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Modal } from 'react-responsive-modal';
import team2 from "assets/images/team-2.jpg"
import 'react-responsive-modal/styles.css';
import { ReactComponent as VerifiedImg } from "assets/images/verified.svg";
import backgroundImage from "assets/images/bg-profile.jpeg";
import nftImage from "assets/images/nft.jpg";
import { useGetSingleNFTQuery } from 'app/services/nftApi';
import { useGetProjectFilesQuery } from 'app/services/projectsApi';


import "./SingleProjectDetailModal.scss";

function SingleProjectDetailModal({ open, handleClose, galleryClicked }) {
    const { id } = useParams();
    const { status, data: nftData, isError, error, isLoading, isSuccess } = useGetSingleNFTQuery(id);
    const {assets,description,name,nft_area:nftArea,number_of:numberOf,project_id:projectId,value,status:verifiedStatus,logo, banner} = nftData?.nfts || {};
    const {  data: projectFilesData, isLoading:filesIsLoading } = useGetProjectFilesQuery(projectId);
    
    
    return (
        <div className='single-project-detail-modal-container'>
            <Modal classNames="" showCloseIcon={false} modalId="single-project-detail-modal" open={open} onClose={handleClose} center>
                <MDBox className="modal-content" p={4}>
                    <Stack direction="row" spacing={2} justifyContent="center">
                        <Typography variant="h3">{name}</Typography>
                        <VerifiedImg />
                    </Stack>
                    <MDBox mb={3}>
                        {galleryClicked ? (
                                <Typography variant="subtitle2" fontWeight="bold" textAlign="center">Gallery</Typography>
                        ) : (
                            <>
                                <Typography variant="subtitle1" fontSize="12px" textAlign="center">Owned By: Founder</Typography>
                                <Typography variant="subtitle2" fontWeight="bold" textAlign="center">#00{numberOf}</Typography>
                            </>
                        )}
                    </MDBox>

                    <MDBox className="img-container">
                        <img src='https://biota.s3.amazonaws.com/500de7ae-4753-47b9-8b3e-cf19e0f72d5b-biota-cube.gif' alt="NFT product" style={{ maxWidth: "650px", width: "100%", borderRadius: 15 }} />
                    </MDBox>
                    {galleryClicked && (
                        {/* <Grid container spacing={1} maxWidth="300px" width="90%" m="auto">
                            <Lightroom images={images} settings={settings} />   
                        </Grid> */}
                    )}
                </MDBox>
            </Modal>
        </div>
    );
}

export default SingleProjectDetailModal