/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "biota/LayoutContainers/DashboardLayout";
import DashboardNavbar from "biota/Navbars/DashboardNavbar";
import Footer from "biota/Footer";
import ReportsBarChart from "biota/Charts/BarCharts/ReportsBarChart";
import DoughnutChart from "biota/Charts/DoughnutCharts/DefaultDoughnutChart";
import ReportsLineChart from "biota/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "biota/Cards/StatisticsCards/ComplexStatisticsCard";
import BookingCard from "biota/Cards/BookingCard";

// Anaytics dashboard components
import ProjectList from "layouts/dashboards/biota/components/ProjectList";

import Link from "assets/theme/components/link";

// Data
import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";

// Images
import booking1 from "assets/images/biota/project-1.png";
import booking2 from "assets/images/biota/project-2.png";
import booking3 from "assets/images/biota/project-3.png";
import { getUser } from "app/slices/userSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAuth } from "../../../use-auth";

function BiotaDashboard() {
  const { sales, tasks } = reportsLineChartData;
  const dispatch = useDispatch()

  const auth = useAuth();
  // Action buttons for the BookingCard
  const actionButtons = (
    <>
      <Tooltip title="Refresh" placement="bottom">
        <MDTypography
          variant="body1"
          color="primary"
          lineHeight={1}
          sx={{ cursor: "pointer", mx: 3 }}
        >
          <Icon color="inherit">refresh</Icon>
        </MDTypography>
      </Tooltip>
      <Tooltip title="Edit" placement="bottom">
        <MDTypography variant="body1" color="info" lineHeight={1} sx={{ cursor: "pointer", mx: 3 }}>
          <Icon color="inherit">edit</Icon>
        </MDTypography>
      </Tooltip>
    </>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container>  
          <ProjectList />
        </Grid>
        <MDBox mt={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <DoughnutChart
                  title="Sold and available tokens"
                  height="12.5rem"
                  chart={{
                    labels: ["Sold", "Available"],
                    datasets: {
                      label: "Tokens",
                      backgroundColors: ["info", "dark", "error", "secondary", "primary"],
                      data: [15, 85],
                    },
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="token sales activity"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="Wallet activity"
                  description="Wallet activity for the last 12 months"
                  date="just updated"
                  chart={tasks}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="weekend"
                  title="Bids"
                  count={281}
                  percentage={{
                    color: "success",
                    amount: "+55%",
                    label: "than lask week",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="leaderboard"
                  title="Income"
                  count="2,300"
                  percentage={{
                    color: "success",
                    amount: "+3%",
                    label: "than last month",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="store"
                  title="Carbon reduced tons"
                  count="34k"
                  percentage={{
                    color: "success",
                    amount: "+1%",
                    label: "than yesterday",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="person_add"
                  title="Users interested in your listings"
                  count="+91"
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Just updated",
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1.5}>
          Recent projects
        </MDBox>
        <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mt={3}>
                <BookingCard
                  image={booking1}
                  title="Tirimina Biological Reserve"
                  description='Tirimbina Biological Reserve, is a non-profit organization, established to protect 345 ha. of tropical rain forest that contains a very important representation of the biodiversity of this type of ecosystem. The average rainfall is 4500 mm per year.
Its main mission is the promotion of scientific research, knowledge transfer and the execution of the Environmental Education program for children and youth of the community of La Virgen de Sarapiquí, where it is located.'
                  price="$1,200/NFT"
                  location="La Virgen de Sarapiquí, Costa Rica"
                  action={actionButtons}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mt={3}>
                <BookingCard
                  image={booking2}
                  title="Lapa Verde Refuge"
                  description='La finca del Refugio Lapa Verde es manejada por la Fundación Ecovida, organización sin fines de lucro que se dedica a la protección sostenible de la flora y fauna, mediante una estrategia de protección y restauración de diversos hábitats (lagunas, arroyos, pantanos, matorrales, selva tropical) vigilancia contra la deforestación y la caza furtiva y sensibilización de la población local.'
                  price="$1,300/NFT"
                  location="Lapa Verde, Costa Rica"
                  action={actionButtons}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mt={3}>
                <BookingCard
                  image={booking3}
                  title="Ara Ambigua State"
                  description='The Ara Ambigua state is a model of a family project that has passed from generation to generation, based on the conservation of the forest. It has an extension of 355 ha, of which 338 ha. It corresponds to very humid premontane transition to basal forest, with an average rainfall of 3500 mm per year. It is owned by Industrias Agropecuarias Asociadas S.A.'
                  price="$1,500/NFT"
                  location="Braulio Carrillo, Costa Rica"
                  action={actionButtons}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default BiotaDashboard;
